<template>
    <div class="myriad-neuro-tooltip-container">
        <div v-show="tooltipShown" ref="tooltip" v-hammer:tap="toggleHoverEvent" class="tooltip">
            <p class="tooltip-title">
                {{ title }}
            </p>
            <p class="tooltip-content">
                {{ content }}
            </p>
            <div ref="tooltipArrow" class="tool-tip-arrow" />
        </div>
        <div
            v-hammer:tap="toggleHoverEvent" 
        >
            <div
                ref="hoverPoint"
                class="hover-point"
                @mouseenter.prevent="hoverEvent"
                @mouseleave.prevent="unhoverEvent"
            >
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyriadNeuroTooltip",
    props: {
        title: {
            required: true,
            type: String
        },
        content: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            tooltipShown: false
        };
    },
    methods: {
        hoverEvent() {
            this.tooltipShown = true;

            this.$nextTick(() => {
                this.getHoverTop();
                this.setArrowLocation();
            });
        },
        unhoverEvent() {
            this.tooltipShown = false;
        },
        toggleHoverEvent() {
            if(this.tooltipShown) {
                this.unhoverEvent();
            }
            else {
                this.hoverEvent();
            }
        },
        getHoverTop() {
            const hoverPointHeight = parseInt(this.$refs.hoverPoint.getBoundingClientRect().height);
            const tooltipHeight = parseInt(this.$refs.tooltip.getBoundingClientRect().height);
            const height = (hoverPointHeight + tooltipHeight + 10) * -1;

            this.$refs.tooltip.style.marginTop = height + "px";
        },
        setArrowLocation() {
            const tooltip = this.$refs.tooltip.getBoundingClientRect().left;
            const hoverPointLoc = this.$refs.hoverPoint.getBoundingClientRect().left;

            const location = hoverPointLoc - tooltip - 10;

            this.$refs.tooltipArrow.style.left = location + "px";
        }
    }
};
</script>

<style lang="scss" scoped>
.tooltip {
    position: absolute;
    margin-left: -50px;
    min-width: 300px;
    max-width: 300px;
    min-height: 120px;
    padding: 20px;
    background: white;
    border: 1px solid $border-grey;
    border-radius: 10px;
    box-shadow: 4px 4px 5px $border-grey;
}

.tool-tip-arrow {
    position: absolute;
    bottom: -20px;
    width: 38px;
    height: 38px;
    border-bottom: 1px solid $border-grey;
    border-right: 1px solid $border-grey;
    background: white;
    transform: rotate(45deg);
}

.tooltip-title {
    margin-bottom: 5px;
    font-size: $small-copy-size;
    font-weight: bold;
}

.tooltip-content {
    font-size: $small-copy-size;
    z-index: 1;
    position: relative;
    background: white;
}

@include sm {
    .tooltip {
        position: absolute;
        left: 10px;
        margin-left: 0;
    }
}
</style>
