<template>
    <div>
        <div v-if="!autocompleteError && $store.getters.googleAutocompleteEnabled" ref="demographicAddressContainer" v-container-validation class="input-row">
            <label class="input-label label">
                Address
            </label>
            <input id="demographic-address-hidden" style="display: none" type="text">
            <!-- Above used to hide google autofill -->
            <input
                id="demographic-address"
                ref="demographicAddress"
                v-required-validation
                class="text-input demographic-long-input"
                name="demographic-address"
                autocomplete="demographic-address"
                type="text"
                placeholder="Example: 123 Main St."
                @blur="checkForGoogleAddressReturn"
            >
            <error-labels v-if="!showAddressNotFoundWarning" invalid-string="Invalid Address" />
            <transition name="grow" mode="out-in">
                <div v-if="showAddressNotFoundWarning" class="input-row demographi-row-address-not-found">
                    <label class="input-label label" />
                    <div class="address-not-found-warning demographic-long-input">
                        <p class="address-not-found-heading">
                            Valid address needed
                        </p>
                        <p class="address-not-found-text">
                            A matching address was not found for the value entered. Please <b>correct the address</b> or <b>click the link below</b> to enter the full address without Google matching.
                        </p>
                        <a class="no-underline address-not-found-link" @click="showManualAddressEntry">
                            Enter the full address
                        </a>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="autocompleteError || !$store.getters.googleAutocompleteEnabled" class="demographic-manual-address">
            <div v-container-validation class="input-row">
                <label class="input-label label">
                    Address
                </label>
                <input
                    ref="demographicStreet"
                    v-max-length-validation="{maxLength: 100, required: true}" 
                    class="text-input demographic-long-input"
                    name="demographic-address"
                    type="text"
                    placeholder="Street"
                    :value="this.$store.getters.patientStreet"
                    @blur="setAddressFromManualInputs"
                >
                <error-labels invalid-string="Invalid Street" />
            </div>
            <div v-container-validation class="input-row">
                <label class="input-label label">
                    City
                </label>
                <input ref="demographicCity" 
                       v-required-validation 
                       class="text-input demographic-long-input" 
                       name="demographic-city"
                       type="text" 
                       :value="this.$store.getters.patientCity" 
                       @blur="setAddressFromManualInputs"
                >
                <error-labels invalid-string="Invalid City" />
            </div>
            <div v-container-validation class="input-row">
                <label class="input-label label">
                    State
                </label>
                <state-select ref="demographicState" 
                              v-model="$store.getters.patientState" 
                              class="demographic-state-input" 
                              @blur="setAddressFromManualInputs"
                />
                <error-labels invalid-string="Invalid State" />
            </div>
            <div v-container-validation class="input-row">
                <label class="input-label label">
                    Zip
                </label>
                <input ref="demographicZip" 
                       v-mask="'#####'" 
                       v-max-min-length-validation="{maxLength: 5, minLength: 5, required: true}" 
                       class="text-input" 
                       name="zip"
                       type="text" placeholder="Zip" 
                       :value="$store.getters.patientZip" 
                       @blur="setAddressFromManualInputs"
                       @keydown.tab="setAddressFromManualInputs"
                >
                <error-labels invalid-string="Invalid Zip" />
            </div>
        </div>
        <div v-container-validation class="input-row">
            <label class="input-label label">
                Apt, Ste, etc.
            </label>
            <input v-model="aptSte" 
                   v-max-length-validation="{maxLength: 100, required: false}" 
                   class="text-input" 
                   type="text" placeholder="(optional)"
            >
            <error-labels invalid-string="Invalid Apt. Ste., etc." />
        </div>
    </div>
</template>

<script>
/* global google */

import GoogleMapsLoader from "google-maps";
import StateSelect from "../../ui/StateSelect.vue";
import ErrorLabels from "./ErrorLabels.vue";

export default {
    name: "Address",
    components: {
        StateSelect,
        ErrorLabels
    },
    data: () => {
        return {
            autocomplete: null,
            autocompleteError: false,
            showAddressNotFoundWarning: false,
            place: {},
            placeBlurTimeout: null,
            manualCityValue: "",
            manualZipValue: "",
            manaualAddressValue: ""
        };
    },
    computed: {
        aptSte: {
            get() {
                return this.$store.getters.patientAptSte;
            },
            set(value) {
                this.$store.dispatch("setPatientAptSte", value);
            }
        }
    },
    mounted() {
        if(this.$store.getters.googleAutocompleteEnabled) {
            this.initAddressAutoComplete();
        }

        if(this.$refs.demographicAddress) {
            this.$refs.demographicAddress.value = this.$store.getters.patientAddress;
        } 
    },
    methods: {
        initAddressAutoComplete() {
            var self = this;
            GoogleMapsLoader.KEY = this.$store.getters.googleAPIKey;
            GoogleMapsLoader.LIBRARIES = ["places"];
            GoogleMapsLoader.VERSION = "weekly";
            GoogleMapsLoader.load(function(google) {
                self.autocomplete = new google.maps.places.Autocomplete(
                    document.getElementById("demographic-address"),
                    { types: ["geocode"] }
                );
                self.geolocateAddressAutocomplete();
                self.autocomplete.addListener("place_changed", self.setAddressFromAutoComplete);
            });
        },
        geolocateAddressAutocomplete() {
            var self = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    var circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                    self.autocomplete.setBounds(circle.getBounds());
                });
            }
        },
        setAddressFromAutoComplete() {
            const self = this;
            let place =  this.autocomplete.getPlace();
            if(place.address_components) {
                this.place = place;

                let number, streetName, city, stateLoc, zip;

                for(let i=0; i< place.address_components.length; i++) {
                    const placeType = place.address_components[i].types[0];
                    switch(placeType) {
                    case "street_number":
                        number = place.address_components[i].short_name;
                        break;
                    case "route":
                        streetName = place.address_components[i].short_name;
                        break;
                    case "locality":
                        city = place.address_components[i].short_name;
                        break;
                    case "sublocality_level_1":
                        city = place.address_components[i].long_name;
                        break;
                    case "administrative_area_level_1":
                        stateLoc = place.address_components[i].short_name;
                        break;
                    case "administrative_area_level_3":
                        city = place.address_components[i].short_name;
                        break;
                    case "postal_code":
                        zip = place.address_components[i].short_name;
                        break;
                    }
                }

                if(number && streetName && city && stateLoc && zip) {
                    const street = number + " " + streetName;
                    this.$store.dispatch("setPatientAddressData", {street, city, stateLoc, zip});
                    self.shouldShowAddressNotFound(false);
                }
                
                else {
                    self.shouldShowAddressNotFound(true);
                }
            }
            else {
                this.place = {};
                self.shouldShowAddressNotFound(true);
            }
        },
        setAddressFromManualInputs() {
            let street = this.$refs.demographicStreet ? this.$refs.demographicStreet.value : "";
            let city = this.$refs.demographicCity ? this.$refs.demographicCity.value : "";
            let stateLoc = this.$refs.demographicState ? this.$refs.demographicState.$refs.stateSelect.value : "";
            let zip = this.$refs.demographicZip ? this.$refs.demographicZip.value : "";

            this.$store.dispatch("setPatientAddressData", {street, city, stateLoc, zip});
        },
        showManualAddressEntry() {
            this.$data.autocompleteError = true;
            this.$nextTick(() => {
                this.$store.dispatch("setPatientCity", "");
                this.$store.dispatch("setPatientState", "");
                this.$store.dispatch("setPatientZip", "");
                this.$store.dispatch("setPatientStreet", "");
            });
        },
        checkForGoogleAddressReturn() {
            let self = this;
            self.placeBlurTimeout = setTimeout(function() {
                clearTimeout(self.placeBlurTimeout);

                const noAutocompleteValue = document.querySelector(".pac-container").querySelectorAll(".pac-item").length <= 0;
                if(self.$refs.demographicAddress) {
                    const autoCompleteEmpty = self.$refs.demographicAddress.value.length <= 0;

                    if(noAutocompleteValue && !autoCompleteEmpty && Object.keys(self.place).length <=0) {
                        self.shouldShowAddressNotFound(true);
                    }
                    else {
                        self.place = {};
                    } 
                }
            }, 500);
        },
        shouldShowAddressNotFound(value) {
            this.showAddressNotFoundWarning = value;

            if(value) {
                this.$refs.demographicAddress.classList.add("myriad-validation-error");
                this.$refs.demographicAddressContainer.classList.add("myriad-container-validation-error");
            }
            else {
                this.$refs.demographicAddress.classList.remove("myriad-validation-error");
                this.$refs.demographicAddressContainer.classList.remove("myriad-container-validation-error");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../../styles/inputs.scss";

    .input-row.demographi-row-address-not-found {
        margin-top: 5px;
        margin-bottom: 0px;
        max-height: 200px;
        opacity: 1;
    }

    .demographic-state-input {
        min-width: 180px;
        margin-right: 10px;
    }

    .address-not-found-warning {
        width: 100%;
        padding: 24px;
        padding: 20px;
        border: 1px solid $genesight-red;
    }

    .address-not-found-heading {
        padding-bottom: 24px;
        font-size: $regular-copy-size;
        font-weight: 700;
        color: $genesight-red;
    }

    .address-not-found-text {
        padding-bottom: 24px;
        font-size: $regular-copy-size;
    }

    .address-not-found-link {
        font-size: $regular-copy-size;
    }

    .grow-enter-active {
        transition: all 0.2s ease-out;
    }

    .grow-leave-active {
        transition: all 0.2s ease-in;
    }

    .grow-enter,
    .grow-leave-to {
        max-height: 0;
        opacity: 0;
    }
</style>


