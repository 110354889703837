export default {
    state: {
        primaryFrontInsuranceCard: null,
        primaryBackInsuranceCard: null,
        secondaryFrontInsuranceCard: null,
        secondaryBackInsuranceCard: null
    },
    getters: {
        primaryFrontInsuranceCard: state => {
            return state.primaryFrontInsuranceCard;
        },
        primaryBackInsuranceCard: state => {
            return state.primaryBackInsuranceCard;
        },
        secondaryFrontInsuranceCard: state => {
            return state.secondaryFrontInsuranceCard;
        },
        secondaryBackInsuranceCard: state => {
            return state.secondaryBackInsuranceCard;
        },
        getArePrimaryCardsMissing: state => {
            return state.primaryFrontInsuranceCard === null || state.primaryBackInsuranceCard === null;
        },
        getAreSecondaryCardsMissing: state => {
            return state.secondaryFrontInsuranceCard === null || state.secondaryBackInsuranceCard=== null;
        }
    },
    mutations: {
        setPrimaryInsuranceFrontCard(state, value) {
            state.primaryFrontInsuranceCard = value;
        },
        setPrimaryInsuranceBackCard(state, value) {
            state.primaryBackInsuranceCard = value;
        },
        clearPrimaryInsuranceCards(state) {
            state.primaryFrontInsuranceCard = null;
            state.primaryBackInsuranceCard = null;
        },
        setSecondaryFrontInsuranceCard(state, value) {
            state.secondaryFrontInsuranceCard = value;
        },
        setSecondaryInsuranceBackCard(state, value) {
            state.secondaryBackInsuranceCard = value;
        },
        clearSecondaryInsuranceCards(state) {
            state.secondaryFrontInsuranceCard = null;
            state.secondaryBackInsuranceCard = null;
        }
    },
    actions: {
        setPrimaryInsuranceFrontCard(context, value) {
            context.commit("setPrimaryInsuranceFrontCard", value);
        },
        setPrimaryInsuranceBackCard(context, value) {
            context.commit("setPrimaryInsuranceBackCard", value);
        },
        setSecondaryInsuranceFrontCard(context, value) {
            context.commit("setSecondaryFrontInsuranceCard", value);
        },
        setSecondaryInsuranceBackCard(context, value) {
            context.commit("setSecondaryInsuranceBackCard", value);
        },
        clearPrimaryInsuranceCards(context) {
            context.commit("clearPrimaryInsuranceCards");
        },
        clearSecondaryInsuranceCards(context) {
            context.commit("clearSecondaryInsuranceCards");
        }
    }
};
