import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import store from "./store/store.js";
import VueSanitize from "vue-sanitize";
import VueTheMask from "vue-the-mask";
import { VueHammer } from "vue2-hammer";
import VueMultiRef from "./directives/multi-ref";
import MyriadValidation from "@myriad-neuroscience/vue-form-validate";
import MyriadMask from "@myriad-neuroscience/vue-input-masks";
import MyriadMultiSelect from "@myriad-neuroscience/vue-multiselect";
import Polyfills from "./scripts/polyfills.js";
import ErrorLogger from "@myriad-neuroscience/common-js";
import { makeServer } from "./server.js";
import { makeCypressServer } from "./cypress-server.js";
import { applyPolyfills, defineCustomElements } from "@myriad-neuroscience/genesight-components/loader";

// Font Awesome Imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faCirclePlus);
library.add(faCircleExclamation);
library.add(faCircleCheck);

if (process.env.VUE_APP_MOCK_API === "true") {
    makeServer();
}

if (window.Cypress) {
    makeCypressServer();
}

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/genesight-\w*/];

const sanitizeOptions = {
    allowedTags: ["sup", "a", "b", "br"],
    allowedAttributes: {
        a: ["href", "target", "class"],
        sup: ["style"]
    }
};

Polyfills.addAllPolyfills();
applyPolyfills().then(()=> {
    defineCustomElements();
});


ErrorLogger.startErrorDetectionListeners(
    Vue,
    window,
    config.rootApiUrl + "/ErrorLog",
    "Patient"
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueSanitize, sanitizeOptions);
Vue.use(VueTheMask);
Vue.use(VueHammer);
Vue.use(MyriadValidation);
Vue.use(MyriadMask);
Vue.use(MyriadMultiSelect);
Vue.use(VueMultiRef);

new Vue({
    el: "#app",
    store,
    router,
    components: { App },
    template: "<App/>"
});
