import Store from "../store/store.js";
import Router from "../router/router.js";

const common = {
    addClass: (element, newClassName) => {
        if (element.className.indexOf(newClassName) === -1) {
            element.className += " " + newClassName;
        }
    },
    removeClass: (element, removeClassName) => {
        if (element.className.indexOf(removeClassName) != -1) {
            element.className = element.className.replace(removeClassName, " ");
        }
    },
    forEach: (nodeList, callback) => {
        for (let i = 0; i < nodeList.length; i++) {
            callback(i, nodeList[i]);
        }
    },
    getElementCenterPosition: (element) => {
        const {top, left, width, height} = element.getBoundingClientRect();
        return {
            x: left + width / 2,
            y: top + height / 2
        };
    },
    doesExist: (variable) => {
        if(variable != null && variable != undefined) {
            return true;
        }
        else {
            return false;
        }
    },
    doesExistAndNotEmpty: (variable) => {
        if(variable != null && variable != undefined && variable != "") {
            return true;
        }
        else {
            return false;
        }
    },
    getUrlParams: (url) => {
        let params = {};
        let parser = document.createElement("a");
        parser.href = url;
        let query = parser.search.substring(1);
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    },
    getUrlParamByName: (url, name) => {
        name = name.replace(/[[\]]/g, "\\$&");

        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        let results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return "";

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    getImageFromFile: (files, callback) => {
        let reader = new FileReader();
        reader.addEventListener("load", function(e) {
            callback(e.target.result);
        });
        reader.readAsDataURL(files);
    },
    ConvertTwoDigitYearToFour: (enteredDOB) => {
        let lastSlash = enteredDOB.lastIndexOf("/");
        let yearEntered = enteredDOB.substring(lastSlash + 1);
        if (yearEntered.length === 2) {
            let restOfDate = enteredDOB.substring(0, lastSlash + 1);
            let twoDigitsCurrentYear = parseInt(new Date().getFullYear().toString().substr(2, 2));
            let newFourYearFormat;
            if (parseInt(yearEntered) >= twoDigitsCurrentYear) {
                newFourYearFormat = "19" + yearEntered;
            }
            else if (parseInt(yearEntered) < twoDigitsCurrentYear) {
                newFourYearFormat = "20" + yearEntered;
            }
            return restOfDate + newFourYearFormat;
        }
        else {
            return enteredDOB;
        }
    },
    createNewEvent: (eventName) => {
        let event;
        if (typeof(Event) === "function") {
            event = new Event(eventName, {cancelable: true});
        } else {
            event = document.createEvent("Event");
            event.initEvent(eventName, true, true);
        }
        return event;
    },
    scrollToErrorMessage: (selector) => {
        let errorSection;
        if(selector) {
            errorSection = document.querySelector(selector);
        }
        else {
            errorSection = document.querySelector(".step-error-text");
        }
        if (errorSection) {
            const destination = Math.floor(errorSection.getBoundingClientRect().top);
            const currentScrollPosition = document.documentElement.scrollTop;
            const scrollTo = currentScrollPosition + destination - 10;

            window.scrollTo({ top: scrollTo, behavior: "smooth"});
        }
    },
    scrollToTop: () => {
        window.scrollTo({ top: 0, behavior: "auto"});
    },
    isMobileSize: () => {
        const isMobile = window.matchMedia("(max-width: 768px)").matches;

        if (isMobile) {
            return true;
        }
        else {
            return false;
        }
    },
    getClosest: (elem, selector) => {
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function(s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {} //eslint-disable-line
                    return i > -1;
                };
        }

        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if ( elem.matches( selector ) ) {
                return elem;
            }
        }
        return null;
    },
    shouldShowDepressiveFrequency() {
        return Store.getters.phq9Score >= 5;
    },
    showFormErrors(vueComp) {
        var CommonFunc = this;
        Store.dispatch("setShowErrorMessage", true).then(()=> {
            vueComp.$nextTick(()=> {
                CommonFunc.scrollToErrorMessage();
            });
        });
    },
    removeErrorBanner() {
        Store.dispatch("setShowErrorMessage", false);
    },
    downloadFileFromAxios(fileData, fileName) {
        if(window.navigator && window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([fileData], { type: "text/html" });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
            var newBlob = new Blob([fileData], {
                type: "application/pdf"
            });
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement("a");
            link.href = data;
            link.target = "_blank";
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
        }
    },
    grabPatientToken(callback) {
        var urlParams = this.getUrlParams(window.location.search);
        var token = urlParams.token;
        var origin = urlParams.origin ? urlParams.origin : "";
        
        if(!this.doesExistAndNotEmpty(token)) {
            Store.dispatch("setShowStartupLoader", false);
            Router.push({
                name: "Error",
                params: {
                    expiredVersion: true
                }
            });
        }
        else {
            callback(token, origin);
        }
    },
    getExcludedSurveyIndexes(items, jsonData) {
        let excludedIndexs = [];
        let includedIndexs = [];

        // Create default excluded indexs array
        for(let i=0; i<items.length; i++) {
            excludedIndexs.push(i);
        }

        // Create array of indexs that have answers included
        for(const key in jsonData) {
            includedIndexs.push(jsonData[key].index);
        }

        // Edit excluded indexes to remove the values in includedIndexs
        excludedIndexs = excludedIndexs.reduce((accumulator, excludedValue) => {
            let value = includedIndexs.find((includedValue)=> {
                return excludedValue === includedValue;
            });

            if(value === undefined) {
                accumulator.push(excludedValue);
            }

            return accumulator;
        }, []);

        return excludedIndexs;
    },
    mergeOrAddToJson(originalObject, key, addedObjct) {
        if(originalObject[key]) {
            Object.assign(originalObject[key], addedObjct);
        }
        else {
            originalObject[key] = addedObjct;
        }


    },
    getFormattedSex(sex) {
        // This will handle any old data that is set to "Male" or "Female"
        if (sex === "Male") {
            sex = "M";
        } else if (sex === "Female") {
            sex = "F";
        }
        return sex;
    },
    getSexString(sex) {
        if (sex === "M") {
            sex = "Male";
        } else if (sex === "F") {
            sex = "Female";
        }
        return sex;
    },
    getFormattedInsurances(patient) {
        var insurances = [];

        if(!Store.getters.primaryNoInsurance){
            var primaryInsurance = this.setPolicyHolderDemographics(Store.getters.insurance.primary, patient);
            var secondaryInsurance = this.setPolicyHolderDemographics(Store.getters.insurance.secondary, patient);

            primaryInsurance.isMedicare = primaryInsurance.isMedicare === "Yes";
            secondaryInsurance.isMedicare = secondaryInsurance.isMedicare === "Yes";

            if (secondaryInsurance.hasSecondaryInsurance === "Yes") {
                insurances = [ primaryInsurance, secondaryInsurance ];
            }
            else {
                insurances = [ primaryInsurance ];
            }
        }

        return insurances;
    },
    setPolicyHolderDemographics(insurance, patient){
        insurance.relationship = insurance.relationship.replace(" ", "");

        if (insurance.relationship === "Me") {
            insurance.firstName = patient.firstName;
            insurance.lastName = patient.lastName;
            insurance.dateOfBirth = patient.dateOfBirth;
            insurance.sex = patient.sex;
        }

        return this.setInsuranceType(insurance);
    },
    setInsuranceType(insurance) {
        if (insurance.isMedicare === "Yes") {
            insurance.InsuranceType = 1;
        } else if (insurance.isUHC === "Yes") {
            insurance.InsuranceType = 2;
        } else {
            insurance.InsuranceType = 0;
        }
        return insurance;
    }
};

export default common;
