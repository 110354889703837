<template>
    <div class="survey-one">
        <survey-page-1 v-if="$store.getters.lastPagesSaved === 0">
            <error-bubble />
        </survey-page-1>
        <survey-page-2 v-if="$store.getters.lastPagesSaved === 1">
            <error-bubble />
        </survey-page-2>
        <survey-page-3 v-if="$store.getters.lastPagesSaved === 2">
            <error-bubble />
        </survey-page-3>
        <survey-thank-you v-if="$store.getters.lastPagesSaved === 3" />
    </div>
</template>

<script>
import SurveyPage1 from "../../components/layout/Survey/SurveyPage1.vue";
import SurveyPage2 from "../../components/layout/Survey/SurveyPage2.vue";
import SurveyPage3 from "../../components/layout/Survey/SurveyPage3.vue";
import SurveyThankYou from "../../components/layout/Survey/SurveyThankYou.vue";
import ErrorBubble from "../../components/layout/Survey/ErrorBubble.vue";

export default {
    name: "SurveyOne",
    components: {
        SurveyPage1,
        SurveyPage2,
        SurveyPage3,
        SurveyThankYou,
        ErrorBubble
    }
};
</script>

<style lang="scss" scoped>
    .survey-one {
        @include site-section();
        display: block;
        margin-bottom: 20px;
    }


    @include small-desktop {
        .survey-one {
            @include site-section-sm-desktop();
        }
    }

    @include tablet {
        .survey-one {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
</style>
