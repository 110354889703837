import { render, staticRenderFns } from "./Medical.vue?vue&type=template&id=8ea1d964&scoped=true&"
import script from "./Medical.vue?vue&type=script&lang=js&"
export * from "./Medical.vue?vue&type=script&lang=js&"
import style0 from "./Medical.vue?vue&type=style&index=0&id=8ea1d964&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.registry.npmjs.org/vue-loader/15.9.8_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ea1d964",
  null
  
)

export default component.exports