<template>
    <div v-if="dialogOpen" class="dialog-background" :style="'height:' + dialogHeight" @click.self="$emit('dialogClose')">
        <div class="dialog">
            <div class="dialog-title-bar">
                <div class="dialog-title-bar-space" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <h2 class="dialog-title-bar-title" v-html="this.$sanitize(title)" />
                <div class="dialog-title-bar-x" @click.self="$emit('dialogClose')">
                    x
                </div>
            </div>
            <slot />
            <div class="dialog-footer">
                <button v-if="closeOnlyButton" class="dialog-button" type="button" @click.self="$emit('dialogClose')">
                    Close
                </button>
                <div v-else class="dialog-footer">
                    <div class="dialog-no-thanks" @click.self="$emit('dialogClose')">
                        No Thanks
                    </div>
                    <button class="dialog-button" type="button" :disabled="!acceptEnabled" @click.self="accepted">
                        Accept
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyriadDialog",
    props: {
        title: {
            default: "",
            type: String
        },
        dialogOpen: {
            default: false,
            type: Boolean
        },
        closeOnlyButton: {
            default: true,
            type: Boolean,
            required: false
        },
        acceptEnabled: {
            default: false,
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            dialogHeight: "100%"
        };
    },
    watch: {
        dialogOpen: function(newVal) {
            if(newVal === true) {
                this.setFullScreenHeight();
            }
        }
    },
    mounted: function () {
        window.addEventListener("keyup", this.escapeKeyPressed);
    },
    methods: {
        isHomePage() {
            var path = this.$route.path;
            var options = ["/"];
            return options.indexOf(path) > -1 ? "info-header info-header-background" : "info-header";
        },
        escapeKeyPressed: function (event) {
            if (event.which == 27) {
                this.$emit("dialogClose");
            }
        },
        setFullScreenHeight() {
            const body = document.body;
            const html = document.documentElement;
            const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
            this.$data.dialogHeight = height + "px";
        },
        accepted() {
            this.$emit("acceptedClicked");
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: $overlay-background;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dialog {
    background: $background-color;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -225px;
    margin-top: -175px;
    width: 550px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-radius: 10px;
    background-image: url("../../assets/bg_genesight_nucleotide_white.png");
    background-color: $background-color;
    background-position: left -127px top -80px;
    background-repeat: no-repeat;
    background-size: 40%;
}

.dialog-title-bar {
    @include flex-center-horizontal();
    justify-content: space-between;
    width: 100%;
}

.dialog-title-bar-space {
    align-self: flex-end;
    padding: 10px 30px;
    font-weight: 500;
    color: gray;
}

.dialog-title-bar-title {
    font-weight: 500;
    margin-top: 20px;
}

.dialog-title-bar-x {
    align-self: flex-end;
    padding: 10px 30px;
    font-weight: 500;
    color: gray;

    &:hover {cursor: pointer;}
}

.dialog-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.dialog-no-thanks {
    font-weight: 500;
    color: $genesight-blue;
    margin: 20px;

    &:hover {cursor: pointer;}
}

.dialog-button {
    background: $genesight-green;
    color: $white;
    border: none;
    border-radius: 20px;
    padding: 10px 65px;
    margin: 20px;
    font-size: 1.25em;

    &:hover {
        cursor: pointer;
        background: $genesight-hover-green;
    }

    &:disabled{
        cursor: auto;
        background: $disabled-btn-gray;
        color: $gray-text-color;
    }
}

@include sm {
    .dialog {
        width: 100%;
        height: 325px;
        top: 50%;
        left: 0;
        margin-left: auto;
        margin-top: -162px;
        background-position: left -110% top -95px;
        background-size: 65%;
    }

    .dialog-footer {
        justify-content: center;
    }

    .dialog-button {
        padding: 10px 50px;
    }
}

</style>
