import axios from "axios";
import Store from "../../store/store.js";
import { HTTP } from "./http";

const PigHttp = {
    params: {
        withCredentials: true
    },
    getIsValidToken: () => {
        const token = Store.getters.pigToken;
        const origin = Store.getters.pigOrigin;
        const url = `${config.rootApiUrl}validateToken?token=${token}&origin=${origin}`;

        return axios.get(url, PigHttp.params);
    },
    verifyInformation: (data) => {
        const token = Store.getters.pigToken;
        const url = `validateDateOfBirth?token=${token}`;
        return HTTP.post(url, data);
    },
    postPatientInsurance: (values) => {
        const token = Store.getters.pigToken;
        const url = `patientInsurance?token=${token}`;
        return HTTP.post(url, values);
    }
};

export default PigHttp;
