<template>
    <div class="view-report-verify-page">
        <form-validate 
            class-name="view-report-verify-info-form"
            id-name="viewReportForm"
            @no-errors-callback="removeErrorBanner"
            @invalid-callback="showFormErrors"
            @valid-callback="verifyInfo"
        >
            <full-name-comp
                class="view-report-full-name" 
                error-string="Invalid Name"
                :first-name="this.$store.getters.viewReportFirstName"
                :last-name="this.$store.getters.viewReportLastName"
                :disable-inputs="disableInputs"
                :has-error="errorInputs"
                @firstNameInput="setFirstName"
                @lastNameInput="setLastName"
                @FullNameInputChange="errorInputs = false"
            />
            <dob-comp 
                store-setting-string="setViewReportDob" 
                store-getter-string="viewReportDob" 
                :has-error="errorInputs"
                :disable="disableInputs"
                @DobInputChange="errorInputs = false"
            />
            <div class="input-row">
                <label class="input-label label" />
                <button class="submit-btn" type="submit" :disabled="disableInputs">
                    <p class="submit-btn-text">
                        Verify My Information
                    </p>
                </button>
            </div>
        </form-validate>
    </div>
</template>

<script>
import FullNameComp from "../components/layout/Demographic/FullNameInputs.vue";
import DobComp from "../components/layout/Demographic/DobInput.vue";
import ViewReportHttp from "../scripts/http/view-report-http.js";
import CommonFunc from "../scripts/common.js";

export default {
    name: "ViewReportVerify",
    components: {
        FullNameComp,
        DobComp
    },
    data() {
        return {
            errorMessage: "<b>We didn’t find a patient with that name and date of birth.</b><br>Please contact your healthcare provider for a new link, or GeneSight Customer Service at <a class='vp-link' href='mailto:support@genesight.com'>support@genesight.com</a> or <a class='vp-link' href='tel:866.757.9204'>866.757.9204</a> for assistance.",
            patientValidationErrorMessage: "<b>We didn’t find a patient with that name and date of birth. Please try again.</b>",
            disableInputs: false,
            errorInputs: false
        };
    },
    created() {
        CommonFunc.grabPatientToken((token, origin)=> {
            this.$store.dispatch("setViewReportToken", {token, origin});
        });
        this.checkValidViewReportToken();
    },
    mounted() {
        this.showExpiredInfoError();
    },
    methods: {
        checkValidViewReportToken() {
            ViewReportHttp.getIsValidToken().then(response => {
                if(response.data.result != "undefined" && response.data.result != "Unauthorized") {
                    this.$store.dispatch("setAntiforgeryToken", response.data.antiforgeryToken);
                    let attempts = parseInt(response.data.attempts);
                    let maxAttempts = parseInt(response.data.maxAttempts);
                    this.$store.dispatch("setPatientValidationAttempts", {maxAttempts, attempts});
                    this.$store.dispatch("setShowStartupLoader", false);
                }
                else {
                    this.goToUnauthorizedPage();
                }
            }).catch(()=> {
                this.goToUnauthorizedPage();
            });
        },
        setFirstName(name) {
            this.$store.dispatch("setViewReportFirstName", name);
        },
        setLastName(name) {
            this.$store.dispatch("setViewReportLastName", name);
        },
        showFormErrors() {
            CommonFunc.showFormErrors(this);
        },
        removeErrorBanner() {
            CommonFunc.removeErrorBanner(this);
        },
        showExpiredInfoError() {
            let attempts = this.$store.getters.currentPatientValidationAttempts;
            let maxAttempts = this.$store.getters.maxPatientValidationAttempts;

            this.errorInputs = false;
            this.disableInputs = false;
            
            if(attempts > 0){
                if(attempts < maxAttempts) {
                    this.$store.dispatch("patientValidationErrorMessageText", this.patientValidationErrorMessage);
                    this.errorInputs = true;
                }
                else if(attempts == maxAttempts) {
                    this.$store.dispatch("patientValidationErrorMessageText", this.errorMessage);
                    this.disableInputs = true;
                } 

                this.$store.dispatch("showPatientValidationError", true);
                this.$store.dispatch("setShowErrorMessage", false);
            }
        },
        verifyInfo() {
            var data = {
                firstName: this.$store.getters.viewReportFirstName,
                lastName: this.$store.getters.viewReportLastName,
                dateOfBirth: this.$store.getters.viewReportDob
            };

            this.$store.dispatch("setShowStartupLoader", true);
            ViewReportHttp.verifyInformation(data).then((response)=> {
                this.$store.dispatch("setValidateIdentityInfo", response.data);
                this.$store.dispatch("setShowErrorMessage", false);
                this.$store.dispatch("showPatientValidationError", false);
                this.$router.push("/view-report");
            }).catch((errorResponse)=> {
                if(errorResponse && errorResponse.response && errorResponse.response.data) {
                    let attempts = parseInt(errorResponse.response.data.attempts);
                    let maxAttempts = parseInt(errorResponse.response.data.maxAttempts);
                    this.$store.dispatch("setPatientValidationAttempts", {maxAttempts, attempts});
                    this.showExpiredInfoError();
                }
                else {
                    this.$router.push("/error");
                }
            }).finally(()=> {
                this.$store.dispatch("setShowStartupLoader", false);
            });
        },
        goToUnauthorizedPage() {
            this.$store.dispatch("setShowStartupLoader", false);
            this.$router.push({
                name: "Error",
                params: {
                    expiredVersion: true
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../styles/inputs.scss";

    .view-report-verify-page {
        @include site-section();
        padding-bottom: 50px;
        min-height: calc(100vh - 563px);
    }

    .view-report-verify-info-form {
        @include desktop-form-container()
    }

    .submit-btn-text {
        position: initial;
    }

    @include small-desktop() {
        .view-report-verify-page {
            @include site-section-sm-desktop();
        }
    }
    
    @include tablet() {
        .view-report-verify-page {
            @include view-report-tablet-padding();
            min-height: calc(100vh - 381px);
        }
    }

    @include sm() {
        .view-report-verify-page {
            min-height: none;
        }
    }
</style>
