<template>
    <transition name="fade">
        <div v-show="this.$store.getters.showFormHeaders" class="step-instructions">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p ref="stepInstructionsText" class="step-instructions-text" v-html="this.$sanitize(this.$store.getters.stepInstructions)" />
        </div>
    </transition>
</template>

<script>
export default {
    name: "StepInstructions",
    updated() {
        if(this.$router.currentRoute.path === "/view-report") {
            this.$refs.stepInstructionsText.innerHTML += " <b>" + this.$store.getters.tokenExpiration + "</b>.";
        }
    }
};
</script>

<style lang="scss" scoped>
    .step-instructions {
        @include site-section();
        @include flex-center-vertical();
        align-items: flex-start;
        margin-top: 28px;
        background: $step-instructions-bg;
        border-radius: 5px;
    }

    .step-instructions-text {
        @include step-text();
        line-height: 25px;
    }

    @include small-desktop {
        .step-instructions {
            @include site-section-sm-desktop();
        }
    }

    @include tablet {
        .step-instructions {
            padding-left: $mobile-banner-padding;
            padding-right: $mobile-banner-padding;
            background: transparent;
        }

        .step-instructions-text {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            background: $step-instructions-bg;
            font-size: 1em;
            font-weight: 400;
            border-radius: 5px;
        }
    }
</style>
