<template>
    <div class="insurance-page">
        <h2 class="insurance-header" :class="{ 'patient-information-gathering-header': $store.getters.getIsPatientInformationGathering }">
            <b>Primary</b> medical insurance information
        </h2>
        <a v-if="!$store.getters.getIsPatientInformationGathering" class="link insurance-link" @click="noInsuranceClick">
            {{ noInsuranceBlurb }}
        </a>
        <transition name="fade" mode="out-in">
            <form-validate
                v-if="!$store.getters.primaryNoInsurance"
                ref="primaryInsuranceForm"
                class="primary-insurance-form"
                @no-errors-callback="removeErrorsBanner"
                @valid-callback="goToSecondaryInsurancePage"
                @invalid-callback="showFormErrors"
            >
                <insurance-form ref="insuranceForm" :primary-insurance="true" :enable-medicare="true" />
            </form-validate>
            <no-insurance v-if="$store.getters.primaryNoInsurance" @close-no-insurance="closeNoInsuranceClick" />
        </transition>
        <btn-row :hide-back-button="$store.getters.getIsPatientInformationGathering" @continueClick="submitForm" @backClick="goToDemographicPage" /> 
    </div>
</template>

<script>
import InsuranceForm from "../components/layout/Insurance/InsuranceForm.vue";
import BtnRow from "../components/layout/BtnRow.vue";
import NoInsurance from "../components/ui/Insurance/NoInsuranceBlurb.vue";
import Common from "../scripts/common.js";
import BasePage from "./BasePage.vue";

export default {
    name: "Insurance",
    components: {
        BtnRow,
        InsuranceForm,
        NoInsurance
    },
    extends: BasePage,
    computed: {
        noInsuranceBlurb: function() {
            return this.$store.getters.primaryNoInsurance ? "I want to use insurance" : "I don't have or want to use insurance";
        },
        insuranceCardMissing() {
            return this.$store.getters.getPrimaryInsuranceCardsAdded && this.$store.getters.getArePrimaryCardsMissing && this.$store.getters.insuranceStepComplete;
        }
    },
    watch: {
        insuranceCardMissing(newValue, oldValue) {
            if(newValue !== oldValue) {
                if(newValue) {
                    this.showInsuranceCardsMissingError();
                }
                else {
                    this.$store.dispatch("");
                    this.$store.dispatch("setShowErrorMessage", false);
                }
            }
        }
    },
    mounted() {
        if(this.insuranceCardMissing) {
            this.showInsuranceCardsMissingError();
        }
    },
    methods: {
        goToDemographicPage() {
            this.$router.push("/demographic");
        },
        submitForm() {
            if(this.$store.getters.primaryNoInsurance) {
                this.$store.dispatch("setShowErrorMessage", false);
                this.$store.dispatch("setInsuranceStepComplete", true);
                this.$router.push("/medical");
            }
            else {
                this.$nextTick(()=> {
                    this.$refs.insuranceForm.checkValidationSections();

                    const submitEvent = Common.createNewEvent("submit");
                    this.$refs.primaryInsuranceForm.$refs.validateForm.dispatchEvent(submitEvent);
                });    
            }
        },
        goToSecondaryInsurancePage() {
            this.$store.dispatch("setShowErrorMessage", false);
            if (this.$store.getters.getIsPatientInformationGathering) {
                this.$router.push("/patient-information-insurance/secondary");
            } else {
                this.$router.push("/insurance/secondary");
            }
        },
        showFormErrors() {
            this.$store.dispatch("setShowErrorMessage", true).then(()=> {
                this.$nextTick(()=> {
                    Common.scrollToErrorMessage();
                });
            });
        },
        removeErrorsBanner() {
            this.$store.dispatch("setShowErrorMessage", false);
        },
        noInsuranceClick() {
            this.$store.dispatch("clearPrimaryInsurance");
            this.$store.dispatch("clearPrimaryInsuranceCards");
            this.$store.dispatch("setShowErrorMessage", false);
            this.$store.dispatch("setPrimaryNoInsurance", !this.$store.getters.primaryNoInsurance);
        },
        closeNoInsuranceClick() {
            this.$store.dispatch("setPrimaryNoInsurance", !this.$store.getters.primaryNoInsurance);
        },
        showInsuranceCardsMissingError() {
            this.$store.dispatch("setErrorMessageText", "For your security, insurance card images are not saved when a page is refreshed. Please upload or enter your insurance information");
            this.$store.dispatch("setShowErrorMessage", true);

            this.submitForm();
        }
    }
};
</script>

<style lang="scss" scoped>
.insurance-link {
    max-width: 220px;
    margin-top: 20px;
    margin-bottom: 55px;
}

.patient-information-gathering-header {
    margin-bottom: 55px;
}

.primary-insurance-form {
    margin-bottom: 100px;
}

.insurance-page {
    @include site-section();
    margin-top: 25px;
}

@include small-desktop() {
    .insurance-page {
        @include site-section-sm-desktop();
    }
}

@include tablet() {
    .insurance-header {
        text-align: left;
    }

    .insurance-link {
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
    }
}
</style>
