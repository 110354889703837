<template>
    <div class="demographic-error-labels" style="width: 100%">
        <div class="input-row input-error-row myriad-required-notice">
            <label class="input-label label" />
            This information is required to continue.
        </div>
        <div class="input-row input-error-row myriad-error-notice">
            <label class="input-label label" />
            {{ invalidString }}
        </div>
    </div>
</template>

<script>
export default {
    name: "DemographicErrorLabels",
    props: {
        invalidString: {
            type: String,
            default: "Invalid"
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/inputs.scss";

.demographic-error-labels {
    width: 100%;
    margin-top: 5px;
}

.input-error-row {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
    color: $error-red;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    font-size: $regular-copy-size;
    transition: 0.2s ease-in-out all;
}

.demographic-error-labels .input-error-row {
    margin-top: 0;
}

@include tablet {
    .demographic-error-labels .input-label {
        display: none;
    }
}
</style>
