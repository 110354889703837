<template>
    <div :class="containerClass">
        <h2 class="question-mobile-header myriad-validation-label">
            {{ question }}
        </h2>
        <h2 v-if="currentValue != ''" class="question-mobile-header question-mobile-answer">
            {{ currentValue }}
        </h2>
        <div v-else class="question-mobile-labels">
            <h2 class="question-mobile-header question-mobile-header-gray">
                Not at all
            </h2>
            <h2 class="question-mobile-header question-mobile-header-gray">
                Nearly every day
            </h2>
        </div>
        <myriad-slider 
            :class="sliderClass"
            :ticks="this.$store.getters.phq9Answers"
            :tick-index="active"
            @SliderSelection="updatePhq9Values"
        />
        <div class="myriad-required-notice medical-error-message">
            This information is required to continue
        </div>
    </div>
</template>

<script>
import MyriadSlider from "../../ui/MyriadSlider.vue";
import Common from "../../../scripts/common.js";

export default {
    name: "QuestionRowMobile",
    components: {
        MyriadSlider
    },
    props: {
        question: {
            default: "",
            type: String
        },
        questionIndex: {
            default: null,
            type: Number
        },
        active: {
            default: null,
            type: Number
        }
    },
    data: () => {
        return {
            currentValue: "",
            requiredValidation: true
        };
    },
    computed: {
        containerClass() {
            let stringVal = "question-row-mobile";
            if(this.requiredValidation && this.active === null) {
                stringVal = stringVal + " " + "myriad-container-required";
            }
            return stringVal;
        },
        sliderClass() {
            let className = "";
            if(this.requiredValidation && this.active === null) {
                className += "myriad-required-error";
            }
            return className;
        }
    },
    watch: {
        active(newVal) {
            if(Common.doesExist(newVal)) {
                this.currentValue = this.$store.getters.phq9Answers[newVal].text;
            }
        }
    },
    mounted() {
        if(this.active) {
            this.currentValue = this.$store.getters.phq9Answers[this.active].text;
        }
    },
    methods: {
        setRangeMaxValue() {
            this.$el.querySelector(".question-slider").removeAttribute("disabled");
            this.$el.querySelector(".question-slider").setAttribute("max", "3");
        },
        updatePhq9Values(event) {
            this.requiredValidation = false;
            this.$emit("phq9Answered", {value: event.value, questionIndex: this.questionIndex});
        }
    }
};
</script>

<style lang="scss" scoped>
    .question-row-mobile {
        margin-bottom: 60px;
    }

    .question-mobile-header {
        margin-bottom: 2px;
        font-size: 0.9em;
        font-weight: 600;
        color: $dark-gray-text-color;
    }

    .question-mobile-answer {
        margin-top: 15px;
        margin-bottom: 2px;
        color: $genesight-green;
    }

    .question-mobile-header-gray {
        font-weight: 400;
    }

    .question-mobile-labels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        
    }

    .medical-error-message {
        width: 100%;
        max-height: 0;
        text-align: center;
        font-size: $regular-copy-size;
        color: $error-red;
        overflow: hidden;
    }
</style>

