<template>
    <div class="insurance-error-label">
        <div class="required-message">
            This information is required to continue.
        </div>
        <div class="invalid-message">
            {{ invalidString }}
        </div>
    </div>
</template>

<script>
export default {
    name: "InsuranceErrorLabel",
    props: {
        invalidString: {
            type: String,
            default: "Invalid"
        }
    }
};
</script>

<style lang="scss" scoped>
    .insurance-error-label {
        width: auto;
        height: 0;
        margin-top: 0px;
        padding-left: calc(#{$insurance-label-width} + #{$insurance-label-mr});
        color: $error-red;
        overflow: hidden;
        opacity: 0;
    }

    .required-message {
        display: none;
        font-size: $regular-copy-size;
    }

    .invalid-message {
        display: none;
        font-size: $regular-copy-size;
    }
</style>
