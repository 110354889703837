<template>
    <div class="unsubscribe-page">
        <div class="unsub-content">
            <div v-if="unsubscribed">
                <h1 class="unsub-header">
                    Unsubscribe successful
                </h1>
                <p class="unsub-copy">
                    You will no longer receive information collection emails from us at {{ email }}.
                </p>
                <p class="unsub-copy">
                    <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                    If you change your mind, please contact Customer Service at <a href="mailto:support@genesight.com">support@genesight.com</a> or 866.757.9204.
                </p>
                <p class="unsub-copy unsub-copy--nbm">
                    In the meantime, feel free to visit us on the social platforms below:
                </p>
            </div>
            <div v-else>
                <h1 class="unsub-header">
                    Was it something we said?
                </h1>
                <p class="unsub-copy">
                    We hope this is not a permanent break up.
                </p>
                <p class="unsub-copy">
                    <b>Email:</b> {{ email }}
                </p>
                <p class="unsub-copy unsub-copy--nbm">
                    How about a second chance? Check us out on:
                </p>
            </div>
            <div class="social-section">
                <a class="unsub-social-icon" href="https://www.facebook.com/GeneSight/" target="_blank">
                    <facebook />
                </a>
                <a class="unsub-social-icon" href="https://twitter.com/GeneSight" target="_blank">
                    <twitter />
                </a>
                <a class="unsub-social-icon" href="https://www.linkedin.com/company/myriadneuroscience" target="_blank">
                    <linkedin />
                </a>
                <a class="unsub-social-icon" href="https://www.youtube.com/genesight" target="_blank">
                    <youtube />
                </a>
                <a class="unsub-social-icon" href="https://www.instagram.com/genesight/" target="_blank">
                    <img src="../assets/instagram.png" class="unsub-social-icon">
                </a>
            </div>
            <button v-if="!unsubscribed" class="submit-btn" type="button" @click="unsubscribeClick">
                <p>
                    Unsubscribe
                </p>
            </button>
        </div>        
    </div>
</template>

<script>
import UnsubHttp from "../scripts/http/unsubscribe-http.js";
import PatientHttp from "../scripts/http/patient-http.js";
import Common from "../scripts/common.js";

import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import linkedin from "../assets/linkedin.svg";
import youtube from "../assets/youtube.svg";

export default {
    name: "Unsubscribe",
    components: {
        facebook,
        twitter,
        linkedin,
        youtube
    },
    data() {
        return {
            email: "",
            unsubscribed: false
        };
    },
    mounted() {
        this.$store.dispatch("setShowStartupLoader", false);

        let email = Common.getUrlParamByName(window.location.hash, "email");

        if(Common.doesExistAndNotEmpty(email)) {
            this.email = email;
        }
        else {
            this.$router.push({
                name: "Error",
                params: {
                    expiredVersion: false
                }
            });
        }  
    },
    methods: {
        unsubscribeClick() {
            PatientHttp.getAntiForgeryToken().then(data => {
                this.$store.dispatch("setAntiforgeryToken", data.data);
                UnsubHttp.postUnsubscribe(this.email);
                this.unsubscribed = true;
            });  
        }
    }
};
</script>

<style lang="scss" scoped>

.unsub-content {
    padding-top: 200px;
    padding-bottom: 200px;
    padding-left: 290px;
}

.unsub-header {
    margin-bottom: 30px;
    font-size: $large-header-size;
    font-weight: 400;
}

.unsub-copy {
    margin-bottom: 45px;
    font-size: $mini-header-font-size;
}

.unsub-copy--nbm {
    margin-bottom: 5px;
}

.unsub-social-icon {
    width: 50px;
    height: 50px;
    margin-right: 54px;
}

.social-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 85px;
}

@include small-desktop {
    .unsub-content {
        padding: 100px;
    }
}

@include tablet {
    .unsub-content {
        padding: 25px 50px;
    }

    .unsub-header  {
        margin-bottom: 15px;
        font-size: 1.75em;
    }

    .unsub-copy {
        margin-bottom: 25px;
        font-size: 1em;
    }

    .unsub-copy--nbm {
        margin-bottom: 5px;
    }
}

@include sm {
    .unsub-content {
        padding: 20px 20px;
    }

    .unsub-social-icon {
        margin-right: auto;
    }

    .submit-btn {
        margin: 0 auto;
    }
}
</style>
