export default {
    state: {
        surveyToken: null,
        surveyOrigin: null,
        consentAccepted: null,
        showBackBtnDialog: false,
        showSurvey1ErrorBubble: false,
        pagesSaved: 0,
        medicationOptions: [],
        surveyComplete: false
    },
    getters: {
        surveyToken: state => {
            return state.surveyToken;
        },
        surveyOrigin: state => {
            return state.surveyOrigin;
        },
        consentAccepted: state => {
            return state.consentAccepted;
        },
        showBackBtnDialog: state => {
            return state.showBackBtnDialog;
        },
        showSurvey1ErrorBubble: state => {
            return state.showSurvey1ErrorBubble;
        },
        lastPagesSaved: state => {
            return state.pagesSaved;
        },
        medicationOptions: state => {
            return state.medicationOptions;
        },
        surveyComplete: state => {
            return state.surveyComplete;
        }
    },
    mutations: {
        setSurveyToken(state, {token, origin}) {
            state.surveyToken = token;
            state.surveyOrigin = origin;
        },
        setConsentAccepted(state, value) {
            state.consentAccepted = value;
        },
        setShowBackBtnDialog(state, value) {
            state.showBackBtnDialog = value;
        },
        setShowSurvey1ErrorBubble(state, value) {
            state.showSurvey1ErrorBubble = value;
        },
        setPagesSaved(state, value) {
            state.pagesSaved = value;
        },
        setMedicationOptions(state, value) {
            state.medicationOptions = value;
        },
        setSurveyComplete(state, value) {
            state.surveyComplete = value;
        }
    },
    actions: {
        setSurveyToken(context, {token, origin}) {
            context.commit("setSurveyToken", {token, origin});
        },
        setConsentAccepted(context, value) {
            context.commit("setConsentAccepted", value);
        },
        setShowBackBtnDialog(context, value) {
            context.commit("setShowBackBtnDialog", value);
        },
        setShowSurvey1ErrorBubble(context, value) {
            context.commit("setShowSurvey1ErrorBubble", value);
        },
        setPagesSaved(context, value) {
            context.commit("setPagesSaved", value);
        },
        setMedicationOptions(context, value) {
            context.commit("setMedicationOptions", value);
        },
        setSurveyComplete(context, value) {
            context.commit("setSurveyComplete", value);
        }
    }
};
