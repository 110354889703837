<template>
    <div class="patient-information-verify-page">
        <form-validate 
            class-name="patient-information-verify-info-form"
            id-name="viewReportForm"
            @no-errors-callback="removeErrorBanner"
            @invalid-callback="showFormErrors"
            @valid-callback="verifyInfo"
        >
            <dob-comp 
                store-setting-string="setPigDob" 
                store-getter-string="pigDob" 
                :has-error="errorInputs"
                :disable="disableInputs"
                label="Patient Date of Birth"
                @DobInputChange="errorInputs = false"
            />
            <div class="input-row">
                <label class="input-label label" />
                <button class="submit-btn pig-button" type="submit" :disabled="disableInputs">
                    <p class="submit-btn-text">
                        Continue
                    </p>
                    <img class="submit-btn-image" src="../assets/btn_arrow.png">
                </button>
            </div>
        </form-validate>
    </div>
</template>

<script>
import DobComp from "../components/layout/Demographic/DobInput.vue";
import PatientInformationGatheringHttp from "../scripts/http/patient-information-gathering-http.js";
import CommonFunc from "../scripts/common.js";

export default {
    name: "PatientInformationVerify",
    components: {
        DobComp
    },
    data() {
        return {
            errorMessage: "<b>The date of birth entered below isn't a match for this order. </b><br>Please contact your healthcare provider for a new link, or GeneSight Customer Service at <a class='vp-link' href='mailto:support@genesight.com'>support@genesight.com</a> or <a class='vp-link' href='tel:866.757.9204'>866.757.9204</a> for assistance.",
            pigValidationErrorMessage: "<b>The date of birth entered below is not a match for this order. Please try again.</b>",
            disableInputs: false,
            errorInputs: false
        };
    },
    created() {
        CommonFunc.grabPatientToken((token, origin)=> {
            this.$store.dispatch("setPigToken", {token, origin});
            this.$store.dispatch("setPatientToken", {token, origin});
        });
        this.checkValidPigToken();
    },
    mounted() {
        this.showExpiredInfoError();
    },
    methods: {
        checkValidPigToken() {
            PatientInformationGatheringHttp.getIsValidToken().then(response => {
                if(response.data.result != "undefined" && response.data.result != "Unauthorized") {
                    this.$store.dispatch("setAntiforgeryToken", response.data.antiforgeryToken);
                    let attempts = parseInt(response.data.attempts);
                    let maxAttempts = parseInt(response.data.maxAttempts);
                    this.$store.dispatch("setPigValidationAttempts", {maxAttempts, attempts});
                    this.$store.dispatch("setShowStartupLoader", false);

                    if (response.data.status === "Completed") {
                        this.goToThankYouPage();
                    }
                }
                else {
                    this.goToUnauthorizedPage();
                }
            }).catch(()=> {
                this.goToUnauthorizedPage();
            });
        },
        showFormErrors() {
            CommonFunc.showFormErrors(this);
        },
        removeErrorBanner() {
            CommonFunc.removeErrorBanner(this);
        },
        showExpiredInfoError() {
            let attempts = this.$store.getters.currentPatientValidationAttempts;
            let maxAttempts = this.$store.getters.maxPatientValidationAttempts;

            this.errorInputs = false;
            this.disableInputs = false;
            
            if(attempts > 0){
                if(attempts < maxAttempts) {
                    this.$store.dispatch("pigValidationErrorMessageText", this.pigValidationErrorMessage);
                    this.errorInputs = true;
                }
                else if(attempts == maxAttempts) {
                    this.$store.dispatch("pigValidationErrorMessageText", this.errorMessage);
                    this.disableInputs = true;
                } 

                this.$store.dispatch("showPigValidationError", true);
                this.$store.dispatch("setShowErrorMessage", false);
            }
        },
        verifyInfo() {
            var data = {
                dateOfBirth: this.$store.getters.pigDob
            };

            this.$store.dispatch("setShowStartupLoader", true);
            PatientInformationGatheringHttp.verifyInformation(data).then((response)=> {
                this.$store.dispatch("setTokenExpiration", response.data);
                this.$store.dispatch("setShowErrorMessage", false);
                this.$store.dispatch("showPigValidationError", false);
                this.$router.push("/patient-information-insurance/primary");
            }).catch((errorResponse)=> {
                if(errorResponse && errorResponse.response && errorResponse.response.data) {
                    let attempts = parseInt(errorResponse.response.data.attempts);
                    let maxAttempts = parseInt(errorResponse.response.data.maxAttempts);
                    this.$store.dispatch("setPatientValidationAttempts", {maxAttempts, attempts});
                    this.showExpiredInfoError();
                }
                else {
                    this.$router.push("/error");
                }
            }).finally(()=> {
                this.$store.dispatch("setShowStartupLoader", false);
            });
        },
        goToUnauthorizedPage() {
            this.$store.dispatch("setShowStartupLoader", false);
            this.$router.push({
                name: "Error",
                params: {
                    expiredVersion: true
                }
            });
        },
        goToThankYouPage() {
            this.$store.dispatch("setReviewStepComplete", true);
            this.$router.push("/complete");
        }
    }
};
</script>

<style lang="scss" scoped>
  @import "../styles/inputs.scss";

  .patient-information-verify-page {
      @include site-section();
      padding-bottom: 50px;
      min-height: calc(100vh - 563px);
  }

  .view-report-verify-info-form {
      @include desktop-form-container()
  }

  ::v-deep .dob-component {
    margin-left: 63px;
    margin-top: 30px;
  }

  ::v-deep .myriad-validation-label {
    min-width: 149px;
  }

  ::v-deep .myriad-container-warning-notice .demographic-dob-warning {
    margin-left: 162px;
  }

  .pig-button {
    margin-left: 85px;
  }

  @include small-desktop() {
      .patient-information-verify-page {
          @include site-section-sm-desktop();
      }
  }
  
  @include tablet() {
    .patient-information-verify-page {
        @include view-report-tablet-padding();
        min-height: calc(100vh - 381px);
    }

    ::v-deep .myriad-container-warning-notice .demographic-dob-warning {
        margin-left: 0;
    }

    .pig-button {
        margin-left: 60px;
    }
  }

  @include sm() {
    .patient-information-verify-page {
        min-height: none;
    }

    ::v-deep .dob-component {
        margin-left: 0;
    }

    .pig-button {
        margin-left: 0;
    }
  }
</style>
