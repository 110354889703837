export default {
    state: {
        phq9Questions: [],
        phq9Answers: [],
        phq9Score: 0,
        phqInfoSet: false
    },
    getters: {
        phq9Questions: state => {
            return state.phq9Questions;
        },
        phq9Answers: state => {
            return state.phq9Answers;
        },
        phq9Score: state => {
            return state.phq9Score;
        },
        getPhqInfoSet: state => {
            return state.phqInfoSet;
        }
    },
    mutations: {
        setPhq9Answer(state, {index, value}) {
            state.phq9Questions[index].answer = value;

            state.phq9Score = 0;
            state.phq9Questions.forEach((question) => {
                state.phq9Score = state.phq9Score + question.answer;
            });
        },
        setPhq9Answers(state, {answers}) {
            state.phq9Answers = answers;
        },
        setPhq9Questions(state, {questions}) {
            state.phq9Questions = questions;
        },
        setPhqInfoSet(state, value) {
            state.phqInfoSet = value;
        }
    },
    actions: {
        setPhq9Answer(context, {index, value}) {
            context.commit("setPhq9Answer", {index, value});
        },
        setPhq9Data(context, {patientPayload}) {
            var answers = [];
            for (var a in patientPayload.phQ9Answers) {
                answers.push({ 
                    text: patientPayload.phQ9Answers[a],
                    class: "phq9-" + a,
                    value: parseInt(a)
                });
            }

            var questions = [];
            for (var q in patientPayload.phQ9Questions) {
                questions.push({ 
                    questionId: parseInt(q),
                    question: patientPayload.phQ9Questions[q],
                    answer: null
                });
            }

            context.commit("setPhq9Answers", {answers});
            context.commit("setPhq9Questions", {questions});
            context.commit("setPhqInfoSet", true);
        }
    }
};
