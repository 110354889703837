import { render, staticRenderFns } from "./MyriadSlider.vue?vue&type=template&id=d1561e90&scoped=true&"
import script from "./MyriadSlider.vue?vue&type=script&lang=js&"
export * from "./MyriadSlider.vue?vue&type=script&lang=js&"
import style0 from "./MyriadSlider.vue?vue&type=style&index=0&id=d1561e90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.registry.npmjs.org/vue-loader/15.9.8_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1561e90",
  null
  
)

export default component.exports