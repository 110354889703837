export default {
    state: {
        stepHeaderTitle: "",
        stepInstructions: "",
        showFormHeaders: false,
        hideBreadcrumbs: false,
        isIE: false,
        showStartupLoader: false,
        showErrorMessage: false,
        errorMessageText: "",
        demographicStepComplete: false,
        insuranceStepComplete: false,
        medicalStepComplete: false,
        reviewStepComplete: false,
        isPatientInformationGathering: false
    },
    getters: {
        stepHeaderTitle: state => {
            return state.stepHeaderTitle;
        },
        stepInstructions: state => {
            return state.stepInstructions;
        },
        showStartupLoader: state => {
            return state.showStartupLoader;
        },
        showFormHeaders: state => {
            return state.showFormHeaders;
        },
        hideBreadcrumbs: state => {
            return state.hideBreadcrumbs;
        },
        showErrorMessage: state => {
            return state.showErrorMessage;
        },
        demographicStepComplete: state => {
            return state.demographicStepComplete;
        },
        insuranceStepComplete: state => {
            return state.insuranceStepComplete;
        },
        medicalStepComplete: state => {
            return state.medicalStepComplete;
        },
        reviewStepComplete: state => {
            return state.reviewStepComplete;
        },
        getErrorMessageText: state => {
            return state.errorMessageText;
        },
        getIsPatientInformationGathering: state => {
            return state.isPatientInformationGathering;
        }
    },
    mutations: {
        setStepHeaderTitle(state, value) {
            state.stepHeaderTitle = value;
        },
        setStepInstructions(state, value) {
            state.stepInstructions = value;
        },
        setShowFormHeaders(state, value) {
            state.showFormHeaders = value;
        },
        setHideBreadcrumbs(state, value) {
            state.hideBreadcrumbs = value;
        },
        setShowStartupLoader(state, value) {
            if(value) {
                document.body.style = "overflow: hidden";
            }
            else {
                document.body.style = "";
            }
            state.showStartupLoader = value;
        },
        setShowErrorMessage(state, value) {
            state.showErrorMessage = value;
        },
        setDemographicStepComplete(state, value) {
            state.demographicStepComplete = value;
        },
        setInsuranceStepComplete(state, value) {
            state.insuranceStepComplete = value;
        },
        setMedicalStepComplete(state, value) {
            state.medicalStepComplete = value;
        },
        setReviewStepComplete(state, value) {
            state.reviewStepComplete = value;
        },
        setErrorMessageText(state, value) {
            state.errorMessageText = value;
        },
        setIsPatientInformationGathering(state, value) {
            state.isPatientInformationGathering = value;
        }
    },
    actions: {
        setStepHeaderTitle(context, value) {
            context.commit("setStepHeaderTitle", value);
        },
        setStepInstructions(context, value) {
            context.commit("setStepInstructions", value);
        },
        setShowFormHeaders(context, value) {
            context.commit("setShowFormHeaders", value);
        },
        setHideBreadcrumbs(context, value) {
            context.commit("setHideBreadcrumbs", value);
        },
        setShowStartupLoader(context, value) {
            context.commit("setShowStartupLoader", value);
        },
        setShowErrorMessage(context, value) {
            context.commit("setShowErrorMessage", value);
        },
        setDemographicStepComplete(context, value) {
            context.commit("setDemographicStepComplete", value);
        },
        setInsuranceStepComplete(context, value) {
            context.commit("setInsuranceStepComplete", value);
        },
        setMedicalStepComplete(context, value) {
            context.commit("setMedicalStepComplete", value);
        },
        setReviewStepComplete(context, value) {
            context.commit("setReviewStepComplete", value);
        },
        setErrorMessageText(context, value) {
            context.commit("setErrorMessageText", value);
        },
        setIsPatientInformationGathering(context, value) {
            context.commit("setIsPatientInformationGathering", value);
        }
    }
};
