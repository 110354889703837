import { createServer, Response } from "miragejs";

export function makeCypressServer({ environment = "test" } = {}) {
    let otherDomains = [];
    let methods = ["get", "put", "patch", "post", "delete"];

    let server = createServer({
        environment,

        routes() {
            for (const domain of ["http://localhost/", ...otherDomains]) {
                for (const method of methods) {
                    this[method](`${domain}*`, async (schema, request) => {
                        let [
                            status,
                            headers,
                            body
                        ] = await window.handleFromCypress(request);
                        return new Response(status, headers, body);
                    });
                }
            }

            this.passthrough("https://services.myriad.com/**");
        }
    });

    return server;
}
