import moment from "moment";
import Common from "../../scripts/common.js";

export default {
    state: {
        patientToken: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        sex: null,
        street: null,
        city: null,
        state: null,
        zip: null,
        aptSte: null,
        email: null,
        phone: null,
        phoneType: null,
        patientOrigin: null
    },
    getters: {
        patient: state => {
            return {
                token: state.patientToken,
                firstName: state.firstName,
                lastName: state.lastName,
                dateOfBirth: state.dateOfBirth,
                sex: state.sex,
                phone: state.phone,
                phoneType: state.phoneType,
                email: state.email,
                address: {
                    address1: state.street,
                    address2: state.aptSte,
                    city: state.city,
                    state: state.state,
                    postalCode: state.zip
                }
            };
        },
        patientToken: state => {
            return state.patientToken;
        },
        patientFirstname: state => {
            return state.firstName;
        },
        patientLastName: state => {
            return state.lastName;
        },
        patientDob: state => {
            return state.dateOfBirth;
        },
        patientSex: state => {
            return state.sex;
        },
        patientPhone: state => {
            return state.phone;
        },
        patientPhoneType: state => {
            return state.phoneType;
        },
        patientEmail: state => {
            return state.email;
        },
        patientOrigin: state => {
            if(state.patientOrigin == "NA"){
                return "";
            }
            else{
                var origin = state.patientOrigin;
                state.patientOrigin = "NA";
                return origin;
            }
        },
        patientAddress: state => {
            if (state.street != null) {
                return state.street + " " + state.city + ", " + state.state + " " + state.zip;
            }
            else {
                return state.street;
            }
        },
        patientStreet: state => {
            return state.street;
        },
        patientCity: state => {
            return state.city;
        },
        patientState: state => {
            return state.state;
        },
        patientZip: state => {
            return state.zip;
        },
        patientAptSte: state => {
            return state.aptSte;
        }
    },
    mutations: {
        setPatientToken(state, {token, origin}) {
            state.patientToken = token;
            if(state.patientOrigin != "NA"){
                state.patientOrigin = origin;
            }
        },
        setDefaultPatientInfo(state, {firstName, lastName, dateOfBirth, email, phone, phoneType}) {
            if(state.firstName === null) {
                state.firstName = firstName;
            }
            if(state.lastName === null) {
                state.lastName = lastName;
            }
            if(state.dateOfBirth === null) {
                state.dateOfBirth = dateOfBirth;
            }
            if(state.email === null) {
                state.email = email;
            }
            if(state.phone === null) {
                state.phone = phone;
            }
            if(state.phoneType === null) {
                state.phoneType = phoneType;
            }
        },
        setPatientFirstName(state, name) {
            state.firstName = name;
        },
        setPatientLastName(state, name) {
            state.lastName = name;
        },
        setPatientDob(state, dob) {
            state.dateOfBirth = dob;
        },
        setPatientSex(state, sex) {
            state.sex = sex;
        },
        setPatientAddressData(state, {street, city, stateLoc, zip}) {
            state.street = street;
            state.city = city;
            state.state = stateLoc;
            state.zip = zip;
        },
        setPatientCity(state, city) {
            state.city = city;
        },
        setPatientState(state, stateName) {
            state.state = stateName;
        },
        setPatientStreet(state, street) {
            state.street = street;
        },
        setPatientZip(state, zip) {
            state.zip = zip;
        },
        setPatientAptSte(state, aptSte) {
            state.aptSte = aptSte;
        },
        setPatientPhone(state, phone) {
            state.phone = phone;
        },
        setPatientPhoneType(state, value) {
            state.phoneType = value;
        },
        setPatientEmail(state, email) { 
            state.email = email;
        }
    },
    actions: {
        setPatientToken(context, {token, origin}) {
            context.commit("setPatientToken", {token, origin});
        },
        setDefaultPatientInfo(context, {firstName, lastName, dateOfBirth, email, phone, phoneType}) {
            if(Common.doesExistAndNotEmpty(dateOfBirth)) {
                dateOfBirth = moment(dateOfBirth).format("MM/DD/YYYY");
            }
            
            if(phoneType == 1){
                phoneType = "Mobile";
            }
            else if(phoneType == 2){
                phoneType = "NotMobile";
            }
            else {
                phoneType = "Unknown";
            }

            context.commit("setDefaultPatientInfo", {firstName, lastName, dateOfBirth, email, phone, phoneType});
        },
        setPatientFirstName(context, name) {
            context.commit("setPatientFirstName", name);
        },
        setPatientLastName(context, name) {
            context.commit("setPatientLastName", name);
        },
        setPatientDob(context, dob) {
            let convertedDob = Common.ConvertTwoDigitYearToFour(dob);
            context.commit("setPatientDob", convertedDob);
        },
        setPatientSex(context, sex) {
            context.commit("setPatientSex", sex);
        },
        setPatientAddressData(context, {street, city, stateLoc, zip}) {
            context.commit("setPatientAddressData", {street, city, stateLoc, zip});
        },
        setPatientCity(context, city) {
            context.commit("setPatientCity", city);
        },
        setPatientStreet(context, street) {
            context.commit("setPatientStreet", street);
        },
        setPatientState(context, stateName) {
            context.commit("setPatientState", stateName);
        },
        setPatientZip(context, zip) {
            context.commit("setPatientZip", zip);
        },
        setPatientAptSte(context, aptSte) {
            context.commit("setPatientAptSte", aptSte);
        },
        setPatientPhone(context, phone) {
            context.commit("setPatientPhone", phone);
        },
        setPatientPhoneType(context, phoneType) {
            context.commit("setPatientPhoneType", phoneType);
        },
        setPatientEmail(context, email) {
            context.commit("setPatientEmail", email);
        }
    }
};
