<template>
    <div class="error-bg">
        <div class="error-page">
            <h2 class="error-header">
                {{ header }}
            </h2>
            <h6 class="error-sub-header-expired">
                {{ subHeader }}
            </h6>
            <div v-if="$route.params.expiredVersion || $route.params.surveyExpiredVersion">
                <p class="error-text-expired">
                    If you have other questions, please contact Customer Service at <a class="no-underline" href="mailto:support@genesight.com">
                        support@genesight.com
                    </a> or <a class="no-underline" href="tel:866.757.9204">
                        866.757.9204
                    </a>
                </p>
            </div>
            <div v-else>
                <p class="error-notice">
                    An error has occurred and we're doing our best to resolve the problem as quickly as possible.
                </p>
                <p class="error-text">
                    In the meantime, here is what we recommend:
                </p>
                <div class="error-row">
                    <img class="error-img" src="../assets/refresh.png">
                    <p class="error-text">
                        <b>Refresh the page</b> (sometimes this helps)
                    </p>
                </div>
                <div class="error-row">
                    <img class="error-img" src="../assets/clock.png">
                    <p class="error-text">
                        <b>Try again later</b> (30 minutes or so)
                    </p>
                </div>
                <div class="error-row">
                    <img class="error-img" src="../assets/envelope.png">
                    <p class="error-text">
                        <b>Contact Customer Service</b> at <a class="no-underline" href="mailto:support@genesight.com">
                            support@genesight.com
                        </a> or <a class="no-underline" href="tel:866.757.9204">
                            866.757.9204
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BasePage from "./BasePage.vue";

export default {
    name: "ErrorPage",
    extends: BasePage,
    computed: {
        subHeader() {
            const notExpired = "We're sorry. This was certainly unexpected.";
            const expired = "Contact your healthcare provider to request a new link.";
            const surveyExpired = "This survey is no longer available, as the questions asked in it need to be answered soon after the GeneSight order is placed. We’re sorry that responding didn’t work out this time. Thank you for your time.";

            if(this.$route.params.expiredVersion) {
                return expired;
            }
            else if(this.$route.params.surveyExpiredVersion) {
                return surveyExpired;
            }
            else {
                return notExpired;
            }
        },
        header() {
            const notExpired = "Oops";
            const expired = "We’re sorry, your link has expired.";
            const surveyExpired = "We’re sorry, your link has expired.";

            if(this.$route.params.expiredVersion) {
                return expired;
            }
            else if(this.$route.params.surveyExpiredVersion) {
                return surveyExpired;
            }
            else {
                return notExpired;
            }
        }
    },
    mounted() {
        if (this.$route.params.expiredVersion || this.$route.params.surveyExpiredVersion){
            this.$store.dispatch("reset");
        }
    }
};
</script>

<style lang="scss" scoped>
    .error-bg {
        background-image: url("../assets/bg_genesight_nucleotide_white.png");
        background-position: left -573px top -310px;
        background-repeat: no-repeat;
        min-height: 700px;
    }

    .error-page {
        @include site-section();
        padding-top: 100px;
        padding-bottom: 65px;
        max-width: 900px;
        color: $text-color;
    }

    .error-header {
        margin-bottom: 55px;
        font-size: 2em;
    }

    .error-sub-header {
        margin-bottom: 45px;
        font-size: 1.5em;
    }

    .error-sub-header-expired {
        font-size: 1.2em;
        font-weight: 500;
        margin-bottom: 25px;
    }

    .error-notice {
        margin-bottom: 45px;
        font-size: $mini-header-font-size
    }

    .error-text {
        margin-bottom: 23px;
        font-size: $mini-header-font-size;
        line-height: 1.8em;
    }

    .error-text-expired {
        font-size: 1em;
        font-weight: 400;
    }

    .error-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 23px;
    }

    .error-row .error-text {
        margin-bottom: 0;
    }

    .error-img {
        min-width: 15px;
        margin-right: 8px;
    }

    .no-underline {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @include small-desktop {
        .error-page {
            @include site-section-sm-desktop();
        }
    }

    @include tablet {
        .error-bg {
            background: none;
        }

        .error-page {
            padding-top: 50px;
            padding-left: $mobile-padding;
            padding-right: $mobile-padding;
        }

        .error-row {
            align-items: baseline;
        }

        .error-text {
            line-height: 1.4em;
        }
    }
</style>

