export default {
    state: {
        antiforgeryToken: null,
        googleAPIKey: null,
        googleAutocompleteEnabled: null
    },
    getters: {
        antiforgeryToken: state => {
            return state.antiforgeryToken;
        },
        googleAPIKey: state => {
            return state.googleAPIKey;
        },
        googleAutocompleteEnabled: state => {
            return state.googleAutocompleteEnabled;
        }
    },
    mutations: {
        setDefaultConfigurationInfo(state, {antiforgeryToken, googleAPIKey, googleAutocompleteEnabled}) {
            state.antiforgeryToken = antiforgeryToken;
            state.googleAPIKey = googleAPIKey;
            state.googleAutocompleteEnabled = googleAutocompleteEnabled;
        },
        setAntiforgeryToken(state, value) {
            state.antiforgeryToken = value;
        }
    },
    actions: {
        setDefaultConfigurationInfo(context, {antiforgeryToken, googleAPIKey, googleAutocompleteEnabled}) {
            context.commit("setDefaultConfigurationInfo", {antiforgeryToken, googleAPIKey, googleAutocompleteEnabled});
        },
        setAntiforgeryToken(context, value) {
            context.commit("setAntiforgeryToken", value);
        }
    }
};
