<template>
    <div :class="buttonClass" @keydown="onEnterPress">
        <input :id="id" ref="radioInput" :name="name" class="button-radio-input" type="radio" :checked="label === vModelProperty" @change="changeEvent(label)">
        <label :for="id" class="button-label">
            <check-white class="button-radio-check" />
            <span class="button-radio-text">
                {{ label }}
            </span>
        </label>
    </div>
</template>

<script>
import Common from "../../scripts/common.js";
import CheckWhite from "../../assets/check_white.svg";

export default {
    name: "RadButton",
    components: {
        CheckWhite
    },
    props: {
        name: {
            default: "default",
            type: String,
            required: true
        },
        id: {
            default: "id",
            type: String
        },
        label: {
            default: "",
            type: String
        },
        vModelProperty: {
            default: "v-model-radio",
            type: String
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            allRadioButtons: []
        };
    },
    computed: {
        buttonClass() {
            return this.disabled ? "button-radio button-radio--disabled" : "button-radio";
        }
    },
    mounted() {
        const name = this.$refs.radioInput.name;
        this.$data.allRadioButtons = document.getElementsByName(name);
    },
    methods: {
        onEnterPress(event) {
            if(!this.disabled) {
                if(event.keyCode === 13 || event.keyCode === 32) {
                    event.preventDefault();
                    this.$refs.radioInput.checked = true;
                    this.$emit("input", this.label);

                    const clickEvent = Common.createNewEvent("click");
                    this.$refs.radioInput.dispatchEvent(clickEvent);
                }
                if(event.keyCode === 37) {
                    this.navigatePreviousRadioInput();
                }
                if(event.keyCode === 39) {
                    this.navigateNextRadioInput(); 
                }
            }
        },
        changeEvent(label) {
            if(!this.disabled) {
                this.$emit("input", label);
            }
        },
        navigateNextRadioInput() {
            let nextIndex = this.findIndexOfNextRadioButton();
            const parent = this.$data.allRadioButtons[nextIndex].closest(".button-radio");
            parent.focus();
        },
        navigatePreviousRadioInput() {
            let nextIndex = this.findIndexOfPreviousRadioButton();
            const parent = this.$data.allRadioButtons[nextIndex].closest(".button-radio");
            parent.focus();
        },
        getCurrentButtonIndex() {
            for(let i=0; i < this.$data.allRadioButtons.length; i++) {
                if(this.$data.allRadioButtons[i] === this.$refs.radioInput) {
                    return i;
                }
            }

            return 0;
        },
        findIndexOfNextRadioButton() {
            const currentIndex = this.getCurrentButtonIndex();
            if(currentIndex + 1 >= this.$data.allRadioButtons.length) {
                return 0;
            }
            else {
                return currentIndex + 1;
            } 
        },
        findIndexOfPreviousRadioButton() {
            const currentIndex = this.getCurrentButtonIndex();
            if(currentIndex - 1 < 0) {
                return this.$data.allRadioButtons.length - 1;
            }
            else {
                return currentIndex - 1;
            }
        }
    }
};
</script>


