<template>
    <transition name="fade">
        <div v-show="this.$store.getters.showFormHeaders && !this.$store.getters.hideBreadcrumbs" class="nav-step-container">
            <div class="nav-steps-line" />
            <div class="nav-steps">
                <router-link :class="$store.getters.demographicStepComplete ? 'nav-item nav-item-complete' : 'nav-item'" data-route="/demographic" to="/demographic" :event="$store.getters.demographicStepComplete ? 'click': ''">
                    <div class="nav-item-num">
                        1
                    </div>
                    <div class="nav-item-check nav-item-check--hidden">
                        &#10003;
                    </div>
                    <span class="nav-item-text">
                        Your Details
                    </span>
                </router-link>
                <router-link :class="$store.getters.insuranceStepComplete ? 'nav-item nav-item-complete' : 'nav-item'" data-route="/insurance" to="/insurance" :event="$store.getters.insuranceStepComplete ? 'click': ''">
                    <div class="nav-item-num">
                        2
                    </div>
                    <div class="nav-item-check nav-item-check--hidden">
                        &#10003;
                    </div>
                    <span class="nav-item-text">
                        Insurance
                    </span>
                </router-link>
                <router-link :class="$store.getters.medicalStepComplete ? 'nav-item nav-item-complete' : 'nav-item'" data-route="/medical" to="/medical" :event="$store.getters.medicalStepComplete ? 'click': ''">
                    <div class="nav-item-num">
                        3
                    </div>
                    <div class="nav-item-check nav-item-check--hidden">
                        &#10003;
                    </div>
                    <span class="nav-item-text">
                        Medical
                    </span>
                </router-link>
                <router-link :class="$store.getters.reviewStepComplete ? 'nav-item nav-item-complete' : 'nav-item'" data-route="/review" to="/review" :event="reviewStepClickable ? 'click': ''">
                    <div class="nav-item-num">
                        4
                    </div>
                    <div class="nav-item-check nav-item-check--hidden">
                        &#10003;
                    </div>
                    <span class="nav-item-text">
                        Review
                    </span>
                </router-link>
            </div>
            <div class="nav-steps nav-steps-mobile-labels">
                <router-link class="nav-step-label-container" data-route="/demographic" to="/demographic">
                    <div class="nav-item-text-mobile">
                        Your Details
                    </div>
                </router-link>
                <router-link class="nav-step-label-container" data-route="/insurance" to="/insurance">
                    <div class="nav-item-text-mobile">
                        Insurance
                    </div>
                </router-link>
                <router-link class="nav-step-label-container" data-route="/medical" to="/medical">
                    <div class="nav-item-text-mobile">
                        Medical
                    </div>
                </router-link>
                <router-link class="nav-step-label-container" data-route="/review" to="/review">
                    <div class="nav-item-text-mobile">
                        Review
                    </div>
                </router-link>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "NavSteps",
    computed: {
        reviewStepClickable() {
            const otherStepsComplete = this.$store.getters.medicalStepComplete && this.$store.getters.insuranceStepComplete && this.$store.getters.demographicStepComplete;
            return otherStepsComplete || this.$store.getters.reviewStepComplete;
        }
    }
};
</script>

<style lang="scss" scoped>
.nav-step-container {
    @include site-section();
}

.nav-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
}

.nav-steps--hidden {
    display: none;
}

.nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 280px;
    height: $nav-step-bubble-height;
    border-radius: 50px;
    font-size: $mini-header-font-size;
    font-weight: 900;
    text-decoration: none;
    color: $gray-text-color;
    background: white;
    border: 1px solid $gray-text-color;
    &:hover {
        cursor: pointer;
    }
}

.nav-item-text {
    margin-right: 35px;
    flex-grow: 1;
    letter-spacing: 1px;
    text-align: center;
}

.nav-item-num {
    @include flex-center-vertical();
    min-width: $nav-bubble-size;
    width: $nav-bubble-size;
    height: $nav-bubble-size;
    margin-left: $nav-bubble-spacing;
    font-family: "Arial";
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 500;
    border: 2px solid $gray-text-color;
    border-radius: 50%;
}

.nav-item-check {
    @include flex-center-vertical();
    min-width: $nav-bubble-size;
    width: $nav-bubble-size;
    height: $nav-bubble-size;
    margin-left: $nav-bubble-spacing;
    font-family: "Arial";
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: 500;
    border: 2px solid #7AC142;
    border-radius: 50%;
    color: #7AC142;
}

.nav-item-check--hidden {
    display: none;
}

.nav-steps-line {
    position: relative;
    top: calc(
        (#{$nav-step-bubble-height} / 2) + (#{$nav-step-line-height} / 2)
    );
    width: 90%;
    height: $nav-step-line-height;
    margin: 0 auto;
    background: $gray-text-color;
    z-index: 0;
}

.nav-item-spacer {
    width: $nav-bubble-size;
    height: $nav-bubble-size;
    margin-right: $nav-bubble-spacing;
}

.nav-steps-mobile-labels {
    display: none;
    margin-top: 8px;
}

.nav-step-label-container {
    width: 58px;
    text-decoration: none;
}

.nav-item-text-mobile {
    width: 68px;
    margin-left: -5px;
    font-size: 0.75em;
    font-weight: 600;
    color: $dark-gray-text-color;
    text-align: center;
    text-decoration: none;
}

.nav-item-complete {
    background: #EAF4E1;
    border-color: #7AC142;

    &:hover {
        cursor: pointer;
    }

    .nav-item-text{
        color: #7AC142;
    }

    .nav-item-num {
        display: none;
    }

    .nav-item-check {
        display: flex;
    }
}

.nav-item.router-link-active {
    background: $genesight-blue;
    color: $white;
    border: none;
}

.nav-item.router-link-active {
    .nav-item-text{
        color: $white;
    }
    
    .nav-item-num {
        display: flex;
        border-color: $white;
    }

    .nav-item-check {
        display: none;
    }
}

@include small-desktop {
    .nav-step-container {
        @include site-section-sm-desktop();
    }
}

@include tablet {
    .nav-step-container {
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
    }

    .nav-item {
        width: $nav-step-bubble-height;
        justify-content: center;
    }

    .nav-item-num,
    .nav-item-check {
        margin-left: 0;
        border: none;
    }

    .nav-item-text,
    .nav-item-spacer {
        display: none;
    }

    .nav-steps-mobile-labels {
        display: flex;
    }
    
    .nav-item-check {
        margin: 0px;
    }
}

@include sm {
    .nav-item {
        width: $nav-step-bubble-height-sm;
        height: $nav-step-bubble-height-sm;
    }

    .nav-steps-line {
        top: calc(
            (#{$nav-step-bubble-height-sm} / 2) + (#{$nav-step-line-height} / 2)
        );
    }
}

@media (max-width: 345px) {
    .nav-item-text-mobile {
        width: 100%;
    }
}
</style>
