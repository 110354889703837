<template>
    <div class="insurance-form">
        <div v-if="showMedicareQuestion" 
             v-radio-button-validation="{required: true, name: 'insurance-medicare'}" 
             class="insurance-row-container"
        >
            <div class="insurance-row">
                <label :class="showMedicareWarning ? 'label insurance-label medicare-warning' : 'label insurance-label'">
                    {{ medicareQuestionString }}
                </label>
                <rad-button
                    id="insurance-medicare-yes"
                    v-model="isThisMedicare"
                    :v-model-property="isThisMedicare"
                    class="policyholder-btn"
                    name="insurance-medicare"
                    label="Yes"
                    tabindex="0"
                    @click.native="medicareSelected"
                />
                <rad-button
                    id="insurance-medicare-no"
                    v-model="isThisMedicare"
                    :v-model-property="isThisMedicare"
                    class="policyholder-btn"
                    name="insurance-medicare"
                    label="No"
                    tabindex="-1"
                    @click.native="medicareUnselected"
                />
                <a class="link insurance-medicare-examples" @click="dialogOpen = true">
                    Not sure? View examples
                </a>
            </div>
            <error-label invalid-string="Invalid Medicare" />
            <div :class="showMedicareWarning ? 'medicare-age-warning medicare-age-warning--visible': 'medicare-age-warning'">
                <p class="medicare-warning-title">
                    <b>Are you sure?</b>
                </p>
                <p class="medicare-warning-copy">
                    Your date of birth indicates that you qualify for Medicare coverage.  Please ensure the correct response was selected.
                </p>
            </div> 
        </div>
        <div v-if="isThisMedicare === 'No' && this.$props.primaryInsurance" 
             v-radio-button-validation="{required: true, name: 'insurance-uhc'}" 
             class="insurance-row-container"
        >
            <div class="insurance-row">
                <label class="label insurance-label">
                    {{ uhcQuestionString }}
                </label>
                <rad-button
                    id="insurance-uhc-yes"
                    v-model="isThisUHC"
                    :v-model-property="isThisUHC"
                    class="policyholder-btn"
                    name="insurance-uhc"
                    label="Yes"
                    tabindex="0"
                />
                <rad-button
                    id="insurance-uhc-no"
                    v-model="isThisUHC"
                    :v-model-property="isThisUHC"
                    class="policyholder-btn"
                    name="insurance-uhc"
                    label="No"
                    tabindex="-1"
                />
                <a class="link insurance-uhc-examples" @click="uhcDialogOpen = true">
                    Not sure? View guidance
                </a>
            </div>
            <error-label invalid-string="Invalid UHC" />
        </div>
        <div 
            ref="policyholderRow" 
            v-radio-button-validation="{required: true, name: 'insurance-form'}"
            class="insurance-row-container"
        >
            <div class="insurance-row">
                <label class="label insurance-label check-policyholder-header">
                    Who is the policyholder?
                </label>
                <rad-button 
                    id="policyholder-me" 
                    ref="policyholderMe" 
                    v-model="policyHolderRelationship" 
                    :v-model-property="policyHolderRelationship" 
                    class="policyholder-btn"
                    name="insurance-form" 
                    label="Me" 
                    tabindex="0" 
                />
                <rad-button 
                    id="policyholder-parent" 
                    v-model="policyHolderRelationship" 
                    :v-model-property="policyHolderRelationship" 
                    :disabled="forcePolicyholderMeOnly"
                    class="policyholder-btn" 
                    name="insurance-form" 
                    label="My Parent" 
                    tabindex="-1"
                />
                <rad-button 
                    id="policyholder-spouse" 
                    v-model="policyHolderRelationship" 
                    :v-model-property="policyHolderRelationship"
                    :disabled="forcePolicyholderMeOnly" 
                    class="policyholder-btn" 
                    name="insurance-form" 
                    label="My Spouse" 
                    tabindex="-1"
                />
                <rad-button 
                    id="policyholder-other" 
                    v-model="policyHolderRelationship" 
                    :v-model-property="policyHolderRelationship" 
                    :disabled="forcePolicyholderMeOnly"
                    class="policyholder-btn" 
                    name="insurance-form" 
                    label="Other" 
                    tabindex="-1" 
                />
            </div>
            <error-label invalid-string="Invalid Policyholder" />
        </div>
        <transition name="grow" mode="out-in">
            <div v-if="!shouldHidePolicyholderInfo" class="policyholder-info">
                <h2 class="insurance-header insurance-form-header  policyholder-header">
                    Policyholder information
                </h2>
                <div v-container-validation class="insurance-row-container">
                    <div class="insurance-row">
                        <label class="label insurance-label">
                            Policyholder's Name
                        </label>
                        <input v-model="firstName"
                               v-max-length-validation="{maxLength: 100, required: true}"
                               class="text-input insurance-name-input"
                               type="text"
                               placeholder="First Name"
                        >
                        <input v-model="lastName"
                               v-max-length-validation="{maxLength: 100, required: true}"
                               class="text-input insurance-name-input"
                               type="text"
                               placeholder="Last Name"
                        >
                    </div>
                    <error-label invalid-string="Invalid Policyholder Name" />
                </div>
                <div v-container-validation class="insurance-row-container">
                    <div class="insurance-row">
                        <label class="label insurance-label">
                            Date of Birth
                        </label>
                        <input ref="dob"
                               v-date-mask
                               v-dob-validation="{required: true, minLength: 6}"
                               class="text-input"
                               type="text"
                               :value="this.$store.getters[this.$data.storeGetterPrefix + 'PolicyholderDateOfBirth']"
                               @keyup="updateDateOfBirth"
                               @blur="updateDateOfBirthNow"
                        >
                        <p class="insurance-dob-text">
                            {{ longDob }}
                        </p>
                    </div>
                    <error-label invalid-string="Invalid Date of Birth" />
                    <div class="insurance-dob-warning">
                        <p><b>Is this correct?</b></p>
                        <p>
                            The DOB entered indicates a patient age that is uncommon for GeneSight testing. If the date is correct, please disregard this message.
                        </p>
                    </div>
                </div>
                <div v-radio-button-validation="{required: true, name: 'policyholder-sex'}" class="insurance-row-container">
                    <div class="insurance-row insurance-sex-row">
                        <label class="label insurance-label">
                            Sex
                        </label>
                        <sex-tooltip class="mobile-tooltip" />
                        <div class="sex-button-wrapper">
                            <rad-button 
                                id="policyholder-male" 
                                v-model="sex" 
                                :v-model-property="sex" 
                                class="policyholder-btn" 
                                name="policyholder-sex" 
                                label="M" 
                                tabindex="0"
                            />
                            <rad-button 
                                id="policyholder-female" 
                                v-model="sex" 
                                :v-model-property="sex" 
                                class="policyholder-btn" 
                                name="policyholder-sex" 
                                label="F" 
                                tabindex="-1" 
                            />
                        </div>
                        <sex-tooltip class="desktop-tooltip" />
                    </div>
                    <error-label invalid-string="Invalid Policyholder Sex" />
                </div>
            </div>
        </transition>
        <div v-if="!showManualInsurance" class="insurance-row insurance-row-cards">
            <div class="insurance-label-wrapper">
                <label :class="!insuranceCardsSupplied ? 'insurance-card-error' : ''" class="label insurance-label check-policyholder-header">
                    Upload images of insurance card
                </label>
                <p class="insurance-card-notice">
                    Individual file size cannot exceed 20MB.
                </p>
                <p class="insurance-card-notice">
                    Accepted file types are .gif, .jpeg, .jpg, .png
                </p>
            </div>
            <validation-section class="insurance-card-mobile-order-3" :disable-validation="showManualInsurance">
                <div ref="insuranceCardUpload" class="insurance-card-upload">
                    <div v-container-validation="{disableValidation: $store.getters.testDisable}" class="insurance-card-image-container">
                        <card-preview 
                            ref="frontInsuranceCard" 
                            v-model="frontInsuranceCard" 
                            class="insurance-front-card" 
                            input-id="frontOfCard"
                            :front-of-card="true"
                            @input="checkValidationSections"
                        />
                        <div class="insurance-card-validation-message">
                            This information is required to continue.
                        </div>
                    </div>
                    <div v-container-validation class="insurance-card-image-container">
                        <card-preview 
                            ref="backInsuranceCard" 
                            v-model="backInsuranceCard" 
                            class="insurance-back-card" 
                            input-id="backOfCard"
                            :front-of-card="false"
                            @input="checkValidationSections"
                        />
                        <div class="insurance-card-validation-message">
                            This information is required to continue.
                        </div>
                    </div>
                </div>
            </validation-section>
        </div>
        <validation-section v-else class="insurance-card-mobile-order-1" :disable-validation="!showManualInsurance">
            <div ref="insuranceManualForm" class="insurance-card-manual insurance-card-manual--shown">
                <div v-container-validation class="insurance-row-container">
                    <div class="insurance-row">
                        <label class="label insurance-label">
                            Insurance Company Name
                        </label>
                        <input 
                            v-model="companyName" 
                            v-max-length-validation="{maxLength: 250, required: true}" 
                            class="text-input insurance-card-input" 
                            type="text"
                            @change="checkValidationSections"
                        >
                    </div>
                    <error-label class="insurance-error-label-mobile" invalid-string="Invalid Company Name" />
                </div>
                <div v-container-validation class="insurance-row-container">
                    <div class="insurance-row">
                        <label class="label insurance-label">
                            ID Number
                        </label>
                        <input 
                            v-model="idNumber" 
                            v-max-length-validation="{maxLength: 250, required: true}" 
                            class="text-input insurance-card-input insurance-card-input--sm" type="text"
                            @change="checkValidationSections"
                        >
                    </div>
                    <error-label class="insurance-error-label-mobile" invalid-string="Invalid ID Number" />
                </div>
                <div v-container-validation class="insurance-row-container">
                    <div class="insurance-row">
                        <label class="label insurance-label">
                            Group Number
                        </label>
                        <input 
                            v-model="groupNumber" 
                            v-max-length-validation="{maxLength: 250, required: false}" 
                            class="text-input insurance-card-input insurance-card-input--sm" type="text"
                            @change="checkValidationSections"
                        >
                    </div>
                    <error-label class="insurance-error-label-mobile" invalid-string="Invalid Group Number Sex" />
                </div>
                <div v-container-validation class="insurance-row-container">
                    <div class="insurance-row">
                        <label class="label insurance-label">
                            Phone Number
                        </label>
                        <input 
                            v-model="phoneNumber" 
                            v-phone-validation="{required: true}"
                            v-mask="'(###) ###-####'" 
                            class="text-input insurance-card-input insurance-card-input--sm" 
                            type="text"
                            @change="checkValidationSections"
                            @keyup="handlePhoneChange"
                        >
                    </div>
                    <error-label class="insurance-error-label-mobile" invalid-string="Invalid Phone Number" />
                </div>
            </div>
        </validation-section>
        <div v-if="primaryInsurance || (!primaryInsurance && !$store.getters.getIsPatientInformationGathering)">
            <div class="insurance-card-spacer insurance-card-mobile-order-2">
                <div class="insurance-card-line" />
                <div class="insurance-card-spacer-or">
                    or
                </div>
                <div class="insurance-card-line" />
            </div>
            <div v-if="!showManualInsurance" class="desktop-enter-choice" @click="handleManualInsurnaceClick(true)">
                manually enter insurance information
            </div>
            <div v-else class="desktop-enter-choice" @click="handleManualInsurnaceClick(false)">
                upload images of insurance card
            </div>
        </div>
        <myriad-dialog class="medicare-dialog" :dialog-open="dialogOpen" title="Medicare" @dialogClose="dialogClose">
            <p class="medicare-dialog-copy">
                The GeneSight test is covered by traditional Medicare and there is a $0 out of pocket costs to patients.  If you have a Medicare Advantage plan, you may be expected to cover a portion of test.  That's typically $330 or less.  If it's more, we will contact you before we process your test to discuss.
            </p>
            <div class="medicare-example-row">
                <div class="medicare-image-container">
                    <p class="medicare-example-title">
                        Medicare Example
                    </p>
                    <medicare-card-example class="medicare-example-image" />
                </div>
                <div class="medicare-image-container">
                    <p class="medicare-example-title">
                        Medicare Advantage Example
                    </p>
                    <medicare-example class="medicare-example-image" />
                </div> 
            </div>
        </myriad-dialog>
        <myriad-dialog class="medicare-dialog medicare-dialog--uhc" :dialog-open="uhcDialogOpen" title="UnitedHealthcare (UHC)" @dialogClose="uhcDialogClose">
            <div>
                <p class="medicare-dialog-paragraph">
                    Selecting the wrong insurance plan will cause delays in your clinician getting your results.
                </p>
                <p class="medicare-dialog-paragraph medicare-dialog-paragraph--no-bottom-margin">
                    If you have:
                </p>
                <ul class="medicare-dialog-list">
                    <li class="medicare-dialog-list-item">
                        <b>UHC Medicare</b>, select <b>Yes</b> for the <b>Medicare/Medicare Advantage</b> question.
                    </li>
                    <li class="medicare-dialog-list-item">
                        a <b>UHC Community Plan</b> (Managed Medicaid), select <b>No</b> for the UHC question.
                    </li>
                    <li>
                        <b>UMR or any other UHC plan</b>, select <b>Yes</b> for the UHC question.
                    </li>
                </ul>
                <p class="medicare-dialog-paragraph medicare-dialog-paragraph--no-bottom-margin">
                    If you have any questions about which insurance provider to select, please contact our Customer Service team at <a href="tel:+1-866-757-9204" class="medicare-dialog-link">866.757.9204</a> or <a href="mailto:support@genesight.com" class="medicare-dialog-link">support@genesight.com</a>.
                </p>
            </div>
        </myriad-dialog>
    </div>
</template>

<script>
import moment from "moment";
import Common from "../../../scripts/common.js";
import RadButton from "../../ui/ButtonRadioInput.vue";
import CardPreview from "../../ui/Insurance/InsuranceCardPreview.vue";
import ErrorLabel from "./InsuranceErrorLabel.vue";
import MyriadDialog from "../../layout/MyriadDialog.vue";
import MedicareExample from "../../../assets/medicare_advantage_card.svg";
import MedicareCardExample from "../../../assets/sample_medicare_card.svg";
import SexTooltip from "../Demographic/SexTooltip.vue";

export default {
    name: "InsuranceForm",
    components: {
        RadButton,
        CardPreview,
        ErrorLabel,
        MyriadDialog,
        MedicareExample,
        MedicareCardExample,
        SexTooltip
    },
    props: {
        primaryInsurance: {
            default: true,
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            storeSetterPrefix: this.$props.primaryInsurance ? "setPrimaryInsurance": "setSecondaryInsurance",
            storeGetterPrefix: this.$props.primaryInsurance ? "primary": "secondary",
            insuranceCardManualShown: false,
            dialogOpen: false,
            uhcDialogOpen: false,
            showMedicareWarning: false,
            insuranceCardsSupplied: false,
            manualInsuranceSupplied: false,
            longDob: "mm/dd/yyyy",
            longDobTimeout: null,
            forcePolicyholderMeOnly: false,
            showManualInsurance: (!this.$props.primaryInsurance && this.$store.getters.getIsPatientInformationGathering) ? this.$store.getters.isManualInsurance : false
        };
    },
    computed: {
        shouldHidePolicyholderInfo: {
            get() {
                return this.policyHolderRelationship === "Me" || this.policyHolderRelationship === "";
            }
        },
        isThisMedicare: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "InsuranceMedicare"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "Medicare", value);
            }
        },
        isThisUHC: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "InsuranceUHC"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "UHC", value);
            }
        },
        policyHolderRelationship: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "PolicyholderRelationship"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "PolicyholderRelationship", value);
            }
        },
        firstName: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "PolicyholderFirstName"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "FirstName", value);
            }
        },
        lastName: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "PolicyholderLastName"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "LastName", value);
            }
        },
        sex: {
            get() {
                return Common.getFormattedSex(this.$store.getters[this.$data.storeGetterPrefix + "PolicyholderSex"]);
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "Sex", Common.getSexString(value));
            }
        },
        companyName: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "InsuranceCompanyName"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "CompanyName", value);
            }
        },
        idNumber: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "InsuranceIdNumber"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "IdNumber", value);
            }
        },
        groupNumber: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "InsuranceGroupNumber"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "GroupNumber", value);
            }
        },
        phoneNumber: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "InsurancePhoneNumber"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "PhoneNumber", value);
            }
        },
        frontInsuranceCard: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "FrontInsuranceCard"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "FrontCard", value);
                this.setBothInsuranceImagesAdded();
            }
        },
        backInsuranceCard: {
            get() {
                return this.$store.getters[this.$data.storeGetterPrefix + "BackInsuranceCard"];
            },
            set(value) {
                this.$store.dispatch(this.$data.storeSetterPrefix + "BackCard", value);
                this.setBothInsuranceImagesAdded();
            }
        },
        medicareQuestionString() {
            const primaryInsurance = "Is your primary insurance Medicare or Medicare Advantage?";
            const secondaryInsurance = "Is your secondary insurance Medicare or Medicare Advantage?";

            return this.primaryInsurance ? primaryInsurance : secondaryInsurance;
        },
        uhcQuestionString() {
            const primaryUHC = "Is your primary insurance UnitedHealthcare (UHC)?";
            const secondaryUHC = "Is your secondary insurance UnitedHealthcare (UHC)?";

            return this.primaryInsurance ? primaryUHC : secondaryUHC;
        },
        showMedicareQuestion() {
            const isNotPrimary = !this.primaryInsurance;
            const medicareNotSelected = !this.$store.getters.getMedicareSelected;

            return this.primaryInsurance || (isNotPrimary && medicareNotSelected);
        }
    },
    mounted() {
        // if PIG and patient go back to change insurance entry type, then we need to clear out data
        if (this.$store.getters.getIsPatientInformationGathering) {
            if (!this.$props.primaryInsurance) {
                if (this.$store.getters.isManualInsurance) {
                    this.frontInsuranceCard = null;
                    this.backInsuranceCard = null;
                } else {
                    this.companyName = "";
                    this.idNumber = "";
                    this.groupNumber = "";
                    this.phoneNumber = "";
                }
            } else {
                if (this.$store.getters.isManualInsurance) {
                    this.$data.insuranceCardManualShown = true;
                    this.$data.showManualInsurance = true;    
                } else {
                    this.$data.insuranceCardManualShown = false;
                    this.$data.showManualInsurance = false;
                }

                // set medicare selection based on dob if no value is selected yet
                if (this.isThisMedicare === "") {
                    const dateOfBirth = moment(this.$store.getters.pigDob, ["MM-DD-YYYY", "M-DD-YYYY", "MM-D-YYYY", "M-D-YYYY"]);
                    const years = moment().diff(dateOfBirth, "years", true);
                    if(years >= 65) {
                        this.isThisMedicare = "Yes";
                        this.medicareSelected();
                    }
                    else {
                        this.isThisMedicare = "No";
                        this.medicareUnselected();
                    }
                }
            }
        } else {
            if(this.companyName || this.idNumber || this.groupNumber || this.phoneNumber) {
                this.$data.insuranceCardManualShown = true;
                this.$data.showManualInsurance = true;
            }
        }
        if(this.isThisMedicare === "No") {
            this.shouldDisplayMedicareCoverageWarning();
        }

        this.checkValidationSections();
    },
    methods: {
        toggleMobileManualCardForm() {
            this.$data.insuranceCardManualShown = !this.$data.insuranceCardManualShown;
            this.$refs.mobileInsuranceLink.classList.toggle("insurance-mobile-manual--shown");
        },
        updateDateOfBirth(event) {
            clearTimeout(this.longDobTimeout);

            this.longDobTimeout = window.setTimeout(()=> {
                this.updateDateOfBirthNow(event);
            }, 1000);  
        },
        updateDateOfBirthNow(event) {
            this.$store.dispatch(this.$data.storeSetterPrefix + "PolicyholderDateOfBirth", event.target.value);
            const dobValue = this.$store.getters[this.$data.storeGetterPrefix + "PolicyholderDateOfBirth"];
            this.longDob = moment(dobValue, ["MM-DD-YYYY", "M-DD-YYYY", "MM-D-YYYY", "M-D-YYYY"]).format("MMMM Do YYYY");

            clearTimeout(this.longDobTimeout);
        },
        dialogClose(){
            this.$data.dialogOpen = false;
        },
        uhcDialogClose() {
            this.$data.uhcDialogOpen = false;
        },
        shouldDisplayMedicareCoverageWarning() {
            const dateOfBirth = moment(this.$store.getters.patientDob, ["MM-DD-YYYY", "M-DD-YYYY", "MM-D-YYYY", "M-D-YYYY"]);
            const years = moment().diff(dateOfBirth, "years", true);
            if(years >= 65) {
                this.$data.showMedicareWarning = true;
            }
            else {
                this.$data.showMedicareWarning= false;
            }
        },
        handlePhoneChange() {
            const strippedValue = this.phoneNumber.replace(/\D/g,"");
            if(strippedValue <= 0) {
                this.phoneNumber = "";
            }
        },
        checkValidationSections() {
            const frontCard = Common.doesExistAndNotEmpty(this.frontInsuranceCard);
            const backCard = Common.doesExistAndNotEmpty(this.backInsuranceCard);
            const anyCardSupplied = frontCard || backCard;
            const allCardsSupplied = frontCard && backCard;

            const companySupplied = Common.doesExistAndNotEmpty(this.companyName);
            const idSupplied = Common.doesExistAndNotEmpty(this.idNumber);
            const groupNumberSupplied = Common.doesExistAndNotEmpty(this.groupNumber);
            const phoneNumberSupplied = Common.doesExistAndNotEmpty(this.phoneNumber);
            const anyInputsSupplied = companySupplied || idSupplied || groupNumberSupplied || phoneNumberSupplied;
            const allInputsSupplied = companySupplied && idSupplied && groupNumberSupplied && phoneNumberSupplied;

            if((anyCardSupplied && !anyInputsSupplied) || allCardsSupplied) {
                this.$data.insuranceCardsSupplied = true;
                this.$data.manualInsuranceSupplied = false;
            }
            else if((anyInputsSupplied && !anyCardSupplied) || allInputsSupplied) {
                this.manualInsuranceSupplied = true;
                this.$data.insuranceCardsSupplied = false;
            }
            else {
                this.$data.insuranceCardsSupplied = false;
                this.manualInsuranceSupplied = false;
            }
        },
        medicareSelected() {
            this.showMedicareWarning = false;
            this.$store.dispatch(this.$data.storeSetterPrefix + "PolicyholderRelationship", "Me");
            this.forcePolicyholderMeOnly = true;

            this.$nextTick(() => {
                const clickEvent = Common.createNewEvent("click");
                this.$refs.policyholderMe.$refs.radioInput.dispatchEvent(clickEvent);
            });
            
            if(this.primaryInsurance) {
                this.$store.dispatch("setMedicareSelected", true);
                this.$store.dispatch(this.$data.storeSetterPrefix + "UHC", "");
            }
        },
        medicareUnselected() {
            this.shouldDisplayMedicareCoverageWarning();
            this.$store.dispatch(this.$data.storeSetterPrefix + "PolicyholderRelationship", "");
            this.forcePolicyholderMeOnly = false;

            this.$nextTick(() => {
                const clickEvent = Common.createNewEvent("click");
                this.$refs.policyholderMe.$refs.radioInput.dispatchEvent(clickEvent);
            });

            if(this.primaryInsurance) {
                this.$store.dispatch("setMedicareSelected", false);
            }
        },
        setBothInsuranceImagesAdded() {
            if(this.$props.primaryInsurance) {
                const backOfCardExists = Common.doesExistAndNotEmpty(this.$store.getters.primaryBackInsuranceCard);
                const frontOfCardExists = Common.doesExistAndNotEmpty(this.$store.getters.primaryFrontInsuranceCard);   

                if(backOfCardExists && frontOfCardExists) {
                    this.$store.dispatch("setPrimaryInsuranceCardsAdded", true);
                }
                else {
                    this.$store.dispatch("setPrimaryInsuranceCardsAdded", false);
                }
            }
            else {
                const backOfCardExists = Common.doesExistAndNotEmpty(this.$store.getters.secondaryBackInsuranceCard);
                const frontOfCardExists = Common.doesExistAndNotEmpty(this.$store.getters.secondaryFrontInsuranceCard);

                if(backOfCardExists && frontOfCardExists) {
                    this.$store.dispatch("setSecondaryInsuranceCardsAdded", true);
                }
                else {
                    this.$store.dispatch("setSecondaryInsuranceCardsAdded", false);
                }
            }
        },
        handleManualInsurnaceClick(value) {
            this.$data.showManualInsurance = value;
            this.companyName = "";
            this.idNumber = "";
            this.groupNumber = "";
            this.phoneNumber = "";
            this.frontInsuranceCard = null;
            this.backInsuranceCard = null;
            if (this.primaryInsurance && this.$store.getters.getIsPatientInformationGathering) {
                this.$store.dispatch("setIsManualInsurance", value);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .insurance-row-container {
        margin-bottom: 20px;
    }

    .insurance-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .insurance-row-cards {
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .insurance-label-wrapper {
        width: $insurance-label-width;
        margin-right: $insurance-label-mr;
        text-align: right;
        margin-top: 12px;
    }

    .insurance-label-wrapper .insurance-label {
        margin-right: 0;
    }

    .insurance-label {
        width: $insurance-label-width;
        margin-right: $insurance-label-mr;
        text-align: right;
    }

    .policyholder-btn {
        margin-right: 20px;
    }

    .insurance-card-upload {
        display: flex;
    }

    .policyholder-info {
        width: 100%;
        height: auto;
        opacity: 1;
    }

    ::v-deep .button-label {
        background-color: white;
    }

    .insurance-name-input {
        width: 200px;
        margin-right: 9px;
    }

    .insurance-dob-text {
        margin-left: 5px;
        color: $dark-gray-text-color;
        font-size: $regular-copy-size;
    }

    .insurance-card-input {
        width: $inusrance-card-input-max-width;
    }

    .insurance-card-input.insurance-card-input--sm {
        width: 205px;
    }

    .policyholder-header {
        margin-bottom: $form-row-btm-margin;
    }

    .insurance-mobile-manual {
        display: none;
    }

    .insurance-dob-warning {
        max-width: 500px;
        max-height: 0px;
        margin-top: 5px;
        margin-left: calc(#{$insurance-label-width} + 15px);
        padding: 0 20px 0 20px;
        background: $warning;
        opacity: 0;
        transition: 0.2s all ease-in-out;
        overflow: hidden;

        p {
            font-size: $regular-copy-size;
        }
    }

    .myriad-container-warning-notice .insurance-dob-warning {
        max-height: 90px;
        padding: 20px;
        opacity: 1;
    }

    .insurance-card {
        display: flex;
        flex-direction: row;
    }

    .insurance-card-spacer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 300px;
        margin-right: 48px;
        margin-left: 320px;
    }

    .insurance-card-line {
        flex-grow: 1;
        height: 1px;
        background: $gray-text-color;
        align-items: stretch;
    }

    .insurance-card-spacer-or {
        @include flex-center-vertical();
        width: 48px;
        height: 48px;
        border-radius: 48px;
        border: 1px solid $gray-text-color;
        background: white;
        font-size: 1em;
        font-weight: 900;
    }

    .insurance-form-header {
        margin-top: 27px;
        margin-bottom: 21px;
    }

    .insurance-card-image-container {
        margin-right: 10px;
    }

    .insurance-card-validation-message {
        height: 0;
        opacity: 0;
        overflow: hidden;
        margin-top: 0;
        color: $error-red;
        text-align: center;
        font-size: $regular-copy-size;
        transition: 0.1s all ease-in-out;
    }

    .insurance-card-notice {
        margin-top: 2px;
        font-family: Lato,sans-serif;
        font-size: 1em;
        font-weight: 300;
        line-height: 1.1em;
        letter-spacing: 0em;
        text-align: right;
        color: #757575;
    }

    ::v-deep .medicare-dialog .dialog {
        width: 800px;
        height: 510px;
        margin-left: -400px;
        margin-top: -255px;
        padding-left: 30px;
        padding-right: 30px;
        background-position: left -190px top -130px;
    }

    ::v-deep .medicare-dialog.medicare-dialog--uhc .dialog {
        height: 370px;
    }

    ::v-deep .medicare-dialog .dialog-title-bar-title {
        font-weight: 900;
    }

    ::v-deep .medicare-dialog .medicare-dialog-paragraph {
        margin-bottom: 20px;
        font-family: Raleway;
        font-size: 17px;
        font-weight: 500;
        line-height: 19.96px;
        text-align: left;
    }

    ::v-deep .medicare-dialog .medicare-dialog-paragraph.medicare-dialog-paragraph--no-bottom-margin {
        margin-bottom: 0;
    }

    ::v-deep .medicare-dialog .medicare-dialog-list {
        list-style-type: disc;
        padding-left: 17px;
        margin: 5px 0;
        font-family: Raleway;
        font-size: 17px;
        font-weight: 500;
        line-height: 19.96px;
        text-align: left;

    }

    .medicare-dialog-copy {
        margin-top: 10px;
        margin-bottom: 60px;
    }

    .medicare-example-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .medicare-example-title {
        margin-bottom: 15px;
        font-size: 1.2em;
    }

    .medicare-image-container {
        width: 325px;
    }

    .medicare-example-image {
        width: 100%;
        height: 210px;
    }

    .medicare-age-warning {
        max-width: 485px;
        max-height: 0;
        margin-top: 0;
        margin-left: calc(#{$insurance-label-width} + #{$insurance-label-mr});
        padding: 0 18px 0 18px;
        background: $warning-bg;
        box-sizing: border-box;
        overflow: hidden;
        opacity: 0;
        transition: 0.15s all ease-in-out;
    }

    .medicare-age-warning--visible {
        margin-top: 10px;
        padding-top: 12px;
        padding-bottom: 12px;
        max-height: 110px;
        opacity: 1;
    }

    .medicare-warning-title {
        margin-bottom: 7px;
    }

    .medicare-warning-copy {
        line-height: $no-insurance-header-font-size;
    }

    .medicare-warning {
        color: $genesight-orange;
    }

    .grow-enter-active {
        transition: all 0.2s ease-out;
    }

    .grow-leave-active {
        transition: all 0.2s ease-in;
    }

    .grow-enter,
    .grow-leave-to {
        max-height: 0;
        opacity: 0;
    }

    .sex-button-wrapper {
        display: flex;
        justify-content: flex-start;
    }

    .desktop-tooltip {
        max-width: 300px;
    }

    .mobile-tooltip {
        display: none;
    }

    .desktop-enter-choice {
        color: #00a8e9;
        font-size: 1em;
        font-family: Lato,sans-serif;
        margin-left: 320px;
        cursor: pointer;
        margin-top: 20px;
    }

    @media (max-width: 820px){
        ::v-deep .medicare-dialog.medicare-dialog--uhc .dialog {
            width: 650px;
            height: auto;
            background-position: left -110px top -70px;
            margin-left: 0;
            margin-top: 0;
            transform: translate(-50%, -50%);
            max-width: 90%;
        }

        ::v-deep .medicare-dialog.medicare-dialog--uhc .dialog .dialog-title-bar {
            margin-bottom: 10px;
        }
    }

    @include small-desktop {
        .insurance-card-manual,
        .insurance-card-upload {
            flex-grow: 1;
        }
    }

    @include tablet {
        .insurance-row {
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
        }
        .insurance-dob-warning {
            margin-left: 0;
        }

        .check-policyholder-header {
            min-width: 100%;
            margin-bottom: 20px;
            text-align: center;
        }

        .policyholder-btn {
            margin-right: 10px;
            margin-bottom: 10px;
        }

        ::v-deep .button-radio.policyholder-btn .button-label {
            min-width: 135px;
        }

        .insurance-label-wrapper {
            text-align: left;
            margin-bottom: 20px;
        }

        .insurance-label {
            width: 100%;
            margin-right: 0;
            margin-bottom: $form-row-btm-margin-mobile;
            text-align: left;
        }

        .insurance-error-label {
            padding-left: 0;
        }

        .insurance-name-input {
            width: 100%;
            margin-right: 0;
            margin-bottom: $form-row-btm-margin-mobile;
        }

        .insurance-card-input {
            width: 100%;
        }

        .insurance-card-input--sm.insurance-card-input {
            width: 100%;
        }

        .insurance-row-cards {
            flex-direction: column;
            align-items: flex-start;
        }

        .policyholder-info {
            height: auto;
        }

        .insurance-card {
            flex-direction: column;
        }

        .insurance-card-upload {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .insurance-mobile-manual {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            font-size: $small-copy-size;
            text-align: center;

            &:hover {
                cursor: pointer;
            }
        }

        .insurance-mobile-manual-img {
            height: 8px;
            margin-left: 5px;
            transform: rotate(180deg);
            transition: 0.2s all ease;
        }

        .insurance-mobile-manual--shown .insurance-mobile-manual-img {
            transform: rotate(0deg);
        }

        .insurance-card-spacer {
            margin-top: 10px;
            margin-bottom: 20px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-left: 0;
            width: 300px;
        }

        .desktop-enter-choice {
            margin-left: 0;
        }

        .insurance-card-line {
            flex-grow: 1;
            width: auto;
            height: 1px;
        }

        .insurance-card-notice {
            display: none;
        }

        .insurance-front-card,
        .insurance-back-card {
            width: 100%;
            max-width: 300px;
        }

        .insurance-front-card {
            margin-bottom: 20px;
        }

        .insurance-card-manual {
            max-height: 0;
            opacity: 0;
            transition: 0.1s all ease;
            overflow: hidden;
        }

        .insurance-card-manual--shown {
            max-height: 600px;
            opacity: 1;
        }

        .insurance-card-mobile-order-1 {
            order: 3;
        }

        .insurance-card-mobile-order-2 {
            order: 2;
        }

        .insurance-card-mobile-order-3 {
            order: 1;
        }

        .insurance-error-label-mobile {
            max-width: $inusrance-card-input-max-width;
            text-align: left;
        }

        .medicare-example-row {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .insurance-medicare-examples, .insurance-uhc-examples {
            width: 100%;
        }

        .medicare-dialog-copy {
            margin-bottom: 20px;
        }

        ::v-deep .medicare-dialog .dialog {
            width: 466px;
            height: 750px;
            margin-left: -233px;
            margin-top: -361px;
            background-position: left -110px top -70px;
        }

        .medicare-example-title {
            margin-top: 15px;
            margin-bottom: 5px;
        }

        .medicare-age-warning {
            margin-left: 0;
        }

        .insurance-sex-row {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            .insurance-label {
                width: 30px;
                min-width: 30px;
                margin-right: 10px;
                margin-bottom: 15px;
            }
        }
        .desktop-tooltip {
            display: none;
        }
        .mobile-tooltip {
            display: block;
            width: 20px;
            margin-right: calc(100% - 75px);
        }
    }

    @include sm {
        .insurance-card-upload {
            align-items: center;
        }

        .policyholder-text-row {
            width: 100%;
        }

        .medicare-image-container {
            width: 80%;
        }

        .medicare-example-image {
            width: 100%;
            height: auto;
        }

        .insurance-card-spacer {
            margin-left: auto;
            margin-right: auto;
            width: 250px;
        }

        .insurance-card-image-container {
            margin-right: 0;
        }

        .insurance-card-mobile-order-3 {
            margin-left: auto;
            margin-right: auto;
        }

        .desktop-enter-choice {
            text-align: center;
        }

        ::v-deep .medicare-dialog .dialog {
            width: 100%;
            height: auto;
            max-height: 100%;
            top: 0;
            left: auto;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            padding-left: 10px;
            padding-right: 10px;
            overflow: auto;
            background-size: 40%;
            background-position: left -23vw top -8vh;
        }

        ::v-deep .medicare-dialog.medicare-dialog--uhc .dialog {
            width: 100%;
            height: auto;
            background-position: left -110px top -70px;
            margin-left: 0;
            margin-top: 0;
            transform: translate(-50%, -50%);
            max-width: 100%;
            left: 50%;
            top: 50%;
            overflow-y: auto;
            overflow-x: hidden;
            border-radius: 0;
        }

        ::v-deep .medicare-dialog.medicare-dialog--uhc .dialog .dialog-title-bar-space {
            display: none;
        }

        ::v-deep .medicare-dialog.medicare-dialog--uhc .dialog .dialog-title-bar-title {
            width: 100%;
            text-align: center;
        }

        ::v-deep .medicare-dialog.medicare-dialog--uhc .dialog .dialog-title-bar-x {
            position: absolute;
            top: -5px;
            right: -15px;
        }
    }
</style>


