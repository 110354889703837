<template>
    <div class="medical-page">
        <form-validate ref="medicalForm"
                       class-name="medical-form"
                       id-name="medicalForm"
                       @no-errors-callback="removeErrorsBanner"
                       @invalid-callback="showFormErrors"
                       @valid-callback="goToReviewPage"
        >
            <div class="medical-questions">
                <h2 :class="pastMedsValid ? 'medication-question' : 'medication-question past-meds-validation-label'">
                    List the psychiatric medications that did not work for you (<b>currently or previously</b> prescribed).
                </h2>
                <div class="medical-med-row">
                    <myriad-multiselect 
                        :items="medicineOptions" 
                        :disabled="pastMedsDisabled"
                        :preselected-items="$store.getters.previousMedicationsForPost"
                        :enable-validation="true"
                        class="medication-select" 
                        items-name="medications"
                        placeholder="Type or select a medication"
                        @selected-values-change="savePreviousMedicines"
                    />
                    <input v-if="!pastMedsCheckDisabled" id="previousMedicationsPrescribed" v-model="noPreviousMedsChecked" type="checkbox">
                    <label v-if="!pastMedsCheckDisabled" for="previousMedicationsPrescribed" class="medication-check-label">
                        None have failed to work for me
                    </label>
                </div>
            </div>
            <h2 ref="stickyHeader" class="medical-sub-title">
                Over the last 2 weeks, how often have you been bothered by any of the following problems?
            </h2>
            <div class="medical-question-container">
                <div class="medical-desktop-questions">
                    <question-row
                        v-for="(item, index) in this.$store.getters.phq9Questions"
                        :key="index"
                        :question="item.question"
                        :question-index="index"
                        :active="item.answer"
                        @phq9Answered="updatePhq9Answers"
                    />
                </div>
                <div class="medical-mobile-questions">
                    <question-row-mobile
                        v-for="(item, index) in this.$store.getters.phq9Questions"
                        :key="index"
                        :question="item.question"
                        :question-index="index"
                        :active="item.answer"
                        @phq9Answered="updatePhq9Answers"
                    />
                </div>
                <div v-if="showSuicideWarning" class="suicide-container">
                    <div class="suicide-image-container">
                        <hand-shake class="icon-svg" />
                        <p class="mobile-top-message">
                            <b>You are not alone.</b> Confidential help is available for <b>free</b>.
                        </p>
                    </div>
                    <div class="suicide-text-container">
                        <p><b>You are not alone.</b> Confidential help is available for <b>free</b>.</p>
                        <p>
                            If you're having actionable thoughts of harming yourself, please contact your healthcare provider or call the National Suicide Prevention Lifeline at <a href="tel:+1-800-273-8255">
                                1.800.273.8255
                            </a>.
                        </p>
                        <p>We do not immediately notify your healthcare provider regarding your response.</p>
                    </div>
                </div>
            </div>
            <transition name="recurrent">
                <div v-if="showFrequencyQuestion" v-radio-button-validation="{required: true, name: 'depression-frequency'}" class="medication-frequency myriad-validation-container">
                    <h2 class="medication-question myriad-validation-label">
                        Which description below describes the frequency of your depressive episodes?
                    </h2>
                    <div class="med-question-row">
                        <div class="med-frequency-content radio-btn--first">
                            <large-radio-input
                                id="recurrent-frequency" 
                                v-model="frequency"
                                class="med-frequency-btn"
                                :v-model-property="frequency" 
                                name="depression-frequency"
                                label="Recurrent" 
                                tabindex="0"
                                :text-content="recurrentTextContent"
                            />
                        </div>
                        <div class="med-frequency-content">
                            <large-radio-input
                                id="single-frequency" 
                                v-model="frequency"
                                class="med-frequency-btn"
                                :v-model-property="frequency" 
                                name="depression-frequency"
                                label="Single" 
                                tabindex="0"
                                :text-content="singleTextContent"
                            />
                        </div>
                    </div>
                    <div class="myriad-required-notice medical-error-message">
                        This information is required to continue
                    </div>
                </div>
            </transition>
            <div v-radio-button-validation="{required: true, name: 'anxiety'}" class="myriad-validation-container">
                <h2 class="medication-question myriad-validation-label">
                    Has a doctor ever treated you for anxiety?
                </h2>
                <div class="med-question-row med-question-row--rowOnly">
                    <div class="">
                        <rad-button
                            id="anxiety-yes" 
                            v-model="hasAnxiety"
                            class="radio-btn--first"
                            :v-model-property="hasAnxiety" 
                            name="anxiety"
                            label="Yes" 
                            tabindex="0"
                        />
                    </div>
                    <div class="">
                        <rad-button
                            id="anxiety-no" 
                            v-model="hasAnxiety"
                            class=""
                            :v-model-property="hasAnxiety" 
                            name="anxiety"
                            label="No" 
                            tabindex="0"
                        />
                    </div>
                </div>
                <div class="myriad-required-notice medical-error-message">
                    This information is required to continue
                </div>
            </div>
        </form-validate>
        <btn-row @continueClick="submitForm" @backClick="goToSecondaryInsurance" />
    </div>
</template>

<script>
import QuestionRow from "../components/layout/Medical/QuestionRow.vue";
import QuestionRowMobile from "../components/layout/Medical/QuestionRowMobile.vue";
import BtnRow from "../components/layout/BtnRow.vue";
import Common from "../scripts/common.js";
import LargeRadioInput from "../components/ui/LargeRadioInput.vue";
import RadButton from "../components/ui/ButtonRadioInput.vue";
import HandShake from "../assets/handshake.svg";
import BasePage from "./BasePage.vue";

export default {
    name: "Medical",
    components: {
        QuestionRow,
        QuestionRowMobile,
        BtnRow,
        LargeRadioInput,
        RadButton,
        HandShake
    },
    extends: BasePage,
    data() {
        return {
            previousMedicineValue: null,
            pastMedsPanEnabled: true,
            pastMedsDisabled: false,
            pastMedsCheckDisabled: false,
            medicineOptions: this.$store.getters.medicationList,
            pastMedsValid: false,
            recurrentTextContent: "There has been at least one previous depressive episode lasting a minimum of two weeks and separated by the current depressive episode of at least two months.",
            singleTextContent: "Single episode depression means that a person experiences finite depression, according to the criteria for diagnosis, but does not suffer from it again."
        };
    },
    computed: {
        frequency: {
            get() {
                return this.$store.getters.frequencyOfEpisodes;
            },
            set(value) {
                this.$store.dispatch("setFrequencyOfEpisodes", value);
            }
        },
        hasAnxiety: {
            get() {
                return this.$store.getters.hasAnxiety;
            },
            set(value) {
                this.$store.dispatch("setHasAnxiety", value);
            }
        },
        showFrequencyQuestion() {
            return Common.shouldShowDepressiveFrequency();
        },
        showSuicideWarning() {
            return this.$store.getters.phq9Questions[8].answer > 0;
        },
        noPreviousMedsChecked: {
            get() {
                return this.$store.getters.getPreviousMedsCheck;
            },
            set(value) {
                this.$store.dispatch("setPreviousMedsCheck", value);
            }
        }
    },
    watch: {
        noPreviousMedsChecked(newValue) {
            this.pastMedsDisabled = newValue;
            this.pastMedsValid = newValue;
            this.$store.dispatch("setPreviousMedications", []);
        },
        showFrequencyQuestion(newValue, oldValue) {
            if(!newValue) {
                this.$store.dispatch("setFrequencyOfEpisodes", "Single");
            }
            else if(newValue && !oldValue) {
                this.$store.dispatch("setFrequencyOfEpisodes", "");
            }
        }
    },
    mounted() {
        this.startStickyQuestionAtTopDetection();

        if(this.noPreviousMedsChecked) {
            this.pastMedsDisabled = true;
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleOnScroll);
    },
    methods: {
        goToReviewPage() {
            this.$store.dispatch("setMedicalStepComplete", true);
            this.$router.push("review");
        },
        goToSecondaryInsurance() {
            this.$router.push("/insurance/secondary");
        },
        updatePhq9Answers(event) {
            this.checkAllAnswered();
            this.$store.dispatch("setPhq9Answer", {
                index: event.questionIndex, 
                value: event.value
            });
        },
        checkAllAnswered() {
            this.$nextTick(() => {
                const anyErrors = this.$refs.medicalForm.checkForErrors();
                if(!anyErrors) {
                    this.removeErrorsBanner();
                }
            });
        },
        startStickyQuestionAtTopDetection() {
            window.addEventListener("scroll", this.handleOnScroll);
        },
        savePreviousMedicines(items) {
            this.checkAllAnswered();

            this.$store.dispatch("setPreviousMedications", items);

            if(items.length > 0 && !this.pastMedsDisabled) {
                this.pastMedsValid = true;
                this.pastMedsCheckDisabled = true;
            }
            else {
                this.pastMedsCheckDisabled = false;
            }
        },
        submitForm() {
            this.$nextTick(()=> {
                const submitEvent = Common.createNewEvent("submit");
                this.$refs.medicalForm.$refs.validateForm.dispatchEvent(submitEvent);
            });  
        },
        showFormErrors() {
            this.$store.dispatch("setShowErrorMessage", true).then(()=> {
                this.$nextTick(()=> {
                    Common.scrollToErrorMessage();
                });
            });
        },
        removeErrorsBanner() {
            this.$store.dispatch("setShowErrorMessage", false);
        },
        handleOnScroll() {
            if(this.$refs.stickyHeader && this.$refs.stickyHeader.getBoundingClientRect().top <= 0.4) {
                this.$refs.stickyHeader.classList.add("medical-sub-title--stuck");
            }
            else {
                this.$refs.stickyHeader.classList.remove("medical-sub-title--stuck");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.medical-page {
    @include site-section();
    margin-bottom: 65px;
}

.medical-form{
    margin-bottom: 100px;
}

.medical-sub-title {
    position: sticky;
    top: 0;
    margin-top: 45px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $medium-gray-text-color;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    font-weight: 700;
    z-index: 10;
    transform: translate3d(0,0,0);
}

.medical-sub-title--stuck {
    background: $background-color;
}

.medical-question-container {
    margin-bottom: 45px;
}

.medical-mobile-questions {
    display: none;
}

.medication-question {  
    margin-top: 45px;
    margin-bottom: 10px;
    color: $medium-gray-text-color;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    font-weight: 700;
}

.medical-med-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.medication-select {
    min-width: 520px;
}

.medication-check-label {
    color: $medium-gray-text-color;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    font-weight: 700;
}

.medical-submit-row {
    @include flex-center-horizontal();
}

.medication-frequency {
    max-height: 400px;
    max-width: 750px;
}

.med-question-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
}

.med-frequency-content {
    width: 50%;
}

.radio-btn--first {
    margin-right: 20px;
}

.med-frequency-text {
    width: 90%;
    height: 80px;
    font-size: $regular-copy-size;
    line-height: 1.1em;
}

.medical-error-message {
    margin-top: 5px;
    max-height: 0;
    font-size: $regular-copy-size;
    color: $error-red;
    overflow: hidden;
}

.medical-questions b {
    color: $genesight-orange;
    font-style: italic;
}

.suicide-container{
    background-color: $step-instructions-bg;
    display: flex;
    flex-direction: row;
    line-height: 1.5em;

    .suicide-image-container{
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        .icon-svg {
            height: 60px;
            margin: 10px;
        }
        .mobile-top-message {
            display: none;
        }
    }

    .suicide-text-container{
        display: flex;
        flex-direction: column;
        margin: 10px;
        max-width: 700px;
        p:last-of-type {
            font-size: 0.8em;
        }
    }
}

@include small-desktop {
    .medical-page {
        @include site-section-sm-desktop();
    }

    .question-row {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .question-text {
        width: 100%;
        text-align: center;
    }
}

@include tablet {
    .medical-page {
        width: 100%;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
    }

    .medical-desktop-questions {
        display: none;
    }

    .medical-mobile-questions {
        display: block;
        padding-top: 20px;
    }

    .suicide-container {
        border: none;
        flex-direction: column;
        .suicide-image-container{
            .mobile-top-message {
                display: block;
                margin: 10px 0;
            }
        }
        .suicide-text-container{
            margin-top: 0;
            p:first-of-type{
                display: none;
            }
        }
    }

    .medical-sub-title {
        font-size: $btn-font-size;
    }

    .medical-sub-title--stuck {
        margin-top: 45px;
        padding: 20px $mobile-padding;
        width: calc(100% + #{$double-mobile-padding});
        margin-left: -$mobile-padding;
    }

    .medical-med-row {
        flex-wrap: wrap;
    }

    .medication-select {
        min-width: 100%;
        margin-bottom: 10px;
    }
    
    .med-question-row {
        flex-direction: column;
    }

    .med-question-row--rowOnly {
        flex-direction: row;
    }

    .med-frequency-content {
        width: 100%;
    }

    .med-frequency-text {
        height: auto;
    }

    .med-frequency-text--mt {
        margin-top: 10px;
    }

    .med-frequency-btn {
        width: 100%;
        margin-top: 5px;
    }
}

@include sm {
    .medical-sub-title {
        background: $background-color;
    }
}

.recurrent-enter-active {
    transition: all .3s;
}

.recurrent-leave-active {
    transition: all .3s;
}

.recurrent-enter,
.recurrent-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>
