<template>
    <div>
        <div v-show="!imageAvailable">
            <label ref="insuranceFileUpload" :for="inputId" class="insurance-file-upload">
                <img class="insurance-file-upload-img" src="../../../assets/insurance_upload_icon.png">
                <p class="insurance-upload-text">
                    Insurance Card <b>{{ frontOfCard ? 'front' : 'back' }}</b>
                </p>
                <img class="insurance-file-upload-img--mobile" src="../../../assets/camera_upload.png">
                <p class="insurance-upload-text--mobile">
                    Take a photo of the 
                    <b>{{ frontOfCard ? 'front' : 'back' }}</b> of your insurance card.
                </p>
            </label>
            <input
                :id="inputId"
                ref="cardInput"
                v-file-validation="{required: true}"
                :data-has-value="value"
                class="insurance-card-upload-input"
                data-preview-element="insurance-card-front-preview"
                type="file"
                accept="image/gif, image/jpeg, image/jpg, image/png"
                @change="previewInsuranceCard"
            >
        </div>
        <div v-show="imageAvailable" ref="cardPreviewContainer" class="insurance-card-preview">
            <delete-btn @deleteClick="removeCardFromInput" />
            <img ref="cardPreview" :src="imageThumbnail" class="insurance-card-preview-img">
        </div>
        <div v-if="uploadTypeError" class="insurance-card-preview-error">
            The image is not among the supported file types of .gif, .jpeg, .jpg, and .png.
        </div>
        <div v-if="uploadSizeError" class="insurance-card-preview-error">
            The image exceeded the maximum file size of 20MB.
        </div>
    </div>
</template>

<script>
import Common from "../../../scripts/common.js";
import DeleteBtn from "../../ui/DeleteButton.vue";

export default {
    name: "InsuranceCardPreview",
    components: {
        DeleteBtn
    },
    props: {
        inputId: {
            default: "",
            type: String,
            required: true
        },
        frontOfCard: {
            default: true,
            type: Boolean
        },
        value: {
            default: null,
            type: Object
        }
    },
    data: () => {
        return {
            imageThumbnail: "",
            imageAvailable: false,
            uploadSizeError: false,
            uploadTypeError: false,
            validExtensions: ["gif", "jpg", "jpeg", "png"],
            maxFileSize: 20971520
        };
    },
    watch: {
        value(newValue) {
            if(newValue) {
                this.$data.imageAvailable = true;
            }
            else {
                this.$data.imageAvailable = false;
            }
        }
    },
    mounted() {
        if(this.$props.value != null) {
            this.$data.imageThumbnail = this.$props.value.File;
            this.$refs.cardInput.classList.remove("myriad-required-error");
            this.$refs.cardInput.closest(".myriad-validation-container").classList.remove("myriad-container-required");
            this.$data.imageAvailable = true;
        }
    },
    methods: {
        validFileExtensionAndSize() {
            const validExtensions = this.$data.validExtensions;
            const maxFileSize = this.$data.maxFileSize;
            const fileElement = this.$refs.cardInput;
            let fileExtension = "";
            let fileSize = 20480;
            if (fileElement.value.lastIndexOf(".") > 0) {
                fileExtension = fileElement.value.substring(fileElement.value.lastIndexOf(".") + 1, fileElement.value.length);
            }

            if(fileElement.files && fileElement.files[0]) {
                fileSize = fileElement.files[0].size;
            }

            if(fileElement.files.length <= 0) {
                this.$data.uploadSizeError = false;
                this.$data.uploadTypeError = false;
                return false;
            }
            else if (!validExtensions.includes(fileExtension.toLowerCase())) {
                this.$data.uploadTypeError = true;
                this.$data.uploadSizeError = false;
                return false;
            }
            else if(fileSize > maxFileSize) {
                this.$data.uploadSizeError = true;
                this.$data.uploadTypeError = false;
                return false;
            }
            else {
                this.$data.uploadSizeError = false;
                this.$data.uploadTypeError = false;
                return true;
            }
        },
        previewInsuranceCard(event) {
            var self = this;
            if(this.validFileExtensionAndSize()) {
                let files = event.target.files;

                const imageObj = {
                    ContentType: files[0].type,
                    Length: files[0].size,
                    FileName: files[0].name
                };

                let reader = new FileReader();
                reader.onload = (e) => {
                    self.$data.imageThumbnail = e.target.result;
                    imageObj.File = e.target.result;
                    self.$emit("input",  imageObj);
                };
                reader.readAsDataURL(files[0]);
            }
        },
        getFiles(input, callback) {
            if (input.files && input.files[0]) {
                callback.bind(this);
                Common.getImageFromFile(input.files[0], callback);
            }
        },
        removeCardFromInput() {
            this.$refs.cardInput.value = null;
            this.$refs.cardPreview.setAttribute("src", "");
            this.$data.imageThumbnail = "";
            this.$emit("input", null);

            const changeEvent = Common.createNewEvent("change");
            this.$refs.cardInput.dispatchEvent(changeEvent);
        }
    }
};
</script>

<style lang="scss" scoped>
    .insurance-file-upload {
        @include flex-center-horizontal();
        max-width: $insurance-card-max-width;
        justify-content: center;
        align-items: center;
        position: relative;
        border: 2px solid $genesight-blue;
        border-radius: 25px;
        padding: 9px 24px;
        background-color: white;

        &:hover {
            cursor: pointer;
        }
    }

    .insurance-file-upload-img {
        position: relative;
        pointer-events: none;
        width: 19px;
        margin-right: 10px;
    }

    .insurance-file-upload-img--mobile {
        position: relative;
        pointer-events: none;
        display: none;
    }

    .insurance-upload-text {
        max-width: 180px;
        text-align: center;
        font-weight: 500;
        color: $genesight-blue;
    }

    .insurance-upload-text--mobile {
        max-width: 180px;
        text-align: center;
        font-weight: 500;
        color: $genesight-blue;
        display: none;
    }



    .insurance-card-upload-input {
        display: none;
    }

    .insurance-card-preview {
        @include flex-center-vertical();
        justify-content: flex-start;
        position: relative;
        margin-right: 25px;
    }

     .insurance-card-preview-img {
         max-width: $insurance-card-max-width;
         max-height: 400px;
     }

     .insurance-card-preview-error {
         max-width: $insurance-card-max-width;
         margin-top: 5px;
         font-size: $regular-copy-size;
         color: $error-red;
     }

     @include tablet {
         .insurance-file-upload {
             padding-left: 5px;
             padding-right: 5px;
         }

        .insurance-file-upload-img {
            display: none;
        }

        .insurance-file-upload-img--mobile {
            display: inline-block;
        }

        .insurance-upload-text {
            display: none;
        }

        .insurance-upload-text--mobile {
            max-width: 180px;
            text-align: center;
            font-weight: 500;
            color: $genesight-blue;
            display: inline-block;
            font-size: $regular-copy-size;
        }
     }
</style>

