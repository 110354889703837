<template>
    <div class="hero" :style="{ 'background-image': `url(${getHeroBackgroundImage()})` }">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h1 class="hero-title hero-header" v-html="this.$sanitize(heroTextContent)" />
    </div>
</template>

<script>
export default {
    name: "Hero",
    props: {
        backgroundImage: {
            default: "",
            type: String
        },
        heroTextContent: {
            default: "",
            type: String
        }
    },
    methods: {
        getHeroBackgroundImage() {
            return require("../../../assets/" + this.backgroundImage);
        }
    }
};
</script>

<style lang="scss" scoped>
.hero {
    display: block;
    width: 100%;
    height: 395px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-header {
    position: absolute;
    left: $left-margin;
    bottom: 75px;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 3.125em;
    color: $white;

    sup {
        font-size: 10px;
    }
}

@include tablet {
    .hero {
        @include flex-center-vertical();
    }

    .hero-header {
        position: static;
        margin: 0 auto;
        font-size: 2.5em;
        text-align: center;
        padding: $mobile-padding;
    }
}
</style>
