<template>
    <div class="view-report-language-page">
        <div class="view-report-form">
            <div v-if="$store.getters.hasPsych42OrLater" class="report-list">
                <ul>
                    <li>
                        <report-list-icon />
                        <div class="report-list__name">
                            GeneSight Psychotropic
                        </div>
                    </li>
                </ul>
                <div class="report-list__note">
                    <span>Note</span>: Psychotropic only available in high contrast color.
                </div>
            </div>
            <div v-if="$store.getters.reports.length > 0" class="report-list">
                <ul>
                    <li v-for="report in $store.getters.reports" :key="report.productSeriesName + report.majorVersion + report.minorVersion">
                        <report-list-icon />
                        <div class="report-list__name">
                            {{ report.productSeriesName }}
                        </div>
                    </li>
                </ul>
                <togggle-button
                    class="view-report-toggle-btn"
                    input-id="view-report-toggle"
                    :options="toggleOptions"
                    @toggleChange="toggleChange"
                />
            </div>
            <icon-btn 
                key="english"
                class="view-report-icon-btn view-report-icon-btn--english"
                btn-text="View In English" 
                :filled="true" 
                :multiple="hasMultipleReports"
                @buttonClick="downloadReport(false)"
            />
            <icon-btn 
                v-if="$store.getters.hasSpanishReports"
                key="espanol"
                class="view-report-icon-btn"
                btn-text="View In Español" 
                :filled="false" 
                :multiple="hasMultipleReports"
                @buttonClick="downloadReport(true)"
            />
            <p v-if="$store.getters.hasPartialSpanishReports">
                <b>Note:</b> Not all reports are available in Spanish.
            </p>
        </div>
        <div class="report-consultation">
            <h4 class="report-consultation-header">
                Free Report Consultation
            </h4>
            <p class="">
                Our Medical Information team is available to review your report with you.
            </p>
            <p class="">
                <a class="vp-link contact-link" href="mailto:medinfo@genesight.com">medinfo@genesight.com</a><span class="no-mobile">&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span><a class="vp-link contact-link" href="tel:855.891.9415">855.891.9415</a>
            </p>
            <hr class="report-consultation-hr">
            <p class="report-consultation-text">
                Do <b>not</b> make any medication changes without consulting your healthcare provider.
            </p>
        </div>
        <div class="helpful-resoureces">
            <h4 class="view-report-sub-header">
                Helpful Resources
            </h4>
            <a class="view-report-link vp-link" href="https://genesight.com/product/psychotropic-report-details/" target="_blank">GeneSight Psychotropic report details</a>
            <a class="view-report-link vp-link" href="https://genesight.com/genetic-insights/how-to-interpret-the-genesight-report/" target="_blank">How to interpret your GeneSight Psychotropic report</a>
            <a class="view-report-link vp-link" href="https://genesight.com/patient-stories/" target="_blank">GeneSight Patient Stories</a>
            <a class="view-report-link vp-link" href="https://genesight.com/patient-faq/" target="_blank">FAQ about the GeneSight test</a>
        </div>
    </div>
</template>

<script>
import TogggleButton from "../components/ui/ToggleButton.vue";
import IconBtn from "../components/ui/IconButton.vue";
import ViewReportHttp from "../scripts/http/view-report-http.js";
import Common from "../scripts/common.js";
import ReportListIcon from "../assets/report-list-icon.svg";

export default {
    name: "ViewReportLanguage",
    components: {
        TogggleButton,
        IconBtn,
        ReportListIcon
    },
    data() {
        const toggleOptions = ["Color", "Black and white"];

        return {
            toggleOptions: toggleOptions,
            currentToggleValue: toggleOptions[0]
        };
    },
    computed: {
        hasMultipleReports() {
            // if we have a psych 4.2+ report then add that to the report count.
            return (this.$store.getters.hasPsych42OrLater ? 1 : 0) + this.$store.getters.reports.length > 1;
        }
    },
    methods: {
        toggleChange(val) {
            this.currentToggleValue = val;
        },
        downloadReport(isSpanisReport) {
            var data = {};
            data.lastName = this.$store.getters.viewReportLastName;
            data.firstName = this.$store.getters.viewReportFirstName;
            data.dateOfBirth = this.$store.getters.viewReportDob;
            data.color = this.currentToggleValue === this.toggleOptions[0];
            data.spanish = isSpanisReport;

            this.$store.dispatch("setShowStartupLoader", true);

            ViewReportHttp.retrieveResults(data).then((response) => {
                if(response.data === "unauthorized") {
                    this.$router.push("/error");
                }
                else {
                    Common.downloadFileFromAxios(response.data, "GeneSightReport.pdf");
                }
            }).catch(()=> {
                this.$router.push("/error");
            }).finally(()=> {
                this.$store.dispatch("setShowStartupLoader", false);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    $reportConsultGray: #D7D7D7;

    .view-report-language-page {
        @include site-section();
        padding-bottom: 50px;
    }

    .view-report-form {
        margin-top: 40px;
    }

    .color-warning {
        margin-top: 7px;
    }

    .view-report-icon-btn--english {
        margin-top: 25px;
    }

    .view-report-icon-btn {
        margin-bottom: 27px;
    }

    .view-report-sub-header {
        margin: 120px 0 30px 0;
        font-weight: 700;
    }

    .view-report-link {
        display: block;
        margin-bottom: 15px;
    }

    .report-consultation {
        margin-top: 55px;
        padding: 40px 60px;
        border: 1px solid $reportConsultGray;
        border-radius: 5px;
        line-height: 25px;
    }

    .report-consultation-header {
        margin-bottom: 22px;
        font-weight: 700;
    }

    .report-consultation-hr {
        height: 1px;
        margin: 30px 0;
        color: $reportConsultGray;
        background: $reportConsultGray;
        border: none;
    }

    .report-consultation-text {
        color: #666666;
    }

    .helpful-resoureces {
        margin-bottom: 100px;
    }

    .vp-link:hover {
        text-decoration: underline;
    }

    .report-list {
        background: #D7F1C1;
        padding: 40px;
        margin-bottom: 20px;
    }

    .report-list ul {
        margin-bottom: 20px;
    }

    .report-list li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
    }

    .report-list li:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0;
    }

    .report-list li .report-list__name {
        font-family: Raleway,sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 10px;
    }

    .report-list .report-list__note {
        font-family: Raleway,sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
    }

    .report-list .report-list__note span {
        font-weight: 700;
    }

    @include small-desktop() {
        .view-report-language-page {
            @include site-section-sm-desktop();
        }
    }

    @include tablet() {
        .view-report-language-page {
            @include view-report-tablet-padding();
        }

        .report-consultation {
            padding: 20px;
        }

        .contact-link {
            display: block;
        }

        .no-mobile {
            display: none;
        }
    }
</style>
