<template>
    <transition name="fade">
        <div>
            <div v-if="this.$store.getters.showErrorMessage" class="step-error">
                <div class="step-error-logo">
                    !
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p class="step-error-text" v-html="errorMessageText" />
            </div>
            <div v-if="this.$store.getters.showPatientValidationError" class="step-error step-patient-validation-error">
                <div class="step-error-logo step-error-logo--dropped">
                    !
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p :class="hitMaxAttempts ? 'step-error-text' : 'step-error-text step-error-text-patient'" v-html="patientValidationErrorMessageText" />
                <div v-if="!hitMaxAttempts" class="step-error-patient-validation-tips">
                    <p>
                        Tips
                    </p>
                    <ul class="step-error-patient-validation-tips-list">
                        <li class="step-error-patient-validation-tips-list-item">
                            Make sure the patient name below matches the name on your insurance card.
                        </li>
                        <li class="step-error-patient-validation-tips-list-item">
                            Your healthcare provider can confirm the name and date of birth as they appear on your GeneSight order.
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="this.$store.getters.showPigValidationError" class="step-error step-patient-validation-error">
                <div class="step-error-logo step-error-logo--dropped">
                    !
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p :class="hitMaxAttempts ? 'step-error-text' : 'step-error-text step-error-text-patient'" v-html="pigValidationErrorMessageText" />
                <div v-if="!hitMaxAttempts" class="step-error-patient-validation-tips">
                    <p>
                        Tip
                    </p>
                    <ul class="step-error-patient-validation-tips-list">
                        <li class="step-error-patient-validation-tips-list-item">
                            Your healthcare provider or GeneSight Customer Service can confirm your date of birth as it appears on your GeneSight order.
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showRemainingAttempts" class="step-error step-error-attempts-warning">
                You have {{ remainingAttempts }} more attempts to verify your information.
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "StepError",
    data() {
        return {
            defaultErrorMessageText: "There were problems with information provided.  Please correct the highlighted fields below."
        };
    },
    computed: {
        errorMessageText() {
            if(this.$store.getters.getErrorMessageText !== "") {
                return this.$store.getters.getErrorMessageText;
            }
            else {
                return this.defaultErrorMessageText;
            }
        },
        patientValidationErrorMessageText() {
            if(this.$store.getters.patientValidationErrorMessageText !== "") {
                return this.$store.getters.patientValidationErrorMessageText;
            }
            else {
                return this.defaultErrorMessageText;
            }
        },
        pigValidationErrorMessageText() {
            if (this.$store.getters.pigValidationErrorMessageText !== "") {
                return this.$store.getters.pigValidationErrorMessageText;
            } else {
                return this.defaultErrorMessageText;
            }
        },
        remainingAttempts() {
            return this.$store.getters.maxPatientValidationAttempts - this.$store.getters.currentPatientValidationAttempts;
        },
        showRemainingAttempts() {
            const shouldShowPatientValidationmessage = this.$store.getters.showPatientValidationError || this.$store.getters.showPigValidationError;
            const attempts = this.$store.getters.currentPatientValidationAttempts;
            return attempts <= 4 && attempts > 2 && shouldShowPatientValidationmessage;
        },
        hitMaxAttempts() {
            return this.$store.getters.currentPatientValidationAttempts === this.$store.getters.maxPatientValidationAttempts;
        }
    }
};
</script>

<style lang="scss" scoped>
    .step-error {
        @include site-section();
        @include flex-center-vertical();
        position: relative;
        align-items: flex-start;
        height: 102px;
        margin-top: 10px;
        background: white;
        border-radius: 5px;
        border: 1px solid $error-red;
    }

    .step-error-text {
        @include step-text();
        line-height: 1.8em;
    }

    .step-error-logo {
        position: absolute;
        left: 20px;
        top: 37px;
        @include flex-center-vertical();
        width: 25px;
        height: 25px;
        background: $error-red;
        border-radius: 25px;
        color: $white;
        line-height: 18px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        font-weight: 600;
    }

    .step-error-logo--dropped {
        top: 41px;
    }

    .step-patient-validation-error {
        height: auto;
    }

    .step-error-text-patient {
        padding-bottom: 8px;
    }

    .step-error-patient-validation-tips {
        padding-left: 60px;
        padding-right: 40px;
    }

    .step-error-patient-validation-tips-list {
        list-style: disc;
        padding-left: 14px;
        margin-bottom: 40px;
    }

    .step-error-patient-validation-tips-list-item {
        padding-top: 10px;
    }

    .step-error-attempts-warning {
        background: $error-red;
        color: white;
        padding-left: 20px;
    }

    @include small-desktop {
        .step-error {
            @include site-section-sm-desktop();
        }
    }

    @include tablet {
        .step-error {
            width: auto;
            margin-left: 35px;
            margin-right: 35px;
            padding-left: $mobile-banner-padding;
            padding-right: $mobile-banner-padding;
            height: auto;
        }

        .step-error-logo {
            display: none;
        }

        .step-error-text {
            width: 100%;
            padding: 20px 0;
            box-sizing: border-box;
            font-size: 1em;
            font-weight: 400;
        }
        
    }

    @include sm {
        .step-error {
            margin-left: 32px;
            margin-right: 32px;
        }
    }
</style>

