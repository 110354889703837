import { HTTP } from "./http";

const PatientHttp = {
    getAntiForgeryToken: () => {
        return HTTP.get("antiforgery");
    },
    getPatientInfo: () => {
        return HTTP.getWithToken("patient");
    },
    postPatientInfo: (values) => {
        return HTTP.post("patient", values);
    },
    postPatientConsent: (token, consented) => {
        var data = {token: token, patientConsented: consented};
        return HTTP.post("consent", data);
    }
};

export default PatientHttp;
