<template>
    <div :class="containerClass">
        <div class="question-row">
            <label class="question-text myriad-validation-label">
                {{ question }}
            </label>
            <button v-for="(item, index) in this.$store.getters.phq9Answers"
                    :key="index"
                    :class="getButtonClass(index)"
                    :data-value="item.value"
                    type="button"
                    @click="clickEvent(item)"
            >
                <label class="button-label">
                    <check-white class="button-radio-check" />
                    <span class="button-radio-text">
                        {{ item.text }}
                    </span>
                </label>
            </button>
        </div>
        <div class="myriad-required-notice medical-error-message question-row-message">
            This information is required to continue
        </div>
    </div>
</template>

<script>
import CheckWhite from "../../../assets/check_white.svg";

export default {
    name: "QuestionRow",
    components: {
        CheckWhite
    },
    props: {
        question: {
            default: "",
            type: String
        },
        questionIndex: {
            default: NaN,
            type: Number
        },
        active : {
            default: NaN,
            type: Number
        }
    },
    data() {
        return {
            requiredValidation: true
        };
    },
    computed: {
        containerClass() {
            let stringVal = "question-outer-container myriad-validation-container";
            if(this.requiredValidation) {
                stringVal = stringVal + " " + "myriad-container-required";
            }
            return stringVal;
        }
    },
    methods: {
        getButtonClass(index) {
            var classes = "button-radio medical-btn ";
            if(this.active === index) {
                classes  += "medical-btn-active";
                this.requiredValidation = false;
            }
            else if(this.requiredValidation) {
                classes += " myriad-required-error";
            }
            return classes;
        },
        clickEvent(item) {
            const questionIndex = this.questionIndex;
            this.requiredValidation = false;
            this.$emit("phq9Answered", {value: item.value, questionIndex});
        }
    }
};
</script>

<style lang="scss" scoped>
.question-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    border-top: 1px solid #C0C0C0;
}

.question-outer-container:last-child {
    border-bottom: 1px solid #C0C0C0;
}

.question-text {
    width: 35%;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-grow: 1;
    color: $medium-gray-text-color;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.4em;
    text-align: right;
}

.medical-btn {
    margin-right: 15px;
    background: none;
    padding: 0;
    border: none;

    &:focus {
        outline: none;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &:first-of-type {
        margin-left: 15px;
    }
}

.medical-btn-active .button-label .button-radio-check {
    opacity: 1;
}

.medical-btn-active .button-label .button-radio-text {
    color: $white;
}

.medical-btn-active .button-label {
    border-color: $genesight-blue;
    background: $genesight-blue;
}

.question-btn-first {
    margin-left: 18px;
}

.question-btn-last {
    margin-right: 0;
}

.medical-error-message {
    width: 100%;
    max-height: 0;
    text-align: center;
    font-size: $regular-copy-size;
    color: $error-red;
    overflow: hidden;
}

@include small-desktop {
    .question-row {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .question-row:last-child {
        padding-bottom: 20px;
    }

    .question-text {
        width: 100%;
        text-align: center;
    }

    .medical-btn {
        margin-left: 2px;
        margin-right: 2px;
    }

    .medical-btn:first-of-type {
        margin-left: 2px;
    }
}
</style>
