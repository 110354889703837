<template>
    <div class="home-page">
        <hero :background-image="heroBackgroundImage" :hero-text-content="heroText" />
        <div class="home-info-row">
            <div class="home-info-box home-info-box-left">
                <p class="home-box-text">
                    With GeneSight testing, your clinician can get answers that quickly lead to relief. By examinig your DNA, this simple, painless test lets your clinician 
                    know which medications may not work for you, so you can get back to feeling like yourself again. That's the power and simplicity of the GeneSight test.
                </p>
            </div>
            <div class="home-info-box home-info-box-right">
                <h2 class="home-box-title">
                    Take the next step
                </h2>
                <div class="home-steps">
                    <icon-row key="row1" :image="row1Image" :text="row1Text" />
                    <icon-row key="row2" :image="row2Image" :text="row2Text" />
                    <icon-row key="row3" :image="row3Image" :text="row3Text" />
                </div>
                <div class="home-cta">
                    <div class="home-cta-row">
                        <img class="home-cta-img" src="../assets/cell_icon.png">
                        <div class="home-cta-contents">
                            <h6 class="home-cta-title">
                                Better on mobile
                            </h6>
                            <p class="home-cta-text">
                                For the best experience, complete this process on a <b>mobile phone</b> so your camera can be accessed for <b>easy upload</b> of insurance card photos.
                            </p>
                        </div>
                    </div>
                    <button class="home-cta-btn" @click="goToDemographicPage()">
                        <p class="home-cta-btn-text">
                            Let's go!
                        </p>
                        <img class="home-cta-btn-img" src="../assets/btn_arrow.png">
                    </button>
                </div>
                <button class="home-cta-btn home-cta-btn-mobile" @click="goToDemographicPage()">
                    <p class="home-cta-btn-text home-cta-btn-text-mobile">
                        Let's Go
                    </p>
                    <img class="home-cta-btn-img" src="../assets/btn_arrow.png">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Hero from "../components/ui/home/Hero.vue";
import IconRow from "../components/ui/home/IconRow.vue";
import Common from "../scripts/common";
import Http from "../scripts/http/patient-http.js";

// CSS Image Requires
require("../assets/bg_genesight_nucleotide.png");

export default {
    name: "Home",
    components: {
        Hero,
        IconRow
    },
    data: () => {
        return {
            heroBackgroundImage: "home_hero_banner.jpg",
            heroText: "Get your GeneSight<sup>&reg;</sup> test",
            row1Image: "home_row_icon_1.png",
            row1Text:
                "We'll ask a few questions about your health so that your clinician can get your results as quickly as possible.",
            row2Image: "home_row_icon_2.png",
            row2Text:
                "<b>Have your insurance card ready</b> so we can submit a claim to your insurance company after your test is processed. " +
                "<i>(If your out-of-pocket cost is over $330, we'll call you before processing the test. " +
                "<a class='icon-row-link' target='_blank' href='https://genesight.com/cost/'>Learn more</a>)</i>",
            row3Image: "home_row_icon_3.png",
            row3Text: "This typically takes around <b>5 minutes</b> to complete."
        };
    },
    created() {
        Common.grabPatientToken((token, origin)=> {
            this.$store.dispatch("setPatientToken", {token, origin});
        });
        this.getPatientInfo();
    },
    methods: {
        getPatientInfo() {
            Http.getPatientInfo().then(data => {
                var patientPayload = data.data;

                if(patientPayload.result && patientPayload.result === "Unauthorized") {
                    this.$router.push({
                        name: "Error",
                        params: {
                            expiredVersion: true
                        }
                    });
                }
                
                var patientInfo = patientPayload.patient;

                var patientConfiguration = {
                    antiforgeryToken: patientPayload.antiforgeryToken,
                    googleAPIKey: patientPayload.googleAPIKey,
                    googleAutocompleteEnabled: patientPayload.googleAutocompleteEnabled
                };

                this.$store.dispatch("setShowStartupLoader", false);
                if (patientInfo.patientWorkflowStatus == 2){
                    this.$store.dispatch("setReviewStepComplete", true);
                    this.$router.push("/complete");
                }
                else{
                    this.$store.dispatch("setDefaultPatientInfo", patientInfo);
                    this.$store.dispatch("setDefaultConfigurationInfo", patientConfiguration );
                    this.$store.dispatch("setMedicationList", patientPayload.medicationList);

                    if(!this.$store.getters.getPhqInfoSet) {
                        this.$store.dispatch("setPhq9Data", { patientPayload } );
                    }
                }
            },  () => {
                this.goToUnauthorizedPage();
            });
        },
        goToUnauthorizedPage() {
            this.$store.dispatch("setShowStartupLoader", false);
            this.$router.push({
                name: "Error",
                params: {
                    expiredVersion: true
                }
            });
        },
        goToDemographicPage() {
            this.$router.push("demographic");
        }
    }
};
</script>

<style lang="scss" scoped>
$top-margin: 50px;

.home-page {
    max-width: $home-max-width;
    margin: 0 auto;
    top: $header-height;
}

.home-info-row {
    display: table;
    min-height: 610px;
}

.home-info-box {
    width: 50%;
    height: 100%;
    display: table-cell;
    padding-left: $left-margin;
    padding-right: $left-margin;
}

.home-info-box-left {
    background: $tan-background-color;
}

.home-info-box-right {
    background-image: url("../assets/bg_genesight_nucleotide.png");
    background-color: $genesight-background-blue;
    background-position: left -210px top -210px;
    background-repeat: no-repeat;
    background-size: 90%;
}

.home-box-text {
    position: relative;
    top: 30px;
    margin-top: $top-margin;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 2em;
}

.home-box-title {
    margin-top: $top-margin;
    text-align: center;
    font-size: 2.4em;
    font-weight: 500;
    color: $white;
}

.home-steps {
    margin-top: 35px;
}

.home-cta {
    @include flex-center-vertical();
    max-width: 577px;
    margin: 45px auto 25px auto;
    padding: 20px;
    background: $white;
}

.home-cta-row {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.home-cta-img {
    width: 33px;
    min-width: 33px;
    height: 55px;
    margin-right: 18px;
}

.home-cta-title {
    margin-bottom: 2px;
    font-size: $mini-header-font-size;
    font-weight: 700;
    color: $genesight-orange;
}

.home-cta-text {
    font-size: $regular-copy-size;
    line-height: $mini-header-font-size;
    color: $gray-text-color;
}

.home-cta-btn {
    @include flex-center-horizontal();
    width: 275px;
    height: 40px;
    margin-top: 30px;
    font-size: $mini-header-font-size;
    text-align: center;
    background: $genesight-green;
    color: $white;
    border: none;
    border-radius: 55px;

    &:hover {
        background: $genesight-hover-green;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}

.home-cta-btn-mobile {
    display: none;
}

.home-cta-btn-text {
    margin-right: 20px;
    font-size: 1em;
    font-weight: 400;
}

.home-cta-btn-img {
    transform: translateX(0);
    transition: all .25s;
}

.home-cta-btn:hover .home-cta-btn-img {
    transform: translateX(10px);
}

@media (max-width: $home-max-width) {
    .home-page {
        left: 0;
        margin-left: 0;
    }
}

@media (max-width: 1200px) {
    .home-info-row {
        display: flex;
        flex-direction: column;
    }

    .home-info-box {
        display: block;
        width: 100%;
        height: auto;
    }

    .home-box-text {
        top: 0;
        margin-bottom: $top-margin;
    }

    .home-footer-text {
        width: 70%;
        text-align: center;
    }
}

@include tablet {
    .home-info-box {
        padding: 35px;
    }

    .home-box-text {
        margin-top: 0;
        margin-bottom: 0;
        font-size: $regular-copy-size;
    }

    .home-box-title {
        margin-top: 0;
        font-size: 2em;
    }

    .home-cta {
        display: none;
    }

    .home-cta-btn-mobile {
        display: flex;
        margin: 50px auto;
        border-radius: 50px;
    }

    .home-cta-btn-text-mobile {
        flex-grow: 1;
        padding-left: 45px;
    }

    .home-cta-btn:hover .home-cta-btn-img {
        transform: translateX(0px);
    }
}

@include sm {
    .home-cta-btn-mobile {
        width: 240px;
    }
}
</style>
