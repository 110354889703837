<template>
    <label :for="id" :class="containerClass" @keydown="onEnterPress">
        <div>
            <div>
                <input
                    :id="id"
                    ref="radioInput"
                    :name="name"
                    class="large-radioInput"
                    type="radio"
                    :checked="label === vModelProperty"
                    @change="changeEvent(label)"
                >
                <div class="large-radio-label">
                    <check-white class="large-radio-label-img" />
                    <span class="large-radio-label-text">
                        {{ label }}
                    </span>
                </div>
            </div>
            <div class="large-radio-input-content">
                {{ textContent }}
            </div>
        </div>
    </label>
</template>

<script>
import Common from "../../scripts/common.js";
import CheckWhite from "../../assets/check_white.svg";

export default {
    name: "LargeRadioButton",
    components: {
        CheckWhite
    },
    props: {
        name: {
            default: "default",
            type: String,
            required: true
        },
        id: {
            default: "id",
            type: String
        },
        textContent: {
            type: String,
            required: true,
            default: ""
        },
        label: {
            default: "",
            type: String
        },
        vModelProperty: {
            default: "v-model-radio",
            type: String
        }
    },
    computed: {
        containerClass() {
            return this.label === this.vModelProperty ? "large-radio-input" : "large-radio-input large-radio-input--unchecked";
        }
    },
    methods: {
        onEnterPress(event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
                this.$refs.radioInput.checked = true;
                this.$emit("input", this.label);

                const clickEvent = Common.createNewEvent("click");
                this.$refs.radioInput.dispatchEvent(clickEvent);
            }
            if (event.keyCode === 37) {
                this.navigatePreviousRadioInput();
            }
            if (event.keyCode === 39) {
                this.navigateNextRadioInput();
            }
        },
        changeEvent(label) {
            this.$emit("input", label);

            this.$nextTick(()=> {
                const clickEvent = Common.createNewEvent("click");
                this.$refs.radioInput.dispatchEvent(clickEvent);
            });
        },
        navigateNextRadioInput() {
            let nextIndex = this.findIndexOfNextRadioButton();
            const parent = this.$data.allRadioButtons[nextIndex].closest(".button-radio");
            parent.focus();
        },
        navigatePreviousRadioInput() {
            let nextIndex = this.findIndexOfPreviousRadioButton();
            const parent = this.$data.allRadioButtons[nextIndex].closest(".button-radio");
            parent.focus();
        },
        getCurrentButtonIndex() {
            for(let i=0; i < this.$data.allRadioButtons.length; i++) {
                if(this.$data.allRadioButtons[i] === this.$refs.radioInput) {
                    return i;
                }
            }

            return 0;
        },
        findIndexOfNextRadioButton() {
            const currentIndex = this.getCurrentButtonIndex();
            if(currentIndex + 1 >= this.$data.allRadioButtons.length) {
                return 0;
            }
            else {
                return currentIndex + 1;
            } 
        },
        findIndexOfPreviousRadioButton() {
            const currentIndex = this.getCurrentButtonIndex();
            if(currentIndex - 1 < 0) {
                return this.$data.allRadioButtons.length - 1;
            }
            else {
                return currentIndex - 1;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .large-radio-input {
        display: block;
        padding: 15px 20px;
        background: $genesight-blue;
        border-radius: 25px;
        color: white;
        outline: none;
        border: 2px solid $genesight-blue;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            box-shadow: 0 0 3px $genesight-blue;
        }
    }

    .large-radioInput {
        position: absolute;
        visibility: hidden;
    }

    .large-radio-label-img {
        margin-left: -15px;
        margin-right: 2px;
        width: 13px;
        height: auto;
    }

    .large-radio-label-text {
        font-size: 1em;
        font-weight: 700;
    }

    .large-radio-input-content {
        font-size: $regular-copy-size;
    }

    .large-radio-input--unchecked {
        background: none;
        color: $text-color !important;
        border: 2px solid $gray-text-color;

        .large-radio-label {
            color: $gray-text-color;
        }

        .large-radio-label-img {
            display: none; 
        }
    }
</style>


