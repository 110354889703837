<template>
    <div class="survey-thank-you">
        <survey-thank-you v-if="$store.getters.lastPagesSaved === 3" />
    </div>
</template>

<script>
import SurveyThankYou from "../../components/layout/Survey/SurveyThankYou.vue";
export default {
    name: "SurveyThankYouPage",
    components: {
        SurveyThankYou
    }
};
</script>

<style lang="scss" scoped>
</style>
