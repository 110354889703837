<template>
    <div class="delete-row">
        <div class="delete-btn" @click="$emit('deleteClick')">
            <img class="remove-icon" src="../../assets/remove.png">
        </div>
    </div>
</template>

<script>
export default {
    name: "DeleteBtn"
};
</script>

<style lang="scss" scoped>
    .delete-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2px;
        width: 100%;
    }

    .delete-label {
        height: 1em;
        line-height: -1px;
        font-size: $small-copy-size;
        font-weight: bold;
        color: $medium-gray-text-color;
    }

    .delete-btn {
        position: absolute;
        top: 0;
        right: -25px;

        &:hover {
            cursor: pointer;
        }
    }
</style>

