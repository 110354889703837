<template>
    <div class="toggle-btn-container">
        <p class="left-label">
            {{ options[0] }}
        </p>
        <div class="toggle-btn">
            <label :for="inputId">
                <input :id="inputId" type="checkbox" class="toggle-input" @change="onInputChange">
                <div class="toggle-bg">
                    <div class="toggle-action" />
                </div>
            </label>
        </div>
        <p class="right-label">
            {{ options[1] }}
        </p>
    </div>
</template>

<script>
export default {
    name: "Toggle",
    props: {
        inputId: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        }
    },
    methods: {
        onInputChange(event) {
            const value = event.target.checked ? this.options[1] : this.options[0];
            this.$emit("toggleChange", value);
        }
    }
};
</script>

<style lang="scss" scoped>
    .toggle-btn-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .toggle-btn {
        display: block;
        flex-direction: row;
        width: 63px;
        height: 30px;;;
    }

    .toggle-input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .toggle-bg {
        position: relative;
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 9990px;
        transition: background .25s;
    }

    .toggle-action {
        transition: all .25s;
    }

    .toggle-action {
        position: absolute;
        top: -1px;
        width: 30px;
        height: 30px;
        background: $genesight-green;
        border-radius: 9999px;
    }

    .toggle-input:checked ~ .toggle-bg .toggle-action {
        transform: translateX(calc(100% + 1px));
        background: black;
    }

    .toggle-input ~ .toggle-bg {
        border: 1px solid $genesight-green;
    }

    .toggle-input:checked ~ .toggle-bg {
        border: 1px solid black;
    }

    .left-label {
        margin-right: 16px;
    }

    .right-label {
        margin-left: 16px;
    }
</style>
