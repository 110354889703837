import { render, staticRenderFns } from "./NucleotideLoader.vue?vue&type=template&id=e5fb4e26&scoped=true&"
import script from "./NucleotideLoader.vue?vue&type=script&lang=js&"
export * from "./NucleotideLoader.vue?vue&type=script&lang=js&"
import style0 from "./NucleotideLoader.vue?vue&type=style&index=0&id=e5fb4e26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.registry.npmjs.org/vue-loader/15.9.8_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5fb4e26",
  null
  
)

export default component.exports