<template>
    <div class="survey-wrapper">
        <router-view />
        <myriad-dialog :dialog-open="$store.getters.showBackBtnDialog" title="" @dialogClose="closeDialog">
            <p class="survey-dialog-text">
                We're sorry, this page is no longer available
            </p>
            <p class="survey-dialog-blue-text">
                Please continue with the questions.
            </p>
            <p class="survey-dialog-small-text">
                We would appreciate your feedback.
            </p>
        </myriad-dialog>
    </div>
</template>

<script>
import MyriadDialog from "../components/layout/MyriadDialog.vue";
import Common from "../scripts/common";
import { HTTP } from "../scripts/http/http.js";
import SurveyHttp from "../scripts/http/survey-http";

export default {
    name: "SurveyWrapper",
    components: {
        MyriadDialog
    },
    data: () => {
        return {
            showFirstSurvey: false,
            showSecondSurvey: false
        };
    },
    computed: {
        showBackDialog() {
            return this.$store.getters.showBackBtnDialog;
        }
    },
    watch: {
        showBackDialog(newValue) {
            if(newValue) {
                this.$nextTick(() => {
                    document.querySelector(".dialog-button").innerText = "Continue";
                });
            }
        }
    },
    created() {
        Common.grabPatientToken((token, origin)=> {
            this.$store.dispatch("setSurveyToken", {token, origin});
        });
        HTTP.getAntiforgeryToken();
        this.getSurvey();
    },
    mounted() {
        history.pushState(null, null, window.location.href);
        window.onpopstate = ()=> { 
            history.forward();
            this.$store.dispatch("setShowBackBtnDialog", true);
        };         
    },
    methods: {
        getSurvey() {
            SurveyHttp.getSurvey().then(response => {
                const data = response.data;

                if(data.expired) {
                    this.goToExpiredPage();
                }
                else if(data.pagesSaved == 3) {
                    this.$router.push("/survey/survey-thank-you");
                }
                else if(data.consented && data.surveyType === 0) {
                    this.$store.dispatch("setConsentAccepted", true);
                    this.$router.push("/survey/survey1");
                }
                else if(data.surveyType === 1) {
                    this.$store.dispatch("setConsentAccepted", true);
                    this.$router.push("/survey/survey2");
                }
                
                this.$store.dispatch("setPagesSaved", data.pagesSaved);
                this.$store.dispatch("setShowStartupLoader", false);
            },
            () => {
                this.goToExpiredPage();
            });
        },
        closeDialog() {
            this.$store.dispatch("setShowBackBtnDialog", false);
        },
        goToExpiredPage() {
            this.$router.push({
                name: "Error",
                params: {
                    surveyExpiredVersion: true
                }
            });
            this.$store.dispatch("setShowStartupLoader", false);
        }
    }
};
</script>

<style lang="scss" scoped>
    .survey-dialog-text {
        font-size: $no-insurance-header-font-size;
        text-align: center;
        color: #333333;
    }

    .survey-dialog-blue-text {
        margin-top: -50px;
        color: $genesight-blue;
        font-size: $mini-header-font-size;
        text-align: center;
    }

    .survey-dialog-small-text {
        margin-top: -50px;
        text-align: center;
        color: #333333;
    }
</style>
