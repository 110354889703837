<template>
    <div>
        <div class="insurance-error-title-row">
            <h4 class="insurance-error-header">
                {{ title }}
            </h4>
            <router-link :to="editLocation" class="insurance-error-edit">
                edit
            </router-link>
        </div>
        <div class="insurance-card-review-error">
            <div class="top-row">
                <div class="error-logo">
                    !
                </div>
                <h6 class="error-header">
                    For your security, insurance card images are not saved when a page is refreshed.
                </h6>
            </div>
            <div class="top-row">
                <div class="error-logo error-logo--invisible" />
                <p class="error-content">
                    Please return to the insurance page and upload or enter your insurance information.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InsuranceCardReviewError",
    props: {
        primaryInsurance: {
            default: true,
            required: true,
            type: Boolean
        }
    },
    computed: {
        title: function() {
            return this.primaryInsurance ? "Primary" : "Secondary";
        },
        editLocation: function() {
            return this.primaryInsurance ? "/insurance/primary" : "/insurance/secondary";
        }
    }
};
</script>

<style lang="scss" scoped>
    .insurance-error-title-row {
        margin-bottom: 30px;
    }

    .insurance-error-header {
        display: inline-block;
    }

    .insurance-error-edit {
        float: right;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .insurance-card-review-error {
        padding: 15px 18px;
        border-radius: 10px;
        background: $step-error-bg;
    }

    .top-row {
        display: flex;
        flex-direction: row;
    }

    .error-logo {
        @include flex-center-vertical();
        min-width: 25px;
        min-height: 25px;
        max-height: 25px;
        margin-right: 10px;
        background: $error-red;
        border-radius: 25px;
        color: $white;
        line-height: 18px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        font-weight: 600;
    }

    .error-logo--invisible {
        visibility: hidden;
    }

    .error-header {
        margin-bottom: 20px;
        font-size: $regular-copy-size;
        font-weight: 700;
    }

    .error-content {
        font-size: $regular-copy-size;
    }
</style>
