import { render, staticRenderFns } from "./SurveyTwo.vue?vue&type=template&id=06ce6f16&scoped=true&"
import script from "./SurveyTwo.vue?vue&type=script&lang=js&"
export * from "./SurveyTwo.vue?vue&type=script&lang=js&"
import style0 from "./SurveyTwo.vue?vue&type=style&index=0&id=06ce6f16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.registry.npmjs.org/vue-loader/15.9.8_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ce6f16",
  null
  
)

export default component.exports