<template>
    <div class="consent-page">
        <div class="consent-page-content">
            <img class="logo-img" src="../../assets/genesight_logo_tagged.png">
            <p class="consent-title">
                Welcome to our health survey.
            </p>
            <div class="consent-insturctions">
                <p class="consent-insturctions-text">
                    Your participation is voluntary. Your treatment is not influenced in any way by your decision to participate in this survey and your responses are not shared with your healthcare provider.
                </p>
            </div>
            <h3 class="consent-sub-title">
                The <b>usage and protection</b> of your information are outlined in the document below.
            </h3>
            <div class="consent-iframe-container">
                <iframe class="consent-iframe" width="100%" height="100%" src="./docs/gpro-consent-form-v2.pdf#view=fitH" />
            </div>
            <a class="consent-download-link" download href="./docs/gpro-consent-form-v2.pdf">
                <img class="consent-pdf-icon" src="../../assets/consent_pdf_icon.png">
                Download Informed Consent Form
            </a>
            <p class="agree-paragraph">
                You must be 18 years of age (19 in Nebraska and Alabama) to consent and participate in this study. If you agree to participate, you can continue by clicking the “I Agree” button below to acknowledge your understanding and participation. If you decide not to participate, please close this window.
            </p>
            <div class="agree-btn-container">
                <button class="submit-btn consent-agree-btn" type="button" @click="goToSurvey">
                    <p class="submit-btn-text">
                        I agree
                    </p>
                    <img class="submit-btn-image" src="../../assets/btn_arrow.png">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SurveyHttp from "../../scripts/http/survey-http.js";

export default {
    name: "Consent",
    methods: {
        goToSurvey() {
            SurveyHttp.postConsentAccepted();
            this.$store.dispatch("setConsentAccepted", true);
            this.$router.push("/survey/survey1");
        }
    }
};
</script>

<style lang="scss" scoped>
    $consent-width: 1100px;
    $consent-top-margin: 49px;
    $consent-top-margin-tablet: 25px;

    .consent-page-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        max-width: $consent-width;
    }

    .logo-img {
        margin-top: 20px;
    }

    .consent-title {
        margin-top: 49px;
        max-width: $consent-width;
        text-align: center;
        font-family: 'Raleway', sans-serif;
        font-weight: 100;
        color: #333333;
        font-size: 2.75em;
    }

    .consent-insturctions {
        margin-top: $consent-top-margin;
        max-width: $consent-width;
        background: $survey-blue-bg;
        border-radius: 5px;
    }

    .consent-insturctions-text {
        padding: 30px;
        font-size: 1.5em;
        line-height: 1.55em;
        font-weight: 100
    }

    .consent-sub-title {
        max-width: 688px;
        margin-top: $consent-top-margin;
        color: $genesight-blue;
        font-size: 2em;
        text-align: center;
        text-transform: none;
    }
     
    .consent-iframe-container {
        margin-top: $consent-top-margin;
        width: $consent-width;
        height: 450px;
    }

    .consent-iframe {
        border: none;
    }

    .consent-download-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-start;
        margin-top: 20px;
        text-decoration: none;
    }

    .consent-pdf-icon {
        width: 14px;
        margin-right: 12px;
    }

    .agree-btn-container {
        width: 100%;
        margin-top: $consent-top-margin;
        margin-bottom: 150px;
    }

    .consent-agree-btn {
        float: right;
        width: 200px;
    }

    .agree-paragraph {
        margin-top: 25px;
        margin-bottom: 5px;
        color: #333333;
        font-size: 1em;
    }

    @include tablet {
        .consent-page-content {
            max-width: 500px;
        }
        
        .consent-title { 
            margin-top: $consent-top-margin-tablet;
            font-size: 1.5em;
        }
        
        .consent-insturctions {
            margin-top: $consent-top-margin-tablet;
        }

        .consent-insturctions-text {
            padding: 20px;
            font-size: 1em;
        }

        .consent-sub-title {
            margin-top: $consent-top-margin-tablet;
            font-size: 1.5em;
        }

        .consent-iframe-container {
            margin-top: $consent-top-margin-tablet;
            width: 500px;
            height: 450px;
        } 

        .consent-download-link {
            margin-top: 5px;
        }

        .agree-btn-container {
            margin-top: $consent-top-margin-tablet;
        }

        .agree-paragraph {
            font-size: 1em;
        }
    }

    @include sm() {
        .consent-page-content {
            max-width: 100%;
        }

        .consent-iframe-container {
            position: relative;
            width: 375px;
            height: 450px;
        } 
    }
</style>
