import Axios from "axios";

const PrivacyHttp = {
    rootUrl: "https://services.myriad.com/notices/notices/?notice_id=",
    getNotice: function (noticeId) {
        const uri = PrivacyHttp.rootUrl + noticeId;
        return Axios.get(uri);
    }
};

export default PrivacyHttp;
