<template>
    <div class="full-name--comp">
        <div v-container-validation class="input-row">
            <label class="input-label label">
                Your Full Name
            </label>
            <input v-model="firstNameValue" 
                   v-max-length-validation="{maxLength: 100, required: true}" 
                   :class="firstNameClassName" 
                   placeholder="First Name"
                   name="fname"
                   :disabled="disableInputs"
                   autocompletetype="fname firstname"
                   @input="$emit('FullNameInputChange')"
            >
            <input v-model="lastNameValue" 
                   v-max-length-validation="{maxLength: 100, required: true}" 
                   :class="lastNameClassName" 
                   placeholder="Last Name"
                   name="lname"
                   :disabled="disableInputs"
                   autocompletetype="lname lastname"
                   @input="$emit('FullNameInputChange')"
            >
            <div class="input-row input-name-notice">
                <label class="label input-label" />
                <p class="input-notice">
                    Enter your name as it appears on your insurance card.
                </p>
            </div>
            <error-labels :invalid-string="errorString" />
        </div>
    </div>
</template>

<script>
import ErrorLabels from "./ErrorLabels.vue";

export default {
    name: "FullName",
    components: {
        ErrorLabels
    },
    props: {
        errorString: {
            default: "",
            type: String
        },
        firstName: {
            default: "",
            type: String
        },
        lastName: {
            default: "",
            type: String
        },
        disableInputs: {
            default: false,
            type: Boolean
        },
        hasError: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        firstNameValue: {
            get() {
                return this.firstName;
            },
            set(val) {
                this.$emit("firstNameInput", val);
            }
        },
        lastNameValue: {
            get() {
                return this.lastName;
            },
            set(val) {
                this.$emit("lastNameInput", val);
            }
        },
        firstNameClassName() {
            let defaultClasses = "text-input input-md input-mgr input-tablet-wrap";
            let errorClass = this.hasError ? "text-input-border-error" : "";

            return `${defaultClasses} ${errorClass}`;
        },
        lastNameClassName() {
            let defaultClasses = "text-input input-md";
            let errorClass = this.hasError ? "text-input-border-error" : "";

            return `${defaultClasses} ${errorClass}`;
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../../styles/inputs.scss";
</style>
