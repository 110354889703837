<template>
    <div class="survey-page-2">
        <thank-you-topper />
        <h3 class="survey-page-title">
            Having any side effects
        </h3> 
        <div class="survey-blue-box">
            <p class="survey-blue-box-text">
                Details on side effects can improve our ability to predict response to certain medications. 
            </p>
        </div>
        <slot />
        <div class="survey-gray-box">
            <p class="survey-gray-box-text">
                The Antidepressant Side-Effect Checklist (ASEC)
            </p>
        </div>
        <div class="survey-instructions">
            <p class="survey-instructions-text">
                Please score the following list of symptoms
            </p>
            <ul class="survey-instructions-list">
                <li class="survey-instructions-list-item">
                    0 = <b>absent</b>
                </li>
                <li class="survey-instructions-list-item">
                    1 = <b>mild</b>
                </li>
                <li class="survey-instructions-list-item">
                    2 = <b>moderate</b>
                </li>
                <li class="survey-instructions-list-item">
                    3 = <b>severe</b>
                </li>
            </ul>
            <p class="survey-instructions-text">
                Please indicate if the symptom is likely to be a <b>side effect of antidepressant medication</b> (Y = YES, N = NO). 
            </p>
            <p class="survey-instructions-text">
                Enter a comment to provide relevant information if the item is <b>not</b> a side effect.
            </p>
        </div>
        <div class="side-effects">
            <div class="side-effects-row side-effects-header-row">
                <p class="side-effects-header side-effects-column-1">
                    Symptom
                </p>
                <p class="side-effects-header side-effects-column-2">
                    Score (0-3)
                </p>
                <p class="side-effects-header side-effects-column-3">
                    Linked to Antidepressant
                </p>
                <p class="side-effects-header side-effects-column-4">
                    Comment
                </p>
            </div>
            <div 
                v-for="(item, index) in symptoms"
                :key="index"
                class="side-effects-row symptoms-row"
            >
                <label class="side-effects-column-1">
                    {{ item }}
                </label>
                <div class="side-effects-column-2 side-effects-inner-row">
                    <p
                        v-multi-ref:sideEffectRowLabel
                        class="inner-row-label"
                    />
                    <genesight-button-row 
                        v-multi-ref:sideEffectRow 
                        class=" button-row"
                        hide-labels="true"
                        slim="true" 
                    />
                </div>
                <label class="linked-label">
                    Linked to antidepressant?
                </label>
                <genesight-btn-row-input
                    v-multi-ref:sideEffectLink
                    class="side-effects-column-last"
                    placeholder="Comments (optional)"
                />
            </div>
        </div>
        <div>
            <genesight-textarea ref="textAreaOne" class="textarea" :label="textAreaOneQuestion" :data-question="textAreaOneQuestion" max-length="1000" />
            <genesight-textarea ref="textAreaTwo" class="textarea" :label="textAreaTwoQuestion" :data-question="textAreaTwoQuestion" max-length="1000" />
            <genesight-textarea ref="textAreaThree" class="textarea" :label="textAreaThreeQuestion" :data-question="textAreaThreeQuestion" max-length="1000" />
        </div>
        <div class="submit-btn-container">
            <button class="submit-btn survey-submit-btn cy-agree-btn" type="button" @click="submitPage">
                <p class="submit-btn-text">
                    Submit
                </p>
                <img class="submit-btn-image" src="../../../assets/btn_arrow.png">
            </button>
        </div>
        <p class="bottom-note">
            <b>Note:</b> Your information will not be shared with your healthcare provider.
        </p>
        <p class="bottom-note">
            Uher, R., Maier, W., Hauser, J., Marušič, A., Schmael, C., Mors, O., . . . McGuffin, P. (2009). Differential efficacy of escitalopram and nortriptyline on dimensional measures of depression. British Journal of Psychiatry, 194(3), 252-259. doi:10.1192/bjp.bp.108.057554
        </p>
        <p class="bottom-note">
            Corrections. (2009). British Journal of Psychiatry, 195(1), 87-87. doi:10.1192/bjp.195.1.87a
        </p>   
    </div>
</template>

<script>
import ThankYouTopper from "../../layout/Survey/ThankYouTopper.vue";
import SurveyHttp from "../../../scripts/http/survey-http.js";
import Common from "../../../scripts/common";

export default {
    name: "SurveyPage2",
    components: {
        ThankYouTopper
    },
    props: {
        isSurvey1: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            validState: false,
            symptoms: [
                "Dry Mouth",
                "Drowsiness",
                "Insomnia (difficulty sleeping)",
                "Blurred vision",
                "Headache",
                "Constipation",
                "Diarrhea",
                "Increased appetite",
                "Decreased appetite",
                "Nausea or vomiting",
                "Problems with urination",
                "Problems with sexual function",
                "Palpitations",
                "Feeling light-headed on standing",
                "Feeling like the room is spinning",
                "Sweating",
                "Increased body temperature",
                "Tremor",
                "Disorientation",
                "Yawning",
                "Weight gain"
            ],
            options: ["0", "1", "2", "3"],
            symptomsEnum: {
                0: "absent",
                1: "mild",
                2: "moderate",
                3: "severe"
            },
            textAreaOneQuestion: "What other symptoms have you had since the antidepressant medication (or since last completing the ASEC)  that you think may be side effects of the medication?",
            textAreaTwoQuestion: "Have you had any treatment for a side effect?",
            textAreaThreeQuestion: "Has any side effect led to you discontinuing the antidepressant medication?",
            jsonData: {symptoms: {}},
            textAreaStyling: `
                <style>
                    .label + .textarea {
                        font-family: 'Lato', sans-serif;
                        font-size: .9em;
                    }
                </style>
            `
        };
    },
    mounted() {
        Common.scrollToTop();

        this.symptoms.forEach((item, index) => {
            this.$refs.sideEffectRow[index].options = this.$data.options;
            this.$refs.sideEffectRow[index].addEventListener("optionSelected", (event) => {

                this.$refs.sideEffectRowLabel[index].innerHTML = this.symptomsEnum[event.detail];
                
                // Set input as invalid
                this.$refs.sideEffectRow[index].invalid = false;

                // Set Y/N input to invalid also if severity is 0
                if(event.detail === "0") {
                    this.$refs.sideEffectLink[index].invalid = false;
                }

                Common.mergeOrAddToJson(this.jsonData.symptoms, item, {
                    "answer": this.symptomsEnum[event.detail]
                });

                Common.mergeOrAddToJson(this.jsonData.symptoms, item, {
                    "index": index
                });
            });
            this.$refs.sideEffectLink[index].addEventListener("buttonSelection", (event) => {
                this.$refs.sideEffectLink[index].invalid = false;
                
                var detail = event.detail;

                // If length is greater than 1, then it was translated and the "Y" or "N" will
                // be wrapped in font tags. Simply strip all html tags from the value.
                if (detail.length > 1) {
                    detail = detail.replace(/<\/?[^>]+(>|$)/g, "");
                }

                Common.mergeOrAddToJson(this.jsonData.symptoms, item, {
                    "linkedToDepression": detail
                });

                Common.mergeOrAddToJson(this.jsonData.symptoms, item, {
                    "index": index
                });
            });
            this.$refs.sideEffectLink[index].addEventListener("textInput", (event) => {
                this.$refs.sideEffectLink[index].invalid = false;
                Common.mergeOrAddToJson(this.jsonData.symptoms, item, {
                    "comments": event.detail
                });
            });
            this.$refs.textAreaOne.addEventListener("inputEvent", this.handleTextAreaInput);
            this.$refs.textAreaTwo.addEventListener("inputEvent", this.handleTextAreaInput);
            this.$refs.textAreaThree.addEventListener("inputEvent", this.handleTextAreaInput);

            this.$refs.textAreaOne.shadowRoot.innerHTML = this.textAreaStyling;
            this.$refs.textAreaTwo.shadowRoot.innerHTML = this.textAreaStyling;
            this.$refs.textAreaThree.shadowRoot.innerHTML = this.textAreaStyling;
        });
    },
    methods: {
        validateSymptoms() {
            // Accumulator function
            function accum(accumulator, excludedValue, includedIndexs) {
                let value = includedIndexs.find((includedValue)=> {
                    return excludedValue === includedValue;
                });

                if(value === undefined) {
                    accumulator.push(excludedValue);
                }

                return accumulator;
            }

            // let excludedIndexs = Common.getExcludedSurveyIndexes(this.symptoms, this.jsonData.symptoms);
            let excludedIndexsScore = [];
            let excludedIndexsLinked = [];
            let includedIndexsScore = [];
            let includedIndexsLinked = [];

            // Create default excluded indexs arrays
            for(let i = 0; i < this.symptoms.length; i++) {
                excludedIndexsScore.push(i);
                excludedIndexsLinked.push(i);
            }
            
            // Create arrays of indexs that have answers included
            for(const key in this.jsonData.symptoms) {
                if(this.jsonData.symptoms[key].answer) {
                    includedIndexsScore.push(this.jsonData.symptoms[key].index);

                    // If answer is absent then include in linkedArray also
                    if(this.jsonData.symptoms[key].answer === "absent") {
                        includedIndexsLinked.push(this.jsonData.symptoms[key].index);
                    }
                }
                    
                if(this.jsonData.symptoms[key].linkedToDepression) {
                    includedIndexsLinked.push(this.jsonData.symptoms[key].index);
                }
            }

            // Edit excluded indexes to remove the values in includedIndexs
            excludedIndexsScore = excludedIndexsScore.reduce((accumulator, excludedValue) => {
                return accum(accumulator, excludedValue, includedIndexsScore);
            }, []);
            excludedIndexsLinked = excludedIndexsLinked.reduce((accumulator, excludedValue) => {
                return accum(accumulator, excludedValue, includedIndexsLinked);
            }, []);

            //loop exluded arrays and mark them as invalid
            excludedIndexsScore.forEach((index)=> {
                this.$refs.sideEffectRow[index].invalid = true;
            });
            excludedIndexsLinked.forEach((index)=> {
                this.$refs.sideEffectLink[index].invalid = true;
            });

            //Set valid state and dispatch store values
            this.validState = excludedIndexsScore.length == 0 && excludedIndexsLinked.length == 0;
            this.$store.dispatch("setShowSurvey1ErrorBubble", !this.validState);
            if(!this.validState) {
                this.$nextTick(() => {
                    Common.scrollToErrorMessage(".error-bubble");
                }); 
            }
        },
        handleTextAreaInput(event) {
            this.jsonData[event.target.dataset.question] = event.detail;
        },
        submitPage() {
            this.validatePage();
            if(this.validState) {
                this.$store.dispatch("setShowStartupLoader", true);
                SurveyHttp.postSurveyPage(this.jsonData, 2, !this.isSurvey1).then(()=> {
                    this.$store.dispatch("setPagesSaved", 2);
                    this.$store.dispatch("setSurveyComplete", !this.isSurvey1);
                }).catch(()=> {
                    this.$router.push("/error");
                }).finally(()=> {
                    this.$store.dispatch("setShowStartupLoader", false);
                });
            }
        },
        validatePage() {
            this.validState = true;
            this.validateSymptoms();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_survey.scss";
$mobile-vertial-margin: 10px;

.side-effects {
    margin-top: 50px;
    margin-bottom: 60px;
}

.side-effects-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #D0D3DA;
}

.side-effects-header-row {
    position: sticky;
    top: 0;
    padding: 0 0 5px 0; 
    background: #f5f5f5;
}

.side-effects-inner-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.symptoms-row {
    padding-top: 25px;
    padding-bottom: 25px;
}

.side-effects-header {
    text-align: center;
    font-size: $survey-instructions-text-size;
    line-height: $survey-instructions-text-height;
    color: $survey-instructions-color;
}

.symptom-header {
    padding-left: 80px;
}

.score-header {
    padding: 0 100px;
}

.side-effects-column-1 {
    width: 21%;
}

.side-effects-column-2 {
    width: 35%
}

.side-effects-column-3 {
    width: 11%;
}

.side-effects-column-4 {
    width: 33%;
}

.side-effects-column-last {
    width: 44%;
}

.inner-row-label {
    width: 25%;
    color: $genesight-green;
    text-align: right;
    padding-right: 30px;
}

.button-row {
    width: 75%;
    padding-right: 30px;
}

.linked-label {
    display: none;
    font-size: $survey-instructions-text-size;
    line-height: $survey-instructions-text-height;
    color: $survey-instructions-color;
}

.bottom-note {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: .9em;
    color: $survey-instructions-color;
}

.textarea {
    margin-bottom: 50px;
}

.textarea:last-child {
    margin-bottom: 100px;
}

.submit-btn-container {
    margin: 0 auto;
    width: 185px;

    .submit-btn {
        width: 100%;
    }
}

@include small-desktop {

}

@include tablet {
    .side-effects-row,
    .side-effects-inner-row {
        flex-direction: column;
        align-items: flex-start;
        border: none;
    }

    .side-effects-header-row {
        display: none;
    }

    .linked-label {
        display: block;
        margin-top: $mobile-vertial-margin;
        margin-bottom: $mobile-vertial-margin;
        font-size: 0.75em;
    }

    .side-effects-column-1,
    .side-effects-column-2,
    .side-effects-column-last {
        width: 100%;
    }

    .button-row {
        width: 100%;
        padding-right: 0;
    }

    .inner-row-label {
        margin-top: $mobile-vertial-margin;
        margin-bottom: $mobile-vertial-margin;
        height: 17px;
        text-align: left;
        font-size: 0.875em;
    }
}

@include sm {

}

</style>
