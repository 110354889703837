<template>
    <div class="insurance-page">
        <h2 class="insurance-header">
            <b>Secondary</b> medical insurance information
        </h2>
        <div class="insurance-row insurance-secondary-question">
            <p :class="showSecondaryInsuranceError ? 'insurance-label--error label insurance-label' : 'label insurance-label'">
                Do you have secondary insurance?
            </p>
            <rad-button id="secondaryInsuranceYes" 
                        v-model="secondaryInsurance" 
                        :v-model-property="secondaryInsurance" 
                        class="insurance-btn"
                        name="secondaryInsurance" 
                        label="Yes"
                        tabindex="0"
                        @input="secondaryInsuranceInput"
            />
            <rad-button id="secondaryInsuranceNo" 
                        v-model="secondaryInsurance" 
                        :v-model-property="secondaryInsurance" 
                        class="insurance-btn"
                        name="secondaryInsurance" 
                        label="No"
                        tabindex="-1"
                        @input="secondaryInsuranceInput"
            />
        </div>
        <transition name="grow">
            <div v-if="showSecondaryInsuranceError" class="secondary-insurance-error-notice required-message">
                This information is required to continue
            </div>
        </transition>
        <transition name="grow">
            <form-validate
                v-if="secondaryInsurance === 'Yes'"
                ref="secondaryInsuranceForm"
                class-name="secondary-insurance-form"
                @no-errors-callback="removeErrorsBanner"
                @valid-callback="handleFormSuccess"
                @invalid-callback="showFormErrors"
            >
                <insurance-form ref="insuranceForm" :primary-insurance="false" />
            </form-validate>
        </transition>
        <btn-row class="secondary-insurance-btn-row" @continueClick="submitForm" @backClick="goToPrimaryInsurancePage" />
    </div>
</template>

<script>
import BtnRow from "../components/layout/BtnRow.vue";
import RadButton from "../components/ui/ButtonRadioInput.vue";
import InsuranceForm from "../components/layout/Insurance/InsuranceForm.vue";
import PatientInformationGatheringHttp from "../scripts/http/patient-information-gathering-http.js";
import Common from "../scripts/common.js";
import BasePage from "./BasePage.vue";

export default {
    name: "Insurance",
    components: {
        BtnRow,
        RadButton,
        InsuranceForm
    },
    extends: BasePage,
    data: ()=> {
        return {
            showSecondaryInsuranceError: false
        };
    },
    computed: {
        secondaryInsurance: {
            get() {
                return this.$store.getters.secondaryInsuranceIncluded;
            },
            set(value) {
                this.$store.dispatch("setSecondaryInsuranceIncluded", value);
            }
        },
        insuranceCardMissing() {
            return this.$store.getters.getSecondaryInsuranceCardsAdded && this.$store.getters.getAreSecondaryCardsMissing && this.$store.getters.insuranceStepComplete;
        }
    },
    watch: {
        insuranceCardMissing(newValue, oldValue) {
            if(newValue !== oldValue) {
                if(newValue) {
                    this.$store.dispatch("setErrorMessageText", "For your security, insurance card images are not saved when a page is refreshed. Please upload or enter your insurance information");
                    this.$store.dispatch("setShowErrorMessage", true);
                }
                else {
                    this.$store.dispatch("");
                    this.$store.dispatch("setShowErrorMessage", false);
                }
            }
        }
    },
    mounted() {
        if(this.insuranceCardMissing) {
            this.showInsuranceCardsMissingError();
        }
    },
    methods: {
        goToPrimaryInsurancePage() {
            if (this.$store.getters.getIsPatientInformationGathering) {
                this.$router.push("/patient-information-insurance/primary");
            } else {
                this.$router.push("/insurance/primary");
            }
        },
        submitForm() {
            if(!Common.doesExistAndNotEmpty(this.secondaryInsurance)){
                this.$data.showSecondaryInsuranceError = true;
                this.$store.dispatch("setShowErrorMessage", true);
            }
            else {
                this.$data.showSecondaryInsuranceError = false;

                if(this.secondaryInsurance === "No") {
                    this.handleFormSuccess();
                }
                else {
                    if(this.$refs.secondaryInsuranceForm) {
                        //Create and submit the form event
                        this.$nextTick(()=> {
                            const submitEvent = Common.createNewEvent("submit");
                            this.$refs.secondaryInsuranceForm.$refs.validateForm.dispatchEvent(submitEvent);
                        });   
                    }  
                }
            }
            
        },
        handleFormSuccess() {
            if (!this.$store.getters.getIsPatientInformationGathering) {
                this.goToMedicalPage();
            } else {
                // Perform information gathering post
                var patient = this.$store.getters.patient;
                patient.insurances = Common.getFormattedInsurances(patient);
                // Add insurance card images to patient object to ensure data and documents send in one post
                patient.primaryCardFront = this.$store.getters.primaryFrontInsuranceCard;
                patient.primaryCardBack = this.$store.getters.primaryBackInsuranceCard;
                patient.secondaryCardFront = this.$store.getters.secondaryFrontInsuranceCard;
                patient.secondaryCardBack = this.$store.getters.secondaryBackInsuranceCard;

                PatientInformationGatheringHttp.postPatientInsurance(patient).then(()=> {
                    this.$store.dispatch("setShowStartupLoader", false);
                    this.$store.dispatch("setReviewStepComplete", true);
                    this.$router.push("/complete");
                }).catch(() => {
                    this.$store.dispatch("setShowStartupLoader", false);
                    this.$router.push("/error");
                });
            }
        },
        goToMedicalPage() {
            this.$store.dispatch("setShowErrorMessage", false);
            this.$store.dispatch("setInsuranceStepComplete", true);
            this.$router.push("/medical");
        },
        showFormErrors() {
            this.$store.dispatch("setShowErrorMessage", true).then(()=> {
                this.$nextTick(()=> {
                    Common.scrollToErrorMessage();
                }); 
            });
        },
        removeErrorsBanner() {
            this.$store.dispatch("setShowErrorMessage", false);
        },
        secondaryInsuranceInput() {
            this.$data.showSecondaryInsuranceError = false;
            if(this.secondaryInsurance === "No") {
                this.$store.dispatch("setShowErrorMessage", false);
                this.$store.dispatch("clearSecondaryInsuranceInfo");
                this.$store.dispatch("clearSecondaryInsuranceCards");
            }
        },
        showInsuranceCardsMissingError() {
            this.$store.dispatch("setErrorMessageText", "For your security, insurance card images are not saved when a page is refreshed. Please upload or enter your insurance information");
            this.$store.dispatch("setShowErrorMessage", true);
        }
    }
};
</script>

<style lang="scss" scoped>
.insurance-page {
    @include site-section();
    margin-top: 25px;
}

.insurance-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.insurance-secondary-question {
    margin-top: 60px;
}

.insurance-label {
    width: 306px;
    margin-right: 14px;
    text-align: right;
}

.insurance-label--error {
    color: $error-red;
}

.secondary-insurance-error-notice {
    font-size: $regular-copy-size;
    margin-left: calc(#{$insurance-label-width} + 14px);
    color: $error-red;
}

.insurance-btn {
    margin-right: 20px;
}

.secondary-insurance-btn-row {
    margin-top: 90px;
}

.secondary-insurance-form {
    opacity: 1;
    max-height: 1307px;
}

.grow-enter-active {
    transition: all 0.2s ease-out;
}

.grow-leave-active {
    transition: all 0.2s ease-in;
}

.grow-enter,
.grow-leave-to {
    max-height: 0;
    opacity: 0;
}

::v-deep .button-label {
    background-color: white;
}

@include small-desktop() {
    .insurance-page {
        @include site-section-sm-desktop();
    }
}

@include tablet {
    .insurance-label {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        text-align: left;
    }

    .secondary-insurance-form {
        max-height: none;
    }

    .insurance-row {
        flex-wrap: wrap;
    }

    .secondary-insurance-error-notice {
        margin-left: 0;
    }
}

@include sm {
    .insurance-secondary-question .insurance-btn:last-of-type {
        margin-right: 0;
    }
}
</style>
