<template>
    <div class="btn-row-container">
        <div class="btn-row">
            <button v-if="!hideBackButton" class="back-btn" @click="$emit('backClick')">
                <img src="../../assets/back_arrow.png">
                <p>Back</p>
            </button>
            <button :disabled="disableContinue" class="submit-btn" @click="$emit('continueClick')">
                <p class="submit-btn-text">
                    {{ btnString }}
                </p>
                <img class="submit-btn-image" src="../../assets/btn_arrow.png">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "BtnRow",
    props: {
        btnString: {
            required: false,
            type: String,
            default: "Continue"
        },
        disableContinue: {
            required: false,
            type: Boolean,
            default: false
        },
        hideBackButton: {
            required: false,
            type: Boolean,
            deafult: false
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-row-container {
    @include flex-center-horizontal();
    padding-bottom: 100px;
}

.btn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 474px;
    width: 100%;
}

.back-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 62px;
    padding: 0;
    background: none;
    font-family: "Raleway", sans-serif;
    font-size: 0.9em;
    font-weight: 900;
    color: $genesight-blue;
    outline: none;
    border: none;
    align-self: center;

    img {
        height: 18px;
    }

    &:hover {
        cursor: pointer;
    }
}

@include sm {
    .btn-row {
        justify-content: center;
    }

    .back-btn {
        display: none;
    }
}
</style>
