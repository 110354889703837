<template>
    <div class="demographic-page">
        <form-validate 
            class-name="demographic-form" 
            id-name="demographicForm"
            @no-errors-callback="removeErrorsBanner"
            @invalid-callback="showFormErrors"
            @valid-callback="goToInsurancePage"
        >
            <full-name 
                class="demographic-full-name" 
                error-string="Invalid Name"
                :first-name="this.$store.getters.patientFirstname"
                :last-name="this.$store.getters.patientLastName"
                @firstNameInput="setFirstName"
                @lastNameInput="setLastName"
            />
            <dob-comp 
                store-setting-string="setPatientDob"
                store-getter-string="patientDob"
            />
            <div v-radio-button-validation="{required: true, name: 'demographicSexSelection'}" class="input-row demographic-sex-row">
                <label class="input-label label">
                    Sex
                </label>
                <sex-tooltip class="mobile-tooltip" />
                <div class="sex-button-wrapper">
                    <button-radio-input id="demographicMale" 
                                        v-model="sex" class="input-mgr" 
                                        :v-model-property="sex" 
                                        name="demographicSexSelection" 
                                        label="M" 
                                        tabindex="0"
                    />
                    <button-radio-input id="secondaryInsuranceNo" 
                                        v-model="sex" 
                                        :v-model-property="sex" 
                                        name="demographicSexSelection" 
                                        label="F" 
                                        tabindex="-1"
                    />
                </div>
                <sex-tooltip class="desktop-tooltip" />
                <error-labels invalid-string="Invalid Sex" />
            </div>
            <address-section />
            <div v-container-validation class="input-row">
                <label class="input-label label">
                    Phone
                </label>
                <input 
                    ref="phoneField" 
                    v-model="phone"
                    v-phone-validation="{required: phoneRequired}" 
                    v-mask="'(###) ###-####'" 
                    class="text-input" 
                    name="phone"
                    autocomplete="phone"
                    type="text"
                    :placeholder="phonePlaceholder"
                    @keyup="checkToShowMobilePhoneField"
                >
                <error-labels invalid-string="Invalid Phone" />
            </div>
            <transition 
                name="fade"
                @before-leave="beforeMobilePhoneLeave"
                @after-enter="afterMobilePhoneEnter"
            >
                <div 
                    v-show="showMobilePhone" 
                    v-radio-button-validation="{required: showMobilePhone, name: 'mobilePhoneSelection'}" 
                    class="input-row demographic-mobile-row"
                >
                    <label class="input-label label">
                        Is this a mobile <br class="no-mobile"> phone?
                    </label>
                    <button-radio-input id="mobileYes" 
                                        v-model="phoneType" 
                                        class="input-mgr" 
                                        :v-model-property="phoneType" 
                                        name="mobilePhoneSelection" 
                                        label="Yes" 
                                        tabindex="0"
                    />
                    <button-radio-input id="mobileNo" 
                                        v-model="phoneType" 
                                        :v-model-property="phoneType" 
                                        name="mobilePhoneSelection" 
                                        label="No" 
                                        tabindex="-1"
                    />
                    <error-labels invalid-string="Invalid Answer" />
                </div>
            </transition>
            <div v-container-validation class="input-row">
                <label class="input-label label">
                    Email Address
                </label>
                <input v-model="email" 
                       v-email-validation="{required: emailRequired, maxLength: 100}" 
                       class="text-input demographic-long-input" 
                       name="email"
                       autocomplete="email"
                       type="text" 
                       :placeholder="emailRequired ? '' : '(optional)'"
                >
                <error-labels invalid-string="Invalid Email Address" />
            </div>
            <div class="input-row demographic-submit-row">
                <label class="input-label label" />
                <button class="submit-btn" type="submit">
                    <p class="submit-btn-text">
                        Continue
                    </p>
                    <img class="submit-btn-image" src="../assets/btn_arrow.png">
                </button>
            </div>
        </form-validate>
    </div>
</template>

<script>
import CommonFunc from "../scripts/common.js";
import ButtonRadioInput from "../components/ui/ButtonRadioInput.vue";
import ErrorLabels from "../components/layout/Demographic/ErrorLabels.vue";
import AddressSection from "../components/layout/Demographic/Address.vue";
import SexTooltip from "../components/layout/Demographic/SexTooltip.vue";
import FullName from "../components/layout/Demographic/FullNameInputs.vue";
import DobComp from "../components/layout/Demographic/DobInput.vue";
import BasePage from "./BasePage.vue";

export default {
    name: "Demographic",
    components: {
        ButtonRadioInput,
        ErrorLabels,
        AddressSection,
        FullName,
        DobComp,
        SexTooltip
    },
    extends: BasePage,
    data() {
        return {
            showMobilePhone: false,
            mobilePhoneClasslist: [],
            initialPhoneSupplied: false,
            initialEmailSupplied: false
        };
    },
    computed: {
        sex: {
            get() {
                return CommonFunc.getFormattedSex(this.$store.getters.patientSex);
            },
            set(value) {
                this.$store.dispatch("setPatientSex", CommonFunc.getSexString(value));
            }
        },
        phone: {
            get() {
                return this.$store.getters.patientPhone;
            },
            set(value) {
                this.$store.dispatch("setPatientPhone", value);
            }
        },
        email: {
            get() {
                return this.$store.getters.patientEmail;
            },
            set(value) {
                this.$store.dispatch("setPatientEmail", value);
            }
        },
        phoneType: {
            get() {
                const storeValue = this.$store.getters.patientPhoneType;

                if(storeValue === "Mobile"){
                    return "Yes";
                }
                else if(storeValue === "NotMobile"){
                    return "No";
                }
                else {
                    return "";
                }
            },
            set(value) {
                let returnValue = "Unknown";

                if(value === "Yes") {
                    returnValue = "Mobile";
                }
                else if(value === "No") {
                    returnValue = "NotMobile";
                }
                
                this.$store.dispatch("setPatientPhoneType", returnValue);
            }
        },
        phonePlaceholder() {
            return this.checkForOptionalPhone() ? "(optional)" : "";
        },
        phoneRequired() {
            return !this.checkForOptionalPhone();
        },
        emailRequired() {
            return this.checkForOptionalPhone() && this.initialEmailSupplied;
        }
    },
    watch: {
        showMobilePhone(newValue, oldValue) {
            if(newValue !== oldValue && !newValue) {
                this.$store.dispatch("setPatientPhoneType", null);
            } 
        }
    },
    created() {
        if(CommonFunc.doesExistAndNotEmpty(this.$store.getters.patientPhone)) {
            const strippedPhone = this.$store.getters.patientPhone.replace(/\D/g,"");
            if(strippedPhone.length >= 10) {
                this.showMobilePhone = true;
                this.initialPhoneSupplied = true;
            }
        }
        
        if(CommonFunc.doesExistAndNotEmpty(this.$store.getters.patientEmail)) {
            this.initialEmailSupplied = true;
        }
    },
    methods: {
        goToInsurancePage() {
            this.$store.dispatch("setDemographicStepComplete", true);
            this.$store.dispatch("setShowErrorMessage", false);
            this.$router.push("insurance/primary");
        },
        showFormErrors() {
            CommonFunc.showFormErrors(this);
        },
        removeErrorsBanner() {
            CommonFunc.removeErrorBanner(this);
        },
        checkToShowMobilePhoneField() {
            const strippedValue = this.$refs.phoneField.value.replace(/\D/g,"");
            if(strippedValue.length >=10) {
                this.showMobilePhone = true;
            }
            else if(strippedValue <= 0) {
                this.$refs.phoneField.value = "";
                this.phone = "";
                this.showMobilePhone = false;
            }
        },
        afterMobilePhoneEnter(el) {
            this.mobilePhoneClasslist.forEach(storedClass => {
                let foundMatch = false;

                el.classList.forEach(currentClass => {
                    if(currentClass === storedClass) {
                        foundMatch = true;
                    }
                });

                if(!foundMatch) {
                    el.classList.add(storedClass);
                }
            });

        },
        beforeMobilePhoneLeave(el) {
            this.mobilePhoneClasslist = Array.prototype.slice.call(el.classList, 0);
        },
        checkForOptionalPhone() {
            if(this.initialPhoneSupplied) {
                return false;
            }
            else if(!this.initialPhoneSupplied && this.initialEmailSupplied) {
                return true;
            }
            else {
                const emailSupplied = CommonFunc.doesExistAndNotEmpty(this.email);
                if(emailSupplied && !this.showMobilePhone) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        setFirstName(value) {
            this.$store.dispatch("setPatientFirstName", value);
        },
        setLastName(value){
            this.$store.dispatch("setPatientLastName", value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/inputs.scss";

.demographic-page {
    @include site-section();
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
}

.demographic-form {
    @include desktop-form-container();
}

.sex-button-wrapper {
    display: flex;
    justify-content: flex-start;
}
.desktop-tooltip {
    max-width: 300px;
    margin-left: 20px;
}

.mobile-tooltip {
    display: none;
}

.demographic-row-label {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0;
}

.demographic-prefilled {
    width: 75px;
    border: 0;
    background: transparent;
    color: $medium-gray-text-color;
    font-size: 1.125em;
}

::v-deep .demographic-short-input {
    width: 158px;
}

::v-deep .demographic-long-input {
    width: 499px;
}

@include small-desktop {
    .demographic-page {
        @include site-section-sm-desktop();
    }
}

@include tablet {
    .demographic-mobile-row {
        flex-direction: row;
        flex-wrap: wrap;

        .input-label {
            width: 100%;
        }
    }

    ::v-deep .demographic-long-input {
        width: 100%;
    }

    ::v-deep .demographic-state-input {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .submit-btn {
        margin-bottom: 100px;
    }

    .demographic-sex-row {
        flex-direction: row;
        flex-wrap: wrap;
        .input-label {
            width: 30px;
            min-width: 30px;
            margin-right: 10px;
            margin-bottom: 15px;
        }
    }
    .desktop-tooltip {
        display: none;
    }
    .mobile-tooltip {
        display: block;
        width: 20px;
        margin-right: calc(100% - 75px);
    }
    ::v-deep .button-radio .button-label {
        width: 135px;
    }
}

@include sm {
    .demographic-submit-row {
        justify-content: center;
        align-items: center;

        .input-label {
            display: none;
        }
    }
}
</style>
