import moment from "moment";

// "pig" = Patient Information Gathering

export default {
    state: {
        pigToken: null,
        pigOrigin: null,
        pigDob: null,
        tokenExpiration: null,
        showPigValidationError: false,
        maxPigValidationAttempts: 5,
        currentPigValidationAttempts: 0,
        pigValidationErrorMessageText: "",
        isManualInsurance: false
    },
    getters: {
        pigToken: state => {
            return state.pigToken;
        },
        pigOrigin: state => {
            if(state.pigOrigin == "NA"){
                return "";
            }
            else{
                var origin = state.pigOrigin;
                state.pigOrigin = "NA";
                return origin;
            }
        },
        pigDob: state => {
            return state.pigDob;
        },
        tokenExpiration: state => {
            return state.tokenExpiration;
        },
        showPigValidationError: state => {
            return state.showPigValidationError;
        },
        maxPigValidationAttempts: state => {
            return state.maxPigValidationAttempts;
        },
        currentPigValidationAttempts: state => {
            return state.currentPigValidationAttempts;
        },
        pigValidationErrorMessageText: state => {
            return state.pigValidationErrorMessageText;
        },
        isManualInsurance: state => {
            return state.isManualInsurance;
        }
    },
    mutations: {
        setPigToken(state, {token, origin}) {
            state.pigToken = token;
            if(state.pigOrigin != "NA"){
                state.pigOrigin = origin;
            }
        },
        setPigDob(state, value) {
            state.pigDob = value;
        },
        setTokenExpiration(state, {tokenExpiration}) {
            const expireDate = moment(tokenExpiration).format("MMMM DD, YYYY");
            state.tokenExpiration = expireDate;
        },
        showPigValidationError(state, value) {
            state.showPigValidationError = value;
        },
        setPigValidationAttempts(state, {maxAttempts, attempts}) {
            state.maxPigValidationAttempts = maxAttempts;
            state.currentPigValidationAttempts = attempts;
        },
        pigValidationErrorMessageText(state, value) {
            state.pigValidationErrorMessageText = value;
        },
        setIsManualInsurance(state, value) {
            state.isManualInsurance = value;
        }
    },
    actions: {
        setPigToken(context, {token, origin}) {
            context.commit("setPigToken", {token, origin});
        },
        setPigDob(context, value) {
            context.commit("setPigDob", value);
        },
        setTokenExpiration(context, {tokenExpiration}) {
            context.commit("setTokenExpiration", {tokenExpiration});
        },
        showPigValidationError(context, value) {
            context.commit("showPigValidationError", value);
        },
        setPigValidationAttempts(context, {maxAttempts, attempts}) {
            context.commit("setPigValidationAttempts", {maxAttempts, attempts});
        },
        pigValidationErrorMessageText(context, value) {
            context.commit("pigValidationErrorMessageText", value);
        },
        setIsManualInsurance(context, value) {
            context.commit("setIsManualInsurance", value);
        }
    }
};
