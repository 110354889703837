<template>
    <div class="survey-two">
        <survey-page-1 
            v-if="$store.getters.lastPagesSaved === 0"
            title="How are you doing?"
            :is-survey1="false"
        >
            <error-bubble />
        </survey-page-1>
        <survey-page-2 
            v-if="$store.getters.lastPagesSaved === 1"
            :is-survey1="false"
        >
            <error-bubble />
        </survey-page-2>
        <survey-thank-you v-if="$store.getters.lastPagesSaved === 2" />
    </div>
</template>

<script>
import SurveyPage1 from "../../components/layout/Survey/SurveyPage1.vue";
import SurveyPage2 from "../../components/layout/Survey/SurveyPage2.vue";
import SurveyThankYou from "../../components/layout/Survey/SurveyThankYou.vue";
import ErrorBubble from "../../components/layout/Survey/ErrorBubble.vue";

export default {
    name: "SurveyTwo",
    components: {
        SurveyPage1,
        SurveyPage2,
        SurveyThankYou,
        ErrorBubble
    }
};
</script>

<style lang="scss" scoped>
    .survey-two {
        @include site-section();
        display: block;
        margin-bottom: 20px;
    }
    @include small-desktop {
        .survey-two {
            @include site-section-sm-desktop();
        }
    }
    @include tablet {
        .survey-two {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
</style>
