<template>
    <div>
        <div v-if="$store.getters.showSurvey1ErrorBubble" class="error-bubble">
            <font-awesome-icon class="error-bubble-icon" icon="fa-solid fa-circle-exclamation" />
            <p class="error-bubble-text">
                Missing Information. Please complete the section below.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorBubble"
};
</script>

<style lang="scss" scoped>
    .error-bubble {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid $error-border-color;
        background: $error-background-color;
        border-radius: 5px;
        padding: 12px 15px;
        margin-top: 10px;
    }

    .error-bubble-icon {
        color: $error-red;
        margin-right: 18px;
    }

    .error-bubble-text {
        font-size: 0.8em;
        color: $error-red;
    }
</style>
