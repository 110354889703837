export default {
    state: {
        medicationList: [],
        previousMedications: [],
        frequencyOfEpisodes: "Single",
        previousMedsCheck: false,
        hasAnxiety: null
    },
    getters: {
        medicationList: state => {
            return state.medicationList;
        },
        previousMedications: state => {
            return state.previousMedications;
        },
        previousMedicationsForPost: state => {
            let filteredItems = [];
            state.previousMedications.forEach((item)=> {
                filteredItems.push(item.name);
            });

            return filteredItems;
        },
        frequencyOfEpisodes: state => {
            return state.frequencyOfEpisodes;
        },
        getPreviousMedsCheck: state => {
            return state.previousMedsCheck;
        },
        hasAnxiety: state => {
            return state.hasAnxiety;
        }
    },
    mutations: {
        setMedicationList(state, meds) {
            state.medicationList = meds;
        },
        setPreviousMedications(state, meds) {
            state.previousMedications = meds;
        },
        setFrequencyOfEpisodes(state, value) {
            state.frequencyOfEpisodes = value;
        },
        setPreviousMedsCheck(state, value) {
            state.previousMedsCheck = value;
        },
        setHasAnxiety(state, value) {
            state.hasAnxiety = value;
        }
    },
    actions: {
        setMedicationList(context, meds) {
            context.commit("setMedicationList", meds);
        },
        setPreviousMedications(context, meds) {
            context.commit("setPreviousMedications", meds);
        },
        setFrequencyOfEpisodes(context, value) {
            context.commit("setFrequencyOfEpisodes", value);
        },
        setPreviousMedsCheck(context, value) {
            context.commit("setPreviousMedsCheck", value);
        },
        setHasAnxiety(context, value) {
            context.commit("setHasAnxiety", value);
        }
    }
};
