<template>
    <div class="survey-page-1">
        <slot />
        <h3 class="survey-page-title">
            {{ title }}
        </h3>
        <div class="survey-gray-box">
            <p class="survey-gray-box-text">
                Patient Health Questionnaire (PHQ-9)
            </p>
        </div>
        <div class="phq9-table" />
        <div class="table-row">
            <p class="table-row-question">
                Over the last 2 weeks, how often have you been bothered by any of the following problems?
            </p>
            <ul class="table-rating-list">
                <li>
                    Rating Scale:
                </li>
                <li>
                    0 = Not at all
                </li>
                <li>
                    1 = Several days
                </li>
                <li>
                    2 = More than half the days
                </li>
                <li>
                    3 = Nearly every day
                </li>
            </ul>
        </div>
        <div 
            v-for="(item, index) in questions" 
            :key="index"
        >
            <hr class="table-row-hr">
            <div class="table-row">
                <p class="survey-question">
                    {{ item }}
                </p>
                <genesight-button-row 
                    v-multi-ref:tableQuestionRow 
                    class="table-row-radio-buttons"
                    hide-labels="false"
                    slim="false" 
                />
            </div>
        </div>
        <hr class="table-row-hr">
        <div v-if="showSuicideWarning" class="suicide-container">
            <div class="suicide-image-container">
                <hand-shake class="icon-svg" />
                <p class="mobile-top-message">
                    <b>You are not alone.</b> Confidential help is available for <b>free</b>.
                </p>
            </div>
            <div class="suicide-text-container">
                <p><b>You are not alone.</b> Confidential help is available for <b>free</b>.</p>
                <ul class="suicide-warning-list">
                    <li>
                        contact your healthcare provider
                    </li>
                    <li>
                        call the National Suicide Prevention Lifeline at <a href="tel:+1-800-273-8255">1.800.273.8255</a>
                    </li>
                    <li>
                        text the Crisis Text Line by sending <b>HOME to 741741</b>
                    </li>
                </ul>
                <p>We do not immediately notify your healthcare provider regarding your response.</p>
            </div>
        </div>
        <div v-if="!validMeds" class="error-bubble meds-error">
            <font-awesome-icon class="error-bubble-icon" icon="fa-solid fa-circle-exclamation" />
            <p class="error-bubble-text">
                Missing Information. Please complete the section below.
            </p>
        </div>
        <h3 class="survey-page-title medications-title">
            Your medications
        </h3>
        <div v-for="index in numMedicationsRow" 
             :key="'med-row-'+index" 
             class="table-row table-row--tabletMargin"
             :class="{error: !validMeds}"
        >
            <p :class="index != 1 ? 'survey-question hidden tablet-nospace' : 'survey-question'">
                List the total <b>daily dose</b> of mental health medications that you are currently prescribed and taking.
            </p>
            <your-medications-row v-if="index === 1" class="table-row-radio-buttons medications-row" :is-disabled="noPreviousMedsChecked" :has-error="!validMeds" @inputChange="yourMedicationRowHandler($event, index)" />
            <your-medications-row v-else class="table-row-radio-buttons medications-row" :is-disabled="noPreviousMedsChecked" @inputChange="yourMedicationRowHandler($event, index)" />
        </div>
        <div v-if="noPreviousMedsChecked"
             :key="'med-row-'+index"
             class="table-row table-row--tabletMargin"
        >
            <p class="survey-question">
                List the total <b>daily dose</b> of mental health medications that you are currently prescribed and taking.
            </p>
            <your-medications-row class="table-row-radio-buttons medications-row" :is-disabled="noPreviousMedsChecked" />
        </div>
        <div class="table-row checkbox-row">
            <p class="survey-question" />
            <div class="checkbox-wrapper">
                <input id="noMedications" v-model="noPreviousMedsChecked" type="checkbox">
                <label for="noMedications" class="medication-check-label">
                    Not currently prescribed or taking mental health medications
                </label>
            </div>
        </div>
        <div class="table-row">
            <p class="survey-question" />
            <button class="cy-add-med-btn add-med-btn table-row-radio-buttons" @click="numMedicationsRow++">
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
                Add medication
            </button>
        </div>
        <hr class="table-row-hr">
        <div class="agree-btn-container">
            <button class="submit-btn survey-submit-btn cy-agree-btn" type="button" @click="submitPage">
                <p class="submit-btn-text">
                    Submit
                </p>
                <img class="submit-btn-image" src="../../../assets/btn_arrow.png">
            </button>
        </div>
        <p class="bottom-note">
            <b>Note:</b> Your responses above are not shared with your healthcare provider.
        </p>
    </div>
</template>

<script>
import YourMedicationsRow from "./YourMedicationsRow.vue";
import Common from "../../../scripts/common";
import HandShake from "../../../assets/handshake.svg";
import SurveyHttp from "../../../scripts/http/survey-http.js";

export default {
    name: "SurveyPage1",
    components: {
        YourMedicationsRow,
        HandShake
    },
    props: {
        title: {
            required: false,
            type: String,
            default: "How are you doing?"
        },
        isSurvey1: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            questions: [
                "Little interest or pleasure in doing things", 
                "Feeling down, depressed, or hopeless?",
                "Trouble falling or staying asleep, or sleeping too much?",
                "Feeling tired or having little energy?",
                "Poor appetite or overeating?",
                "Feeling bad about yourself - or that you are a failure or have let yourself or your family down?",
                "Trouble concentrating on things, such as reading the newspaper or watching television?",
                "Moving or speaking so slowly that other people could have noticed?",
                "Thoughts that you would be better off dead, or of hurting yourself in some way?"
            ],
            options: ["Not at all", "Several Days", "More than half the days", "Nearly every day"],
            mobileOptions: ["0", "1", "2", "3"],
            numMedicationsRow: 1,
            jsonData: {phq9: {}},
            validState: false,
            validMeds: true,
            showSuicideWarning: false,
            noPreviousMedsChecked: false
        };
    },
    watch: {
        noPreviousMedsChecked: function(val, oldVal) {
            if (val) {
                // user chekced no previous meds...so clear json data and set med rows to 0
                this.removeMedications(this.jsonData);
                this.numMedicationsRow = 0;
            } else {
                // user unchecked no previous meds...so add 1 row for meds
                this.numMedicationsRow = 1;
            }
        }
    },
    mounted() {
        this.questions.forEach((item, index) => {
            this.$refs.tableQuestionRow[index].options = this.$data.options;
            this.$refs.tableQuestionRow[index].mobileOptions = this.$data.mobileOptions;
            this.$refs.tableQuestionRow[index].addEventListener("optionSelected", (event) => {
                this.$refs.tableQuestionRow[index].invalid = false;
                this.jsonData.phq9[item] = {
                    "answer": event.detail,
                    "index": index
                };
                if(index == 8) {
                    this.showSuicideWarning = event.detail != "Not at all";
                }
            }); 
        });
    },
    methods: {
        submitPage() {
            this.validatePage();
            if(this.validState && this.validMeds) {
                this.$store.dispatch("setShowStartupLoader", true);
                this.jsonData.noPreviousMedsChecked = this.noPreviousMedsChecked;
                SurveyHttp.postSurveyPage(this.jsonData, 1).then(() => {
                    this.setSavedPages();
                }).catch(()=> {
                    this.$router.push("/error");
                }).finally(()=> {
                    this.$store.dispatch("setShowStartupLoader", false);
                });
            }
        },
        yourMedicationRowHandler(value, index) {
            let key = "medication" + index;
            this.jsonData[key] = value;
        },
        removeMedications(json) {
            for (var i = 1; i <= this.numMedicationsRow; i++) {
                delete json["medication" + i];
            }
        },
        validatePage() {
            let excludedIndexs = Common.getExcludedSurveyIndexes(this.questions, this.jsonData.phq9);

            //loop exluded and mark them as invalid
            excludedIndexs.forEach((index)=> {
                this.$refs.tableQuestionRow[index].invalid = true;
            });

            this.validState = excludedIndexs.length == 0;
            this.$store.dispatch("setShowSurvey1ErrorBubble", !this.validState);

            if (this.noPreviousMedsChecked) {
                this.validMeds = true;
            } else {
                // validate meds
                this.validMeds = false;
                for (var i = 1; i <= this.numMedicationsRow; i++) {
                    let value = this.jsonData["medication" + i];
                    if (value && value["Mental Health Medication"]) {
                        this.validMeds = true;
                    }
                }
            }

            if(!this.validState) {
                Common.scrollToErrorMessage(".step-instructions-text");
            } else if (!this.validMeds) {
                Common.scrollToErrorMessage(".meds-error");
            }
        },
        setSavedPages() {
            let keys = Object.keys(this.jsonData).filter(x => /^medication/.test(x));
            // If medications answered or this is not survey 1
            if(keys.length > 0 || !this.isSurvey1) {
                this.$store.dispatch("setPagesSaved", 1);
            }
            else {
                SurveyHttp.postSurveyPage({}, 2);
                this.$store.dispatch("setPagesSaved", 2);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_survey.scss";

.phq9-table {
    display: flex;
    flex-direction: column;
}

.table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 90px;
    box-sizing: border-box;
}

.table-row.checkbox-row {
    min-height: 40px;
}

.table-row-question {
    font-size: 1.1em;
}

.table-row-radio-buttons {
    max-width: 670px;
    width: 100%;
}

.table-rating-list {
    display: none;
}

.survey-question {
    max-width: 420px;
    width: 100%;
}

.error .survey-question {
    color: #ef4034 !important;
}

.table-row-hr {
    height: 1px;
    background-color: #D0D3DA;
    border: none;
}

.medications-title {
    margin-top: 30px;
}

.survey-submit-btn {
    margin: 60px auto 0 auto;
    width: 190px;
}

.bottom-note {
    margin-top: 30px;
    margin-bottom: 90px;
    font-size: .9em;
    color: $survey-instructions-color;
}

.hidden {
    visibility: hidden;
}

.suicide-container{
    background-color: $step-instructions-bg;
    display: flex;
    flex-direction: row;
    line-height: 1.5em;

    .suicide-image-container{
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        .icon-svg {
            height: 60px;
            margin: 10px;
        }
        .mobile-top-message {
            display: none;
        }
    }

    .suicide-text-container{
        display: flex;
        flex-direction: column;
        margin: 15px 10px;
        max-width: 700px;
        p:first-of-type {
            margin-bottom: 0.5em;
        }
        p:last-of-type {
            margin-top: 0.5em;
            font-size: 0.8em;
            font-weight: bold;
        }
    }

    .suicide-warning-list {
        list-style: inside;
    }

}

.error-bubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $error-border-color;
    background: $error-background-color;
    border-radius: 5px;
    padding: 12px 15px;
    margin-top: 40px;
}

.error-bubble-icon {
    color: $error-red;
    margin-right: 18px;
}

.error-bubble-text {
    font-size: 0.8em;
    color: $error-red;
}

.checkbox-wrapper {
    max-width: 670px;
    width: 100%;
}

@include small-desktop {
    .table-row-radio-buttons {
        max-width: 360px;
        width: 100%;
    }

    .medications-row {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

@include tablet {
    .tablet-nospace {
        display: none;
    }

    .table-row-radio-buttons {
        max-width: none;
        width: 100%;
    }

    .table-row {
        height: auto;
    }

    .table-row-question {
        font-size: 1em;
    }

    .table-row {
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .table-row--tabletMargin {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .table-row-radio-buttons {
        width: 100%;
        min-width: 100px;
    }

    .table-row-hr {
        display: none;
    }

    .table-rating-list {
        display: block;
        margin-top: 20px;
        color: $survey-instructions-color;
    }

    .survey-question {
        padding-bottom: 5px;
    }

    .medications-title {
        margin-top: 30px;
    }

    .medications-row {
        padding: 0;
    }

    .add-med-btn {
        text-align: center;
    }

    .suicide-container {
        border: none;
        flex-direction: column;
        .suicide-image-container{
            .mobile-top-message {
                display: block;
                margin: 10px 0;
            }
        }
        .suicide-text-container{
            margin-top: 0;
            p:first-of-type{
                display: none;
            }
        }
    }
}
</style>
