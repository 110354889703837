<template>
    <div class="complete-page">
        <h1 class="complete-header">
            Thank you
        </h1>
        <h2 class="complete-sub-header">
            Your information was successfully sent to your healthcare provider
        </h2>
        <div class="complete-steps">
            <div class="complete-step-row">
                <div class="complete-step-row-left">
                    <div class="complete-step-image">
                        <test-icon />
                    </div>
                    <div class="complete-step-left-line" />
                </div>
                <div class="complete-step-row-right">
                    <p class="complete-step-row-text">
                        When your results are ready, your provider will be notified.
                    </p>
                </div>
            </div>
        </div>
        <div class="for-patients">
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            For more details about the GeneSight test, visit <a href="https://genesight.com/for-patients/" target="_blank">genesight.com/for-patients</a>.
        </div>
    </div>
</template>

<script>
import TestIcon from "../assets/icon_results.svg";
import BasePage from "./BasePage.vue";

export default {
    name: "Complete",
    components: {
        TestIcon
    },
    extends: BasePage,
    data: () => {
        return {
            consentChecked: false,
            patientConsented: false
        };
    },
    beforeRouteLeave(to, from, next) {
        next(false);
    },
    mounted() {
        localStorage.clear();
    }
};
</script>

<style lang="scss" scoped>
.complete-page {
    @include site-section();
    padding: 80px 90px;
    min-height: calc(100vh - 250px);
}

.complete-header {
    font-size: $large-header-size;
}

.complete-sub-header {
    font-size: 1.625em;
}

.complete-steps {
    margin-top: 44px;
}

.complete-step-row {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.complete-step-row-left {
    position: relative;
}

.complete-step-left-line {
    display: none;
    z-index: 0;
}

.complete-step-image {
    @include flex-center-vertical();
    width: $complete-step-img-size;
    height: $complete-step-img-size;
    min-width: $complete-step-img-size;
    min-height: $complete-step-img-size;
    z-index: 10;
}

.complete-step-row-header {
    font-family: "Lato Bold", "Lato";
    font-size: 1em;
    font-weight: 700;
}

.complete-step-row-right {
    width: 600px;
    margin-top: $complete-step-img-margin-size;
    margin-left: $complete-step-img-margin-size;
    margin-bottom: 34px;
}

.complete-step-row-text {
    font-family: "Lato";
    line-height: 24px;
}

.complete-consent-question {
    margin-left: $complete-left-margin;
    margin-bottom: $complete-step-img-margin-size;
}

.consent-btn {
    width: 385px;
    height: 40px;
    margin-left: $complete-left-margin;
    margin-bottom: $complete-step-img-margin-size;
}

.consent-notice-text {
    width: 391px;
    font-size: .625em;
    margin-left: $complete-left-margin;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    color: $medium-gray-text-color;
}

.complete-consent-granted {
    margin-left: $complete-left-margin;
}

.complete-constent-granted-header {
    font-family: "Lato Bold", "Lato", Arial;
    color: $genesight-green;
    font-size: 1.5em;
    font-weight: 700;
}

.complete-consent-granted-text {
    line-height: 24px;
}

@include tablet {
    .complete-page {
        padding: 20px 21px;
        min-height: calc(100vh - 220px);
    }

    .consent-btn {
        width: 100%;
        font-size: 1em;
        margin-left: 0;
    }

    .consent-notice-text {
        width: 100%;
        margin-left: 0;
        line-height: 16px;
    }

    .complete-step-row-left {
        display: none;
    }

    .complete-step-left-line {
        display: block;
        position: absolute;
        left: calc(50% - 2px);
        width: 1px;
        height: 100%;
        border-left: 4px dashed $medium-gray-text-color;
    }

    .complete-step-row-right {
        margin-left: 0;
    }
}

@include sm {
    .complete-page {
        min-height: calc(100vh - 210px);
    }
}

@media (max-width: 488px){
    .complete-page {
        min-height: calc(100vh - 231px);
    }   
}

@media (max-width: 367px){
    .complete-page {
        min-height: calc(100vh - 249px);
    }   
}
</style>
