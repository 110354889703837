import { render, staticRenderFns } from "./ButtonRadioInput.vue?vue&type=template&id=5d5f05fd&"
import script from "./ButtonRadioInput.vue?vue&type=script&lang=js&"
export * from "./ButtonRadioInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.registry.npmjs.org/vue-loader/15.9.8_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports