<template>
    <div class="slider">
        <div class="slider-bar-track-container slider-flex">
            <div class="slider-ticks">
                <div class="slider-tick slider-tick-template" />
            </div>
        </div>
        <div class="slider-bar-thumb-container slider-flex">
            <div v-hammer:pan.horizontal="thumbDrag" 
                 v-hammer:panend="snapThumbToClosestTick" 
                 class="slider-thumb"
                 :pan-options="{ direction: 'horizontal' }" 
                 style="left: 0px"
            >
                <div class="slider-thumb-tooltip">
                    More than half the days
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Common from "../../scripts/common.js";

export default {
    name: "MyriadSlider",
    props: {
        ticks: {
            default: () => {
                return [];
            },
            type: Array,
            requried: true
        },
        tickIndex: {
            default: null,
            type: Number,
            required: false
        }
    },
    data: ()=> {
        return {
            isDragging: false,
            lastPosX: 0,
            maxLeftPostion: 0,
            maxRightPosition: 0,
            resizeTimer: null
        };
    },
    watch: {
        tickIndex(newVal, oldVal) {
            if(newVal != oldVal) {
                this.setThumbToSetTick(newVal);
            }
        }
    },
    mounted() {
        this.setTicks();
        this.setMaxDragLimits();
        window.addEventListener("resize", this.onResize);

        if(this.tickIndex) {
            this.setThumbToSetTick(this.tickIndex);
        }
    },
    methods: {
        setTicks() {
            var ticks = this.ticks;
            var sliderTickTemplate = this.$el.querySelector(".slider-tick-template");
            for(var i = 0; i < ticks.length; i++) {
                var sliderTicksEle = this.$el.querySelector(".slider-ticks");
                var tickElement = sliderTickTemplate.cloneNode(true);
                
                tickElement.className = "slider-tick " + ticks[i].class;
                tickElement.setAttribute("data-value", ticks[i].value);
                tickElement.setAttribute("data-text", ticks[i].text);
                tickElement.setAttribute("data-index", i);

                sliderTicksEle.appendChild(tickElement);
            }

            sliderTickTemplate.parentNode.removeChild(sliderTickTemplate);
        },
        setMaxDragLimits() {
            var track = this.$el.querySelector(".slider-ticks");
            var trackPositioning = track.getBoundingClientRect();
            var center = trackPositioning.left + (trackPositioning.width / 2);

            this.maxLeftPostion =  -(Math.hypot(center - trackPositioning.left));
            this.maxRightPosition = Math.hypot(center - (trackPositioning.left + trackPositioning.width));
        },
        thumbDrag(event) {
            var thumb = event.target;
            var posX;

            if(!this.isDragging) {
                this.isDragging = true;
                this.lastPosX = parseInt(thumb.style.left);
                this.showTooltip();
            }

            posX = event.deltaX + this.lastPosX;

            if(posX < this.maxLeftPostion) {
                posX = this.maxLeftPostion;
            }
            else if(posX > this.maxRightPosition) {
                posX = this.maxRightPosition;
            }

            this.setHoverValue();

            thumb.style.left = posX + "px";
        },
        setHoverValue() {
            var closestTickInfo = this.getClosestTick();
            this.$el.querySelector(".slider-thumb-tooltip").innerHTML = closestTickInfo.closestTick.getAttribute("data-text");
        },
        showTooltip() {
            Common.addClass(this.$el.querySelector(".slider-thumb-tooltip"), "slider-thumb-tooltip--visible");
        },
        hideTooltip() {
            Common.removeClass(this.$el.querySelector(".slider-thumb-tooltip"), "slider-thumb-tooltip--visible");
        },
        getClosestTick() {
            var ticks = this.$el.querySelectorAll(".slider-tick");
            var thumb = this.$el.querySelector(".slider-thumb");
            var closestItem = null;
            var closestDistance = null;
            var closestDistanceDirection = null;
            var moveDistance;

            Common.forEach(ticks, (index, item) => {
                var tickCenter = Common.getElementCenterPosition(item);
                var thumbCenter = Common.getElementCenterPosition(thumb);
                var distance = Math.hypot(tickCenter.x - thumbCenter.x);
                var distanceWithDirection = tickCenter.x < thumbCenter.x ? -Math.abs(distance) : distance;

                if(distance < closestDistance || closestDistance === null) {
                    closestItem = item;
                    closestDistance = distance;
                    closestDistanceDirection = distanceWithDirection;
                }
            });

            moveDistance = parseInt(thumb.style.left) + closestDistanceDirection;

            return {
                moveDistance: moveDistance,
                closestTick: closestItem
            };
        },
        snapThumbToClosestTick() {
            var thumb = this.$el.querySelector(".slider-thumb");
            var closestTickInfo = this.getClosestTick();
            var moveDistance = closestTickInfo.moveDistance;
            var closestItem = closestTickInfo.closestTick;

            this.hideTooltip();
            this.isDragging = false;

            thumb.style.left = moveDistance + "px";
            this.emitSelectedSliderOption(closestItem);
        },
        setThumbToSetTick(newIndex) {
            if(Common.doesExist(newIndex)) {
                var tick = this.$el.querySelectorAll(".slider-tick")[newIndex];
                var thumb = this.$el.querySelector(".slider-thumb");
                var tickCenter = Common.getElementCenterPosition(tick);
                var thumbCenter = Common.getElementCenterPosition(thumb);
                var distance = Math.hypot(tickCenter.x - thumbCenter.x);
                var distanceWithDirection = tickCenter.x < thumbCenter.x ? -Math.abs(distance) : distance;
                var moveDistance = parseInt(thumb.style.left) + distanceWithDirection;
                thumb.style.left = moveDistance + "px";
            }
        },
        emitSelectedSliderOption(element) {
            var elementIndex = parseInt(element.getAttribute("data-index"));
            this.$emit("SliderSelection", this.ticks[elementIndex]);
        },
        onResize() {
            clearTimeout(this.resizeTimer);

            this.resizeTimer = setTimeout(()=> {
                this.setThumbToSetTick(this.tickIndex);
                this.setMaxDragLimits();
            }, 250);
        }
    }
};
</script>

<style lang="scss" scoped>
    $slider-height: 38px;
    $slider-track-height: 4px;
    $track-margin: 3px;

    .slider {
        position: relative;
        width: 100%;
        height: $slider-height;
    }

    .slider-flex {
        @include flex-center-horizontal();
        position: absolute;
        top: 0;
    }

    .slider-bar-track-container {
        width: 100%;
        height: $slider-track-height;
        margin-top: calc((#{$slider-height} / 2) - (#{$slider-track-height} / 2));
        background: $genesight-blue;
        border-radius: $slider-track-height;
    }

    .slider-ticks {
        @include flex-center-horizontal();
        justify-content: space-between;
        width: 100%;
        margin-left: $track-margin;
        margin-right: $track-margin;
    }

    .slider-tick {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: $slider-tick-blue;
    }

    .slider-tick-template {
        display: none;
    }

    .slider-bar-thumb-container {
        @include flex-center-horizontal();
        width: 100%;
    }

    .slider-thumb {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        width: $slider-height;
        height: $slider-height;
        border: 1px solid $white;
        border-radius: $slider-height;
        background: $genesight-green;
        box-sizing: border-box;
        
        &:hover {
            cursor: pointer;
        }
    }

    .slider-thumb-tooltip {
        display: none;
        margin-top: -27px;
        height: 24px;
        padding: 5px;
        background: $dark-gray-text-color;
        color: $white;
        border-radius: 5px;
        font-size: 12px;
        white-space: nowrap;
    }

    .slider-thumb-tooltip--visible {
        display: block;
    }
</style>
