export default {
    state: {
        medicareSelected: false
    },
    getters: {
        getMedicareSelected: state => {
            return state.medicareSelected;
        }
    },
    mutations: {
        setMedicareSelected(state, value) {
            state.medicareSelected = value;
        }
    },
    actions: {
        setMedicareSelected(context, value) {
            context.commit("setMedicareSelected", value);
        }
    }
};
