<template>
    <div class="dob-component">
        <div v-container-validation class="input-row">
            <label class="input-label label">
                {{ label }}
            </label>
            <div class="demographic-dob-flex">
                <input ref="dateOfBirth" 
                       v-date-mask 
                       v-dob-validation="{required: true, minLength: 6}" 
                       :class="hasError ? 'text-input text-input-border-error' : 'text-input'" 
                       type="text" 
                       name="date"
                       autocomplete="dob"
                       :disabled="disable"
                       :value="$store.getters[storeGetterString]" 
                       @keyup="updateDateOfBirth"
                       @blur="updateDateOfBirthNow"
                       @input="$emit('DobInputChange')"
                >
                <p class="demographic-dob-text">
                    {{ longDob }}
                </p>
            </div>
            <error-labels invalid-string="Invalid Date" />
            <div class="demographic-dob-warning">
                <p><b>Is this correct?</b></p>
                <p>
                    The DOB entered indicates a patient age that is uncommon for GeneSight testing. If the date is correct, please disregard this message.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import CommonFunc from "../../../scripts/common.js";
import ErrorLabels from "../../layout/Demographic/ErrorLabels.vue";

export default {
    name: "DobComponent",
    components: {
        ErrorLabels
    },
    props: {
        storeSettingString: {
            required: true,
            type: String
        },
        storeGetterString: {
            required: true,
            type: String
        },
        disable: {
            default: false,
            type: Boolean
        },
        hasError: {
            default: false,
            type: Boolean
        },
        label: {
            required: false,
            type: String,
            default: "Date of Birth"
        }
    },
    data() {
        return {
            longDob: "mm/dd/yyyy",
            longDobTimeout: null
        };
    },
    methods: {
        updateDateOfBirth(event) {
            clearTimeout(this.longDobTimeout);

            this.longDobTimeout = window.setTimeout(()=> {
                this.updateDateOfBirthNow(event);
            }, 1000);  
        },
        updateDateOfBirthNow(event) {
            this.$store.dispatch(this.storeSettingString, event.target.value);
            let longDob = moment(CommonFunc.ConvertTwoDigitYearToFour(this.$store.getters[this.storeGetterString]), ["MM-DD-YYYY", "M-DD-YYYY", "MM-D-YYYY", "M-D-YYYY"]).format("MMMM Do YYYY");
            
            if(event.target.value.length >= 6) {
                this.longDob = longDob != "Invalid date" ? longDob : "";
            } else if (event.target.value.length === 0) {
                this.longDob = "mm/dd/yyyy";
            }
            
            clearTimeout(this.longDobTimeout);
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "../../../styles/inputs.scss";

    .demographic-dob-flex {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .demographic-dob-text {
        margin-left: 5px;
        color: $dark-gray-text-color;
        font-size: 0.9em;
        font-weight: 600;
    }

    .demographic-dob-warning {
        max-width: 500px;
        max-height: 0px;
        margin-top: 5px;
        margin-left: calc(#{$demographic-label-width} + 15px);
        padding: 0 20px 0 20px;
        background: $warning;
        opacity: 0;
        transition: 0.2s all ease-in-out;
        overflow: hidden;

        p {
            font-size: $regular-copy-size;
        }
    }

    .myriad-container-warning-notice .demographic-dob-warning {
        max-height: 90px;
        padding: 20px;
        opacity: 1;
    }

    @include sm {
        .demographic-dob-warning {
            margin-left: 0;
        }

        .myriad-container-warning-notice .demographic-dob-warning {
            max-height: 150px;
        }
    }
</style>
