<template>
    <header :class="isHomePage()">
        <div class="info-header-content">
            <img
                class="info-header-logo"
                src="../../assets/genesight_header_logo.png"
            >
            <a class="info-header-contact" @click="dialogOpen = true">
                Contact
            </a>
        </div>
        <myriad-dialog :dialog-open="dialogOpen" :title="dialogTitle" @dialogClose="dialogOpen = false">
            <div class="info-header-dialog">
                <h3 class="info-header-dialog-title">
                    Customer Service
                </h3>
                <div class="info-header-dialog-line">
                    <p class="info-header-dialog-label">
                        Hours:
                    </p>
                    <p class="info-header-dialog-content">
                        Monday-Friday: 8am-8pm Eastern
                    </p>
                </div>
                <div class="info-header-dialog-line">
                    <p class="info-header-dialog-label">
                        Tel:
                    </p>
                    <a class="info-header-dialog-content" href="tel:+1-866-757-9204">
                        866.757.9204
                    </a>
                </div>
                <div class="info-header-dialog-line">
                    <p class="info-header-dialog-label">
                        Fax:
                    </p>
                    <p class="info-header-dialog-content">
                        888.894.4344
                    </p>
                </div>
                <div class="info-header-dialog-line">
                    <p class="info-header-dialog-label">
                        Email:
                    </p>
                    <a class="info-header-dialog-content" href="mailto:support@genesight.com">
                        support@genesight.com
                    </a>
                </div>
            </div>
        </myriad-dialog>
    </header>
</template>

<script>
import MyriadDialog from "../layout/MyriadDialog.vue";

export default {
    name: "Header",
    components: {
        MyriadDialog
    },
    data: () => {
        return {
            dialogOpen: false,
            dialogTitle: "Contact"
        };
    },
    methods: {
        isHomePage() {
            var path = this.$route.path;
            var options = ["/"];
            return options.indexOf(path) > -1 ? "info-header info-header-background" : "info-header";
        }
    }
};
</script>

<style scoped lang="scss">
.info-header {
    width: 100%;
    height: $header-height;
    background: $white;
}

.info-header-background {
    background: $background-color;
}

.info-header-content {
    width: 95%;
    height: 100%;
    max-width: $section-max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: .2s all ease;
}

.info-header-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.info-header-dialog-title {
    display: flex;
    margin-bottom: 10px;
}

.info-header-dialog-line {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info-header-dialog-label {
    margin-right: 10px;
    width: 60px;
}

a.info-header-dialog-content {
    text-decoration: none;

    &:hover {
            text-decoration: underline;
            color: $genesight-green;
        }
}

.info-header-background .info-header-content {
    max-width: $home-max-width;
}

.info-header-logo {
    width: 178px;
    height: auto;
}

.info-header-contact {
    font-weight: 600;
    color: $genesight-blue;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: $genesight-green;
    }
}

@include tablet {
    .info-header {
        height: $header-height-tablet;
        background: white;
    }

    .info-header-logo {
        width: 100px;
    }
}
</style>
