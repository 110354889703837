import Vue from "vue";
import Vuex from "vuex";
import CreatedPersistedState from "vuex-persistedstate";

import AppUiStore from "./modules/AppUiStore.js";
import Phq9Store from "./modules/PhqNineStore.js";
import PatientStore from "./modules/PatientStore.js";
import InsuranceStore from "./modules/InsuranceStore.js";
import InsuranceCardStore from "./modules/InsuranceCards.js";
import ConfigurationStore from "./modules/ConfigurationStore.js";
import MedicationStore from "./modules/MedicationStore.js";
import MedicareStore from "./modules/MedicareStore.js";
import ViewReportStore from "./modules/ViewReportStore.js";
import SurveyStore from "./modules/SurveyStore.js";
import PigStore from "./modules/PatientInformationGatheringStore.js";

Vue.use(Vuex);

const initialState = {
    appUiStore: AppUiStore.state,
    Phq9Store: Phq9Store.state,
    PatientStore: PatientStore.state,
    InsuranceStore: InsuranceStore.state,
    InsuranceCardStore: InsuranceCardStore.state,
    ConfigurationStore: ConfigurationStore.state,
    MedicationStore: MedicationStore.state,
    MedicareStore: MedicareStore.state,
    ViewReportStore: ViewReportStore.state,
    SurveyStore: SurveyStore.state,
    PigStore: PigStore.state
};

export default new Vuex.Store({
    modules: {
        appUiStore: AppUiStore,
        Phq9Store: Phq9Store,
        PatientStore: PatientStore,
        InsuranceStore: InsuranceStore,
        InsuranceCardStore: InsuranceCardStore,
        ConfigurationStore: ConfigurationStore,
        MedicationStore: MedicationStore,
        MedicareStore: MedicareStore,
        ViewReportStore: ViewReportStore,
        SurveyStore: SurveyStore,
        PigStore: PigStore
    },
    mutations: {
        reset(state) {
            localStorage.clear();

            Object.keys(state).forEach(key => {
                Object.assign(state[key], initialState[key]);
            });
        }
    },
    actions: {
        reset(context) {
            context.commit("reset");
        }
    },
    plugins: [CreatedPersistedState({
        storage: window.sessionStorage,
        paths: [
            "appUiStore", "Phq9Store", "PatientStore", "InsuranceStore", "ConfigurationStore", "MedicationStore", "MedicareStore", "PigStore"
        ]
    })]
});
