<template>
    <div class="icon-row">
        <img class="icon-row-image" :src="getImage()">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="icon-row-text" v-html="this.$sanitize(text)" />
    </div>  
</template>

<script>
export default {
    name: "IconRow",
    props: {
        image: {
            default: "",
            type: String
        },
        text: {
            default: "",
            type: String
        }
    },
    methods: {
        getImage() {
            return require("../../../assets/" + this.image);
        }
    }
};
</script>

<style lang="scss">
.icon-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
}

.icon-row-image {
    margin-right: 15px;
}

.icon-row-text {
    flex: 1 1;
    font-size: $btn-font-size;
    line-height: 1.4em;
    color: $white;

    a {
        color: white;
    }
}

a.icon-row-link {
    color: $white;

    &:hover {
        text-decoration: none;
    }

    &:visited {
        color: $white;
    }

    &:active {
        color: $white;
    }
}

@include tablet {
    .icon-row {
        align-items: flex-start;
    }
}
</style>
