import { HTTP } from "./http";

const PatientHttp = {
    postUnsubscribe: (emaiAddress) => {
        var data = {email: emaiAddress};
        return HTTP.post("unsubscribe", data);
    }
};

export default PatientHttp;
