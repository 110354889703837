import axios from "axios";
import Store from "../../store/store.js";
import { HTTP } from "./http";

const SurveyHttp = {
    params: {
        withCredentials: true
    },
    getSurvey: () => {
        const token = Store.getters.surveyToken;
        const origin = Store.getters.surveyOrigin;
        const url = `${config.rootApiUrl}survey?token=${token}&origin=${origin}`;

        return axios.get(url, SurveyHttp.params);
    },
    postConsentAccepted: ()=> {
        return HTTP.post("survey/consent?token="+Store.getters.surveyToken, {});
    },
    postSurveyPage: (surveyData, page, isCompleted)=> {
        var postData = {
            token: Store.getters.surveyToken,
            surveyData: JSON.stringify(surveyData),
            page: page,
            isCompleted: isCompleted || false
        };

        return HTTP.post("survey/page", postData);
    },
    getMedicationOptions: ()=> {
        return HTTP.get("survey/medicationOptions");
    }
};

export default SurveyHttp;
