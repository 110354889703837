import moment from "moment";

export default {
    state: {
        viewReportToken: null,
        viewReportOrigin: null,
        viewReportFirstName: null,
        viewReportLastName: null,
        viewReportDob: null,
        tokenExpiration: null,
        hasSpanishReports: false,
        hasPartialSpanishReports: false,
        showPatientValidationError: false,
        maxPatientValidationAttempts: 5,
        currentPatientValidationAttempts: 0,
        patientValidationErrorMessageText: "",
        psychVersion: 0,
        reports: null,
        hasPsych42OrLater: false
    },
    getters: {
        viewReportToken: state => {
            return state.viewReportToken;
        },
        viewReportOrigin: state => {
            if(state.viewReportOrigin == "NA"){
                return "";
            }
            else{
                var origin = state.viewReportOrigin;
                state.viewReportOrigin = "NA";
                return origin;
            }
        },
        viewReportFirstName: state => {
            return state.viewReportFirstName;
        },
        viewReportLastName: state => {
            return state.viewReportLastName;
        },
        viewReportDob: state => {
            return state.viewReportDob;
        },
        tokenExpiration: state => {
            return state.tokenExpiration;
        },
        hasSpanishReports: state => {
            return state.hasSpanishReports;
        },
        hasPartialSpanishReports: state => {
            return state.hasPartialSpanishReports;
        },
        psychVersion: state => {
            return state.psychVersion;
        },
        showPatientValidationError: state => {
            return state.showPatientValidationError;
        },
        maxPatientValidationAttempts: state => {
            return state.maxPatientValidationAttempts;
        },
        currentPatientValidationAttempts: state => {
            return state.currentPatientValidationAttempts;
        },
        patientValidationErrorMessageText: state => {
            return state.patientValidationErrorMessageText;
        },
        reports: state => {
            return state.reports;
        },
        hasPsych42OrLater: state => {
            return state.hasPsych42OrLater;
        }
    },
    mutations: {
        setViewReportToken(state, {token, origin}) {
            state.viewReportToken = token;
            if(state.viewReportOrigin != "NA"){
                state.viewReportOrigin = origin;
            }
        },
        setViewReportFirstName(state, value) {
            state.viewReportFirstName = value;
        },
        setViewReportLastName(state, value) {
            state.viewReportLastName = value;
        },
        setViewReportDob(state, value) {
            state.viewReportDob = value;
        },
        setValidateIdentityInfo(state, {tokenExpiration, patientReportLanguages, psychVersion, reports}) {
            const expireDate = moment(tokenExpiration).format("MMMM DD, YYYY");
            state.tokenExpiration = expireDate;

            if (psychVersion && psychVersion.length > 0) {
                state.psychVersion = parseFloat(psychVersion);
            }

            if(patientReportLanguages > 0 || state.psychVersion >= 4.2) {
                state.hasSpanishReports = true;
            }

            if(patientReportLanguages == 1) {
                state.hasPartialSpanishReports = true;
            }

            var r = [];
            reports.forEach((item) => {
                if (item.productSeriesName === "Psychotropic" && ((item.majorVersion === 4 && item.minorVersion >= 2) || (item.majorVersion >= 5))) {
                    state.hasPsych42OrLater = true;
                } else {
                    r.push(item);
                }
            });

            state.reports = r;
        },
        showPatientValidationError(state, value) {
            state.showPatientValidationError = value;
        },
        setPatientValidationAttempts(state, {maxAttempts, attempts}) {
            state.maxPatientValidationAttempts = maxAttempts;
            state.currentPatientValidationAttempts = attempts;
        },
        patientValidationErrorMessageText(state, value) {
            state.patientValidationErrorMessageText = value;
        }
    },
    actions: {
        setViewReportToken(context, {token, origin}) {
            context.commit("setViewReportToken", {token, origin});
        },
        setViewReportFirstName(context, value) {
            context.commit("setViewReportFirstName", value);
        },
        setViewReportLastName(context, value) {
            context.commit("setViewReportLastName", value);
        },
        setViewReportDob(context, value) {
            context.commit("setViewReportDob", value);
        },
        setValidateIdentityInfo(context, {tokenExpiration, patientReportLanguages, psychVersion, reports}) {
            context.commit("setValidateIdentityInfo", {tokenExpiration, patientReportLanguages, psychVersion, reports});
        },
        showPatientValidationError(context, value) {
            context.commit("showPatientValidationError", value);
        },
        setPatientValidationAttempts(context, {maxAttempts, attempts}) {
            context.commit("setPatientValidationAttempts", {maxAttempts, attempts});
        },
        patientValidationErrorMessageText(context, value) {
            context.commit("patientValidationErrorMessageText", value);
        }
    }
};
