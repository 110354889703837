import axios from "axios";
import Store from "../../store/store.js";
import { HTTP } from "./http";

const ViewReportHttp = {
    params: {
        withCredentials: true
    },
    getIsValidToken: () => {
        const token = Store.getters.viewReportToken;
        const origin = Store.getters.viewReportOrigin;
        const url = `${config.rootApiUrl}patientResults?token=${token}&origin=${origin}`;

        return axios.get(url, ViewReportHttp.params);
    },
    verifyInformation: (data) => {
        data.Token = Store.getters.viewReportToken;
        return HTTP.post("validateIdentity", data);
    },
    retrieveResults: (data) => {
        data.token = Store.getters.viewReportToken;
        
        const formData = new FormData();
        HTTP.buildFormData(formData, data);

        const postConfig = {
            withCredentials: true,
            headers: {
                "Content-Type": "multipart/form-data",
                "RequestVerificationToken": Store.getters.antiforgeryToken.requestToken
            },
            responseType: "blob"
        };

        return axios.post(`${config.rootApiUrl}retrieveResults`, formData, postConfig);
    }
};

export default ViewReportHttp;
