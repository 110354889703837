<template>
    <div class="maintenance-page">
        <iframe class="maintenance-iframe" :src="iframeSrc" />
    </div>
</template>

<script>
import BasePage from "./BasePage.vue";

export default {
    name: "MaintenancePage",
    extends: BasePage,
    data() {
        return {
            iframeSrc: config.maintenanceFile
        };
    }
};
</script>

<style lang="scss" scoped>
    .maintenance-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .maintenance-iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>
