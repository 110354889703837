<template>
    <button :class="iconBtnClass + ' icon-btn'" @click="onClick">
        <reports-icon v-if="isMultipleFilled" class="icon-btn-img" />
        <reports-green-icon v-else-if="multiple" class="icon-btn-img" />
        <report-icon v-else-if="filled" class="icon-btn-img" />
        <report-green-icon v-else class="icon-btn-img" />
        <p>
            {{ btnText }}
        </p>
    </button>
</template>

<script>
import ReportIcon from "../../assets/report-icon.svg";
import ReportGreenIcon from "../../assets/report-icon-green.svg";
import ReportsIcon from "../../assets/reports-icon.svg";
import ReportsGreenIcon from "../../assets/reports-icon-green.svg";

export default {
    name: "IconBtn",
    components: {
        ReportIcon,
        ReportGreenIcon,
        ReportsIcon,
        ReportsGreenIcon
    },
    props: {
        btnText: {
            type: String,
            required: true
        },
        filled: {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        iconBtnClass() {
            return this.filled ? "icon-btn--filled" : "";
        },
        isMultipleFilled() {
            return this.multiple && this.filled;
        }
    },
    methods: {
        onClick() {
            this.$emit("buttonClick");
        }
    }
};
</script>

<style lang="scss" scoped>
    .icon-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 50px;
        padding: 0 23px;
        color: $genesight-green;
        background: white;
        border-radius: 9999px;
        border: none;
        font-size: $medical-section-header-font-size;
        border: 1px solid $genesight-green;
    }

    .icon-btn:hover {
        cursor: pointer;
    }

    .icon-btn--filled {
        color: white;
        background: $genesight-green;
        border: none;
    }

    .icon-btn-img {
        margin-right: 14px;
    }
</style>
