<template>
    <div class="survey-page-3">
        <thank-you-topper />
        <slot />
        <h3 class="survey-page-title">
            Tell us about your health history
        </h3>
        <div class="survey-blue-box">
            <p class="survey-blue-box-text">
                These questions combined with test results offer a clear picture of your genetic data. Thank you for contributing to the future of personalized health care.
            </p>
        </div>
        <div class="health-history-table" />
        <div 
            v-for="(item, index) in questions" 
            :key="index"
        >
            <div class="table-row">
                <p class="survey-question">
                    {{ item }}
                </p>
                <genesight-button-row 
                    v-multi-ref:tableQuestionRow 
                    class="table-row-radio-buttons"
                    hide-labels="false"
                    slim="false" 
                />
            </div>
            <hr class="table-row-hr">
        </div>
        <div class="medication-experience">
            <h3 class="survey-page-title">
                Medication experience 
            </h3>
            <div class="medication-experience-row">
                <label class="medication-experience-label medication-experience-column1 medication-experience-main-label">
                    What medications do you take regularly for conditions other than depression or mental health disorders? 
                </label>
                <div class="medication-experience-column2">
                    <label class="medication-experience-label">
                        Medication
                    </label>
                    <input v-for="index in numRegularMedications" 
                           :key="index" 
                           class="medication-experienc-input cy-regular-med-input" 
                           type="text"
                           placeholder="(optional)"
                           @input="handleRegularMedicationAdded($event, index)"
                    >
                    <button class="cy-add-regular-med-btn add-med-btn table-row-radio-buttons" @click="numRegularMedications++">
                        <font-awesome-icon icon="fa-solid fa-circle-plus" />
                        Add medication
                    </button>
                </div>
            </div>
            <div class="medication-experience-row">
                <label class="medication-experience-label medication-experience-column1 medication-experience-main-label">
                    What medications have you previously tried for depression and how well did they work for you?
                </label>
                <div class="medication-experience-column2">
                    <div class="medication-experience-sub-row hide-tablet">
                        <label class="medication-experience-label medication-experience-sub-row-column1">
                            Medication
                        </label>
                        <label class="medication-experience-label medication-experience-sub-row-column2">
                            How well did it work?
                        </label>
                    </div>
                    <div v-for="index in numPreviousMedications" 
                         :key="index" 
                         class="medication-experience-sub-row cy-depression-med-row"
                    >
                        <label class="medication-experience-label hide-desktop">
                            Medication
                        </label>
                        <input
                            class="medication-experienc-input medication-experience-sub-row-column1" 
                            type="text"
                            placeholder="(optional)"
                            @input="handleDepressionMedicationAdded($event, index)"
                        >
                        <label class="medication-experience-label hide-desktop">
                            How well did it work?
                        </label>
                        <select 
                            class="medication-experienc-select medication-experience-sub-row-column2" 
                            @change="handleDepressionMedicationEfficacy($event, index)"
                        >
                            <option disabled selected value />
                            <option value="a great deal">
                                a great deal
                            </option>
                            <option value="a fair amount">
                                a fair amount
                            </option>
                            <option value="somewhat">
                                somewhat
                            </option>
                            <option value="a little">
                                a little
                            </option>
                            <option value="not at all">
                                not at all
                            </option>
                        </select>
                    </div>
                        
                    <button class="cy-add-depression-med-btn add-med-btn table-row-radio-buttons" @click="numPreviousMedications++">
                        <font-awesome-icon icon="fa-solid fa-circle-plus" />
                        Add medication
                    </button>
                </div>
            </div>
        </div>
        <div class="about-you-section">
            <h3 class="survey-page-title">
                A little about you
            </h3>
            <div class="survey-blue-box">
                <p class="survey-blue-box-text">
                    Knowing a bit more about you will help us better understand the impact of individual characteristics on medication outcomes.
                </p>
            </div>
            <div class="about-you">
                <div class="about-you-row">
                    <label class="medication-experience-label about-you-column1">
                        Which of the following best describes you? 
                    </label>    
                    <myriad-multiselect 
                        :items="ethnicityOptions"
                        :disabled="false"
                        :preselected-items="[]"
                        :enable-validation="false"
                        class="ethnicity-select about-you-column2" 
                        items-name="medications"
                        placeholder=""
                        @selected-values-change="handleEthniticyOptionSelection"
                    />
                </div>
                <div class="about-you-row">
                    <label class="medication-experience-label about-you-column1">
                        Weight
                    </label>
                    <div class="sub-about-you-row about-you-column2">
                        <div class="placeholder-container">
                            <input 
                                v-model="jsonData.weight" 
                                type="text"
                                class="medication-experienc-input weight-input"
                                placeholder="lbs"
                                @keyup="filterOutCharacters($event)"
                            >
                            <span class="placeholder">lbs</span>
                        </div>
                    </div>
                </div>
                <div class="about-you-row">
                    <label class="medication-experience-label about-you-column1">
                        Height
                    </label>
                    <div class="about-you-column2 sub-about-you-row">
                        <div class="placeholder-container">
                            <input 
                                v-model="jsonData.height.feet"
                                type="text" 
                                class="medication-experienc-input height-input"
                                placeholder="ft"
                                @keyup="filterOutCharacters"
                            >
                            <span class="placeholder placeholder-measure">ft</span>
                        </div>
                        <div class="placeholder-container">
                            <input 
                                v-model="jsonData.height.inches"
                                type="text" 
                                class="medication-experienc-input height-input"
                                placeholder="in"
                                @keyup="filterOutCharacters"
                            >
                            <span class="placeholder placeholder-measure">in</span>
                        </div>
                    </div>
                </div>
                <div class="about-you-row">
                    <label class="medication-experience-label about-you-column1">
                        Education level
                    </label>
                    <select 
                        v-model="jsonData['Education Level']" 
                        class="medication-experienc-select about-you-select"
                    >
                        <option disabled selected value />
                        <option value="Did not graduate high school">
                            Did not graduate high school
                        </option>
                        <option value="High school diploma or equivalent">
                            High school diploma or equivalent
                        </option>
                        <option value="Some college, no degree">
                            Some college, no degree
                        </option>
                        <option value="Associate's degree">
                            Associate's degree
                        </option>
                        <option value="Bachelor's degree">
                            Bachelor's degree
                        </option>
                        <option value="Postsecondary non-degree">
                            Postsecondary non-degree
                        </option>
                        <option value="Master's degree">
                            Master's degree
                        </option>
                        <option value="Doctoral or professional degree">
                            Doctoral or professional degree
                        </option>
                        <option value="Prefer not to answer">
                            Prefer not to answer
                        </option> 
                    </select>
                </div>
                <div class="about-you-row">
                    <label class="medication-experience-label about-you-column1">
                        Do you smoke tobacco or marijuana products?
                    </label>
                    <select 
                        v-model="jsonData['Do you smoke tobacco or marijuana products?']"
                        class="medication-experienc-select about-you-select"
                    >
                        <option disabled selected value />
                        <option value="Current smoker(every day)">
                            Current smoker(every day)
                        </option>
                        <option value="Occasional smoker(not every day)">
                            Occasional smoker(not every day)
                        </option>
                        <option value="Not a current smoker(never or former smoker)">
                            Not a current smoker(never or former smoker)
                        </option>
                        <option value="Prefer not to answer">
                            Prefer not to answer
                        </option> 
                    </select>
                </div>
                <div class="about-you-row">
                    <label class="medication-experience-label about-you-column1">
                        Relationship status
                    </label>
                    <select v-model="jsonData['Relationship status']" class="medication-experienc-select about-you-select">
                        <option disabled selected value /> 
                        <option value="Divorced">
                            Divorced
                        </option>
                        <option value="Married">
                            Married
                        </option>
                        <option value="Separated">
                            Separated
                        </option>
                        <option value="Single">
                            Single
                        </option>
                        <option value="Widowed">
                            Widowed
                        </option>
                        <option value="Prefer not to answer">
                            Prefer not to answer
                        </option>
                    </select>
                </div>
                <div class="about-you-row">
                    <label class="medication-experience-label about-you-column1">
                        Income level  
                    </label>
                    <select v-model="jsonData['Income Level']" class="medication-experienc-select about-you-select">
                        <option disabled selected value />
                        <option value="Less than $25,000">
                            Less than $25,000
                        </option>
                        <option value="$25,000 - $49,999">
                            $25,000 - $49,999
                        </option>
                        <option value="$50,000 - $74,999">
                            $50,000 - $74,999
                        </option>
                        <option value="$75,000 - $99,999">
                            $75,000 - $99,999
                        </option>
                        <option value="$100,000 - $149,999">
                            $100,000 - $149,999
                        </option>
                        <option value="$150,000 and above">
                            $150,000 and above
                        </option>
                        <option value="Prefer not to answer">
                            Prefer not to answer
                        </option>
                    </select>
                </div>
            </div>
            <div class="submit-btn-container">
                <button class="submit-btn survey-submit-btn cy-submit-btn" type="button" @click="submitPage">
                    <p class="submit-btn-text">
                        Submit
                    </p>
                    <img class="submit-btn-image" src="../../../assets/btn_arrow.png">
                </button>
            </div>
            <p class="bottom-note">
                <b>Note:</b> Your responses above are not shared with your healthcare provider.
            </p>
        </div>
    </div>
</template>

<script>
import SurveyHttp from "../../../scripts/http/survey-http.js";
import ThankYouTopper from "../../layout/Survey/ThankYouTopper.vue";
import Common from "../../../scripts/common";

export default {
    name: "SurveyPage3",
    components: {
        ThankYouTopper
    },
    data() {
        return {
            questions: [
                "Have you ever been diagnosed with a liver problem or disease?",
                "Have you ever been diagnosed with a kidney problem or disease?",
                "Do you experience chronic pain due to any physical or medical condition?",
                "Do you have a family history of close relatives that have been diagnosed with depression?",
                "Have you ever misused or been dependent on alcohol or other drugs?",
                "Have you ever been diagnosed with an inflammatory or auto-immune disease?",
                "Do you currently receive psychotherapy/talk therapy in addition to medication treatment for depression?"
            ],
            options: ["Yes", "Unknown", "No"],
            validState: false,
            numRegularMedications: 1,
            numPreviousMedications: 1,
            ethnicityOptions: ["White", "Black or African American", "American Indian or Alaska Native", "Asian", "Native Hawaiian or Other Pacific Islander", "Hispanic or Latino", "Unknown", "Prefer not to answer"],
            jsonData: {
                healthHistory: {},
                weight: null,
                height: {
                    feet: null,
                    inches: null
                },
                "Education Level": null,
                "Do you smoke tobacco or marijuana products?": null,
                "Relationship status": null,
                "Income Level": null
            }
        };
    },
    mounted() {
        Common.scrollToTop();

        this.questions.forEach((item, index) => {
            this.$refs.tableQuestionRow[index].options = this.$data.options;
            this.$refs.tableQuestionRow[index].addEventListener("optionSelected", (event) => {
                this.$refs.tableQuestionRow[index].invalid = false;
                this.jsonData.healthHistory[item] = {
                    "answer": event.detail,
                    "index": index
                };
            }); 
        }); 
    },
    methods: {
        handleRegularMedicationAdded(event, index) {
            let key = "Regular Medication " + index;
            this.jsonData[key] = event.target.value;
        },
        handleDepressionMedicationAdded(event, index) {
            let key = "Depression Medication " + index;

            Common.mergeOrAddToJson(this.jsonData, key, {
                "medication": event.target.value
            });

        },
        handleDepressionMedicationEfficacy(event, index) {
            let key = "Depression Medication " + index;

            Common.mergeOrAddToJson(this.jsonData, key, {
                "efficacy": event.target.value
            });
        },
        handleEthniticyOptionSelection(value) {
            let ethnicityArray = [];
            value.forEach((item) => {
                ethnicityArray.push(item.name);
            });

            this.jsonData["Ethnicity"] = ethnicityArray;
        },
        filterOutCharacters(event) {
            event.target.value = event.target.value.replace(/[^0-9./]/, "");
        },
        submitPage() {
            this.validatePage();
            if(this.validState) {
                SurveyHttp.postSurveyPage(this.jsonData, 3, true);
                this.$store.dispatch("setPagesSaved", 3);
            }
        },
        validatePage() {
            //TODO:
            this.validState = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_survey.scss";

$mobile-vertial-margin: 10px;

.health-history-table {
    display: flex;
    flex-direction: column;
}

.table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    min-height: 94px;
    box-sizing: border-box;
}

.table-row-question {
    font-size: 1.1em;
}

.table-row-radio-buttons {
    max-width: 283px;
    width: 100%;
}

.table-rating-list {
    display: none;
}

.survey-question {
    max-width: 770px;
    width: 100%;
}

.table-row-hr {
    height: 1px;
    background-color: #D0D3DA;
    border: none;
}

.hide-desktop {
    display: none;
}

.bottom-note {
    margin-top: 30px;
    margin-bottom: 90px;
    font-size: .9em;
    color: $survey-instructions-color;
}

.medication-experience-row {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
}

.medication-experience-column1 {
    width: 46%;
    padding-right: 60px;
}

.medication-experience-column2 {
    display: flex;
    flex-direction: column;
    width: 54%;
}

.medication-experience-sub-row {
    display: flex;
    flex-direction: row;

    .medication-experience-sub-row-column1 {
        width: 71%;
        margin-right: 10px;
    }

    .medication-experience-sub-row-column2 {
        width: 29%;
    }
}

.medication-experienc-select {
    height: 32px;
    min-width: 170px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin: 7px 0 10px 0;
    color: #333333;
}

.medication-experienc-input {
    margin-top: 7px;
    margin-bottom: 10px;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    font-size: 1em;
    line-height: 32px;
    color: #333333;

    &:focus {
        outline: 1px solid $genesight-blue;
    }
}

.medication-experience-label {
    font-size: $survey-instructions-text-size;
    color: #333333;
}

::v-deep .ethnicity-select {
    height: 32px;
    margin: 7px 5px 10px 0;
            
    .multiselect {
        min-height: 32px;
        border: 1px solid #D3D3D3;
        border-radius: 5px;
    }

    .multiselect-input {
        height: 32px;
        outline: 1px solid $genesight-blue;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .selected-item {
        border-radius: 4px;
        padding: 0px 10px;
    }

    .multiselect-list-item--hovered,
    .multiselect-list-item:hover {
        background: #EBF7FD;
    }
}

.about-you-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D3D3D3;
}

.sub-about-you-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about-you-column1 {
    width: 39%;
}

.about-you-column2 {
    width: 61%;
}

.weight-input,
.height-input {
    max-width: 144px;
    margin-right: 6px;

    &::placeholder {
        color: transparent;
        text-align: right;
    }
}

.about-you-select {
    min-width: 294px;
}

.placeholder-container {
    position: relative;
    min-height: 32px;
}

.placeholder-container span {
    margin-right: 5px;
}

.placeholder {
    display: block;
    max-width: 0px;
    max-height: 0px;
    position: absolute;
    top: 13px;
    right: 28px;
    overflow: visible;
    color: #8e8e8e;
}

.placeholder-measure {
    right: 21px;
}

@include tablet {
    .hide-desktop {
        display: block;
    }

    .hide-tablet {
        display: none;
    }

    .medication-experience-row,
    .medication-experience-sub-row {
        flex-direction: column;

        .medication-experience-sub-row-column1 {
            width: 100%;
        }
    }

    .medication-experience-column1,
    .medication-experience-column2 {
        width: 100%;
    } 

    .medication-experience-main-label {
        margin-bottom: 20px;
    }

    .about-you-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .about-you-column1,
    .about-you-column2 {
        width: 100%;
    }

    .about-you-select {
        min-width: 100%;
        width: 100%;
    }
}
</style>
