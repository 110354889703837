import Vue from "vue";
import Router from "vue-router";
import Store from "../store/store";
import Common from "../scripts/common.js";

import Home from "../pages/Home.vue";
import Complete from "../pages/Complete.vue";
import Demographic from "../pages/Demographic.vue";
import Medical from "../pages/Medical.vue";
import Review from "../pages/Review.vue";
import PrimaryInsurance from "../pages/PrimaryInsurance.vue";
import SecondaryInsurance from "../pages/SecondaryInsurance.vue";
import Privacy from "../pages/PrivacyPages.vue";
import OutdatedBrowser from "../pages/OutdatedBrowser.vue";
import Error from "../pages/Error.vue";
import Unsubscribe from "../pages/Unsubscribe.vue";
import ViewReportVerify from "../pages/ViewReportVerify.vue";
import ViewReport from "../pages/ViewReport.vue";
import Survey from "../pages/Survey.vue";
import Consent from "../pages/Survey/Consent.vue";
import SurveyOne from "../pages/Survey/SurveyOne.vue";
import SurveyTwo from "../pages/Survey/SurveyTwo.vue";
import SurveyThankYou from "../pages/Survey/Survey-ThankYou.vue";
import PatientInformationVerify from "../pages/PatientInformationVerify.vue";

import strings from "../assets/strings.json";

Vue.use(Router);

const router = new Router({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            name: "Home",
            component: Home,
            meta: {
                showFormHeaders: false
            }
        },
        {
            path: "/demographic",
            name: "Demographic",
            component: Demographic,
            meta: {
                StepHeaderTitle: "Your Information",
                InstructionsText: "This Information is used to facilitate the physician ordering the GeneSight test.",
                showFormHeaders: true
            }
        },
        { 
            path: "/insurance", 
            redirect: "/insurance/primary"
        },
        {
            path: "/insurance/primary",
            name: "Primary-Insurance",
            component: PrimaryInsurance,
            meta: {
                StepHeaderTitle: "Insurance",
                InstructionsText: strings.insurancePageInstructionsBlurb,
                showFormHeaders: true
            },
            beforeEnter: (to, from, next) => {
                if(Store.getters.demographicStepComplete) {
                    next();
                }
                else {
                    next("/demographic");
                }
            }
        },
        {
            path: "/insurance/secondary",
            name: "Secondary-Insurance",
            component: SecondaryInsurance,
            meta: {
                StepHeaderTitle: "Insurance",
                InstructionsText: strings.insurancePageInstructionsBlurb,
                showFormHeaders: true
            },
            beforeEnter: (to, from, next) => {
                if(Store.getters.demographicStepComplete) {
                    next();
                }
                else {
                    next("/demographic");
                }
            }
        },
        {
            path: "/medical",
            name: "Medical",
            component: Medical,
            meta: {
                StepHeaderTitle: "Tell Us About Yourself",
                InstructionsText: "This information will help the physician better understand your health.",
                showFormHeaders: true
            },
            beforeEnter: (to, from, next) => {
                if(Store.getters.insuranceStepComplete) {
                    next();
                }
                else {
                    next("/insurance/primary");
                }
            }
        },
        {
            path: "/review",
            name: "Review",
            component: Review,
            meta: {
                StepHeaderTitle: "Review",
                InstructionsText: "Please ensure that the information below is correct before requesting your GeneSight test.",
                showFormHeaders: true
            },
            beforeEnter: (to, from, next) => {
                if(Store.getters.medicalStepComplete) {
                    next();
                }
                else {
                    next("/medical");
                }
            }
        },
        {
            path: "/complete",
            name: "Complete",
            component: Complete,
            meta: {
                StepHeaderTitle: "Complete",
                InstructionsText: "Complete blurb goes here",
                showFormHeaders: false
            },
            beforeEnter: (to, from, next) => {
                if(Store.getters.reviewStepComplete) {
                    next();
                }
                else {
                    next("/review");
                }
            }
        },
        {
            path: "/patient-information-verify",
            name: "Patient-Information-Verify",
            component: PatientInformationVerify,
            meta: {
                StepHeaderTitle: "Your Information",
                InstructionsText: "This information is used to match a patient to their GeneSight order.",
                showFormHeaders: true,
                hideBreadcrumbs: true
            },
            beforeEnter: (to, from, next) => {
                Store.dispatch("setIsPatientInformationGathering", true);
                next();
            }
        },
        {
            path: "/patient-information-insurance/primary",
            name: "Patient-Information-Primary-Insurance",
            component: PrimaryInsurance,
            meta: {
                StepHeaderTitle: "Insurance",
                InstructionsText: strings.insurancePageInstructionsBlurb,
                showFormHeaders: true,
                hideBreadcrumbs: true
            },
            beforeEnter: (to, from, next) => {
                Store.dispatch("setIsPatientInformationGathering", true);
                next();
            }
        },
        {
            path: "/patient-information-insurance/secondary",
            name: "Patient-Information-Secondary-Insurance",
            component: SecondaryInsurance,
            meta: {
                StepHeaderTitle: "Insurance",
                InstructionsText: strings.insurancePageInstructionsBlurb,
                showFormHeaders: true,
                hideBreadcrumbs: true
            }
        },
        {
            path: "/privacy/:noticeId",
            name: "Privacy",
            component: Privacy,
            props: true
        },
        {
            path: "/outdated-browser",
            component: OutdatedBrowser,
            meta: {
                showFormHeaders: false
            }
        },
        {
            path: "/error",
            component: Error,
            name: "Error",
            meta: {
                showFormHeaders: false,
                props: true
            }
        },
        {
            path: "/unsubscribe",
            component: Unsubscribe,
            name: "Unsubscribe"
        },
        {
            path: "/view-report-verify",
            component: ViewReportVerify,
            name: "ViewReportVerify",
            meta: {
                StepHeaderTitle: "Verify information",
                InstructionsText: "Verify your information to access your results.",
                showFormHeaders: true,
                hideBreadcrumbs: true
            }
        },
        {
            path: "/view-report",
            component: ViewReport,
            name: "ViewReport",
            meta: {
                StepHeaderTitle: "View report",
                InstructionsText: "We recommend that you <b>print or save your report</b> for future reference.<br>To protect your privacy, access to your report will <b>expire on</b>",
                showFormHeaders: true,
                hideBreadcrumbs: true
            },
            beforeEnter: (to, from, next) => {
                if(from.fullPath === "/view-report-verify" && Store.getters.viewReportToken != null) {
                    next();
                }
                else {
                    next("/view-report-verify");
                }  
            }
        },
        {
            path: "/survey",
            component: Survey,
            name: "Survey",
            meta: {
                showFormHeaders: false,
                hideBreadcrumbs: true
            },
            children: [
                { 
                    path: "", 
                    component: Consent,
                    meta: {
                        showFormHeaders: false,
                        hideBreadcrumbs: true
                    },
                    beforeEnter: (to, from, next) => {
                        if(Store.getters.consentAccepted) {
                            next("/survey");
                        }
                        else  {
                            next();
                        }
                    }
                },
                {
                    path: "survey1",
                    component: SurveyOne,
                    meta: {
                        StepHeaderTitle: "Help others like you",
                        InstructionsText: strings.survey1Instructions,
                        showFormHeaders: true,
                        hideBreadcrumbs: true
                    },
                    beforeEnter: (to, from, next) => {
                        if(!Store.getters.consentAccepted) {
                            next("/survey");
                        }
                        else  {
                            next();
                        }
                    }
                },
                {
                    path: "survey2",
                    component: SurveyTwo,
                    meta: {
                        StepHeaderTitle: "Welcome back",
                        InstructionsText: strings.survey2Instructions,
                        showFormHeaders: true,
                        hideBreadcrumbs: true
                    }
                },
                {
                    path: "survey-thank-you",
                    component: SurveyThankYou
                }
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {
    const urlParams = Common.getUrlParams(window.location.search);

    const tokenChange = Store.getters.patientToken !== urlParams.token && Common.doesExistAndNotEmpty(Store.getters.patientToken);
    if(tokenChange && to.path === "/") {
        Store.dispatch("reset");
        next();
    }
    else if(tokenChange && to.path !== "/error" && to.path !== "/complete") {
        Store.dispatch("reset");
        next("/");
    }
    else {
        next();
    }
});

router.afterEach(to => {
    Store.dispatch("setStepHeaderTitle", to.meta.StepHeaderTitle);
    Store.dispatch("setStepInstructions", to.meta.InstructionsText);
    Store.dispatch("setShowErrorMessage", false);
    Store.dispatch("setShowFormHeaders", to.meta.showFormHeaders);
    Store.dispatch("setHideBreadcrumbs", to.meta.hideBreadcrumbs);

    if(to.meta.hideBreadcrumbs) {
        Store.dispatch("setHideBreadcrumbs", to.meta.hideBreadcrumbs);
    }
    else {
        Store.dispatch("setHideBreadcrumbs", false);
    }
});

export default router;
