import Axios from "axios";
import Store from "../../store/store.js";

export const HTTP = {
    rootUrl: config.rootApiUrl, //Comes from config.js file
    params: {
        withCredentials: true
    },

    put: (uri, data) => {
        return Axios.put(HTTP.rootUrl + uri, data, HTTP.params)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    },
    get: function (uri) {
        return Axios.get(HTTP.rootUrl + uri, HTTP.params);
    },
    getWithToken: function (uri) {
        uri = uri + "?token=" + Store.getters.patientToken + "&origin=" + Store.getters.patientOrigin;
        return Axios.get(HTTP.rootUrl + uri, HTTP.params);
    },
    delete: uri => {
        return Axios.delete(HTTP.rootUrl + uri, HTTP.params)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    },
    post: (uri, patientData) => {
        var headers = {
            "Content-Type": "multipart/form-data",
            "RequestVerificationToken": Store.getters.antiforgeryToken.requestToken
        };

        HTTP.params["headers"] = headers;
        const formData = new FormData();

        HTTP.buildFormData(formData, patientData);

        return Axios.post(HTTP.rootUrl + uri, formData, HTTP.params)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    },
    buildFormData: (formData, data, parentKey) => {
        if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                HTTP.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? "" : data;
      
            formData.append(parentKey, value);
        }
    },
    getAntiforgeryToken: () => {
        Axios.get(HTTP.rootUrl + "antiforgery", HTTP.params).then((response)=> {
            Store.dispatch("setAntiforgeryToken", response.data);
        });
    }
};
