<template>
    <myriad-neuro-tooltip
        class="question-tooltip-container"
        title=""
        content="We provide only a male (M) or female (F) option in the sex category because this information is used to populate the 1500 insurance claim form, and that form only has male or female."
    >
        <div class="question-tooltip">
            ?
        </div>
    </myriad-neuro-tooltip>
</template>

<script>
import MyriadNeuroTooltip from "../../ui/MyriadNeuroTooltip.vue";
export default {
    name: "SexTooltip",
    components: {
        MyriadNeuroTooltip
    }
};
</script>

<style lang="scss" scoped>
.question-tooltip {
    @include flex-center-vertical();
    width: 20px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: $genesight-blue;
    border: 2px solid $genesight-blue;
    border-radius: 20px;
    &:hover {
        cursor: pointer;
    }
}
</style>
