<template>
    <div id="app">
        <maintenance-page v-if="isSiteInMaintenanceMode" />
        <div>   
            <transition-group name="fade">  
                <loader v-if="this.$store.getters.showStartupLoader" key="1" />
            </transition-group>
            <div class="pages-content">
                <info-header />
                <div :class="isHomePage ? '' : 'nucleotide-bg-container'">
                    <step-header />
                    <nav-steps />
                    <step-instructions />
                    <step-error />
                    <transition name="component-fade" mode="out-in">
                        <router-view />
                    </transition>
                </div>
            </div>
            <myriad-footer />
        </div> 
    </div>
</template>

<script>
import MaintenancePage from "./pages/Maintenance.vue";
import NavSteps from "./components/layout/NavSteps.vue";
import InfoHeader from "./components/layout/Header.vue";
import StepHeader from "./components/layout/StepHeader.vue";
import StepInstructions from "./components/ui/StepInstructions.vue";
import StepError from "./components/ui/StepError.vue";
import Loader from "./components/ui/NucleotideLoader.vue";
import MyriadFooter from "./components/layout/Footer.vue";
import browserUpdate from "browser-update";

export default {
    name: "App",
    components: {
        NavSteps,
        InfoHeader,
        StepHeader,
        StepInstructions,
        StepError,
        Loader,
        MyriadFooter,
        MaintenancePage

    },
    computed: {
        isHomePage: function() {
            return this.$route.name === "Home";
        },
        isSiteInMaintenanceMode() {
            if(process.env.NODE_ENV === "development") {
                return false;
            }

            const http = new XMLHttpRequest();
            http.open("HEAD", config.maintenanceFile, false);
            http.send();
            return http.status == 200;
        }
    },
    created() {
        this.$store.dispatch("setShowStartupLoader", true);

        // Check if user needs to update browser
        browserUpdate({
            required: {
                e: -1,
                i: -4,
                f: -2,
                o: -1,
                o_a: 0, // eslint-disable-line
                s: -1,
                c: -3,
                y: 0,
                v: 0,
                uc: 0,
                samsung: 0
            },
            reminder: 8,
            nomessage: true,
            onshow: ()=> {
                this.$store.dispatch("setShowStartupLoader", false);
                this.$router.push("outdated-browser");
            }
        });
    }
};
</script>

<style lang="scss">
@import "~@myriad-neuroscience/pulse-ui/scss/reset.scss";
@import "./styles/_common.scss";
@import "./styles/_validation.scss";

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
a  {
    font-weight: normal;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
}

a,
p,
body,
ol, 
ul,
div,
span,
label,
input {
    font-family: 'Lato', sans-serif;
}

.fade-enter-active {
    transition: all 0.05s ease-out;
}

.fade-leave-active {
    transition: all 0.05s ease-in;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.component-fade-enter-active {
    transition: all 0.05s ease;
}

.component-fade-leave-active {
    transition: all 0.05s ease;
}

.component-fade-enter,
.component-fade-leave-to {
    min-height: 500px;
    opacity: 0;
}

.slow-fade-enter-active {
    transition: all 0.1s ease-out;
    transition-delay: 0.1s;
}

.slow-fade-leave-active {
    transition: all 0.1s ease-in;
}

.slow-fade-enter,
.slow-fade-leave-to {
    opacity: 0;
}

.nucleotide-bg-container {
    background-color: $background-color;
    background-image: url("./assets/bg_genesight_nucleotide_white.png");
    background-position: left -573px top -310px;
    background-repeat: no-repeat;
}

@include tablet {
    .nucleotide-bg-container {
        background-size: 160%;
        background-position: left -361px top -174px;
    }
}
</style>
