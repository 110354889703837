<template>
    <div class="nucleo-loader">
        <div class="nucleotide">
            <nucleotide class="nucleotide-img" />
        </div>
    </div>
</template>

<script>
import Nucleotide from "../../assets/nulceotide.svg";
export default {
    name: "NucelotideLoader",
    components: {
        Nucleotide
    }
};
</script>

<style lang="scss" scoped>
    .nucleo-loader {
        @include flex-center-vertical();
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }

    .nucleotide {
        position: relative;
        width: 300px;
        height: 300px;
        animation: regRotate 2s linear infinite;
    }

    .nucleotide-img {
        width: 100%;
        height: 100%;
    }

    @include sm {
        .nucleotide {
            width: 237.5px;
            height: 237.5px;
            transform: scale(0.5);
        }

        .nucleotide-img {
            width: 50%;
            height: 50%;
        }
    }


    @keyframes regRotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
