<template>
    <footer class="home-footer" :class="isHomePage ? 'home-footer--max' : ''">
        <div class="privacy-links">
            <div class="inner-privacy">
                <router-link :to="{ name: 'Privacy', params: { noticeId: 'privacy_notice' } }" class="privacy-link">
                    Privacy Policy
                </router-link>
                <span class="privacy-spacer-link" />
                <router-link :to="{ name: 'Privacy', params: { noticeId: 'npp' } }" class="privacy-link">
                    Notice of Privacy Practices
                </router-link>
            </div>
            <span class="privacy-spacer-link mobile-seperator" />
            <div class="inner-privacy">
                <a :href="spanishPrivacyLinkLocation" target="_blank" class="privacy-link">
                    Notice of Privacy Practices (Spanish)
                </a>
                <span class="privacy-spacer-link" />
                <router-link :to="{ name: 'Privacy', params: { noticeId: 'terms' } }" class="privacy-link">
                    Terms of Use
                </router-link>
            </div>
        </div>
        <p class="home-footer-text">
            © {{ currentYear }} Myriad Neuroscience. Myriad Neuroscience and GeneSight are registered trademarks of Assurex Health, Inc. All Rights Reserved.
        </p>
    </footer>
</template>

<script>
import PrivacyHttp from "../../scripts/http/privacy-http.js";

export default {
    name: "MyriadFooter",
    data: () => {
        return {
            currentYear: new Date().getFullYear().toString(),
            spanishPrivacyLinkLocation: ""
        };
    },
    computed: {
        isHomePage: function() {
            return this.$route.name === "Home";
        }
    },
    mounted() {
        this.openSpanishPrivacyPractices();
    },
    methods: {
        openSpanishPrivacyPractices() {
            PrivacyHttp.getNotice("npp").then(data => {
                this.$data.spanishPrivacyLinkLocation = data.data.spanish_download_link;
            });
        }
    }
};
</script>


<style lang="scss" scoped>
    .home-footer {
        @include flex-center-vertical();
        width: 100%;
        height: 170px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1.1em;
        background: $background-gray;
    }

    .home-footer--max {
        margin: 0 auto;
        max-width: $home-max-width;
    }

    .home-footer-text {
        width: 95%;
        font-family: "Lato";
        font-size: $regular-copy-size;
        font-weight: 500;
        text-align: center;
        color: $white;
    }

    .privacy-links {
        @include flex-center-horizontal();
        margin-bottom: 15px;
    }

    .inner-privacy {
        @include flex-center-horizontal();
    }

    .privacy-link {
        color: $white;
        font-size: $regular-copy-size;
        font-weight: 300;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .privacy-spacer-link {
        width: 1px;
        height: $regular-copy-size;
        margin-left: 20px;
        margin-right: 20px;
        background: $white;
    }


    @include tablet {
        .privacy-links {
            flex-direction: column;
        }

        .mobile-seperator {
            display: none;
        }

        .inner-privacy {
            margin-bottom: 5px;
        }

        .privacy-link {
            width: 260px;
        }

        .inner-privacy .privacy-link:first-of-type {
            text-align: right;
        }
    }

    @include sm {
        .home-footer {
            height: auto;
        }

        .inner-privacy {
            flex-direction: column;
            margin-bottom: 0;
        }

        .privacy-link {
            text-align: center;
            width: auto;
        }

        .privacy-spacer-link {
            display: none;
        }
    }
</style>

