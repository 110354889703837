<template>
    <div class="no-insurance">
        <h2 class="no-insurance-header">
            Pay with cash
        </h2>
        <p class="no-insurance-copy">
            If you don’t have or want to use insurance for your GeneSight test, we offer a cash pay option.  To help offset this cost, we have an interest-free payment plan that can break up the payments over 12 months. If you would like to proceed with this option, please select the “Continue” button below.
            <br>
            <br>
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            If you would like to use insurance <a @click="returnToInsurancePage">return to the insurance page</a>.
        </p>
        <h2 class="no-insurance-header">
            If you are uninsured and paying with cash isn't an option, please contact us directly at:
        </h2>
        <div class="no-insurance-contact">
            <h6 class="no-insurance-cs-header no-insurace-cs-spacing">
                Customer Service
            </h6>
            <a class="link no-insurace-cs-spacing no-insurance-link no-decoration" href="mailto:support@assurexhealth.com">
                support@assurexhealth.com
            </a>
            <a class="link no-insurace-cs-spacing no-insurance-link no-decoration" href="tel:8667579204">
                866.757.9204
            </a>
        </div>
    </div>  
</template>

<script>
export default {
    methods: {
        returnToInsurancePage() {
            this.$emit("close-no-insurance");
        }
    }
};
</script>

<style lang="scss" scoped>
    .no-insurance {
        min-height: 385px;
    }

    .no-insurance-header {
        margin-top: 60px;
        font-size: $no-insurance-header-font-size;
    }

    .no-insurance-copy {
        margin-top: 15px;
        font-size: $regular-copy-size;
    }

    .no-insurance-contact {
        margin-top: 15px;
        margin-bottom: 150px;
    }

    .no-insurance-cs-header {
        font-size: $regular-copy-size;
    }

    .no-insurace-cs-spacing {
        margin-bottom: 7px;
    }

    .no-insurance-link {
        font-size: $btn-font-size;
    }
    .no-decoration {
        text-decoration: none;
    }
</style>
