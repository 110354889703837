<template>
    <div class="insuranceInfo">
        <h4 class="insuranceInfo-header">
            {{ title }}
        </h4>
        <router-link :to="editLocation" class="insuranceInfo-edit">
            edit
        </router-link>
        <div v-if="imagesIncluded" class="insuranceInfo-images">
            <img ref="frontImage" class="insuranceInfo-image">
            <img ref="backImage" class="insuranceInfo-image">
        </div>
        <div v-else class="insuranceInfo-manual">
            <div v-if="insuranceAvailable">
                <div class="review-content-section">
                    <h4 class="review-bold-header">
                        Policyholder information
                    </h4>
                    <p class="review-text">
                        {{ policyholderRelationship }}
                    </p>
                    <p class="review-text">
                        {{ policyholderName }}
                    </p>
                    <p v-if="policyholderBirthdate" class="review-text">
                        Born on {{ policyholderBirthdate }}
                    </p>
                    <p class="review-text">
                        {{ policyholderSex }}
                    </p>
                </div>
                <div class="review-content-section">
                    <h4 class="review-bold-header">
                        Insurance information
                    </h4>
                    <p class="review-text">
                        {{ insuranceCompanyName }}
                    </p>
                    <p v-if="insuranceIdNumber" class="review-text">
                        ID Number: {{ insuranceIdNumber }}
                    </p>
                    <p v-if="insuranceGroupNumber" class="review-text">
                        Group Number: {{ insuranceGroupNumber }}
                    </p>
                    <p class="review-text">
                        {{ insurancePhoneNumber }}
                    </p>
                </div>
            </div>
            <div v-else>
                <div class="review-content-section">
                    <h4 class="review-bold-header">
                        {{ noInsuranceText }}
                    </h4>
                </div>    
            </div>
        </div>
    </div>
</template>

<script>
import Common from "../../../scripts/common.js";

export default {
    name: "InsuranceInfo",
    props: {
        primaryInsurance: {
            default: true,
            required: true,
            type: Boolean
        }
    },
    data: ()=> {
        return {
            frontImage: "",
            backImage: ""
        };
    },
    computed: {
        title: function() {
            return this.primaryInsurance ? "Primary" : "Secondary";
        },
        editLocation: function() {
            return this.primaryInsurance ? "/insurance/primary" : "/insurance/secondary";
        },
        imagesIncluded: function() {
            if(this.primaryInsurance) {
                return Common.doesExistAndNotEmpty(this.$store.getters.primaryFrontInsuranceCard);
            }
            else {
                return Common.doesExistAndNotEmpty(this.$store.getters.secondaryFrontInsuranceCard);
            }
        },
        insuranceAvailable: function() {
            let frontInsuranceCard;
            let insuranceIdNumber;

            if(this.primaryInsurance){
                frontInsuranceCard = this.$store.getters.primaryFrontInsuranceCard;
                insuranceIdNumber = this.$store.getters.primaryInsuranceIdNumber;
            }
            else {
                frontInsuranceCard = this.$store.getters.secondaryFrontInsuranceCard;
                insuranceIdNumber = this.$store.getters.secondaryInsuranceIdNumber;
            }

            var noImagesAvailable = !Common.doesExistAndNotEmpty(frontInsuranceCard);
            var noManualEnteredInfo = !Common.doesExistAndNotEmpty(insuranceIdNumber);

            if(noImagesAvailable && noManualEnteredInfo) {
                return false;
            }
            else {
                return true;
            } 
        },
        noInsuranceText: function() {
            if(this.primaryInsurance) {
                return "No primary insurance";
            }
            else {
                return "No secondary insurance";
            }
        },
        policyholderRelationship: function() {
            return this.primaryInsurance ? this.$store.getters.primaryPolicyholderRelationship : this.$store.getters.secondaryPolicyholderRelationship;
        },
        policyholderName: function() {
            return this.primaryInsurance ? 
                this.$store.getters.primaryPolicyholderFirstName + " " + this.$store.getters.primaryPolicyholderLastName
                :  this.$store.getters.secondaryPolicyholderFirstName + " " + this.$store.getters.secondaryPolicyholderLastName;
        },
        policyholderBirthdate: function() {
            return this.primaryInsurance ? this.$store.getters.primaryPolicyholderDateOfBirth : this.$store.getters.secondaryPolicyholderDateOfBirth;
        },
        policyholderSex: function() {
            return this.primaryInsurance ? this.$store.getters.primaryPolicyholderSex : this.$store.getters.secondaryPolicyholderSex;
        },
        insuranceCompanyName: function() {
            return this.primaryInsurance ? this.$store.getters.primaryInsuranceCompanyName : this.$store.getters.secondaryInsuranceCompanyName;
        },
        insuranceIdNumber: function() {
            return this.primaryInsurance ? this.$store.getters.primaryInsuranceIdNumber : this.$store.getters.secondaryInsuranceIdNumber;
        },
        insuranceGroupNumber: function() {
            return this.primaryInsurance ? this.$store.getters.primaryInsuranceGroupNumber : this.$store.getters.secondaryInsuranceGroupNumber;
        },
        insurancePhoneNumber: function() {
            return this.primaryInsurance ? this.$store.getters.primaryInsurancePhoneNumber : this.$store.getters.secondaryInsurancePhoneNumber;
        }
    },
    mounted() {
        this.getCardImages();
    },
    methods: {
        getCardImages() {
            let getters = this.$store.getters;
            const frontCard = this.primaryInsurance ? getters.primaryFrontInsuranceCard : getters.secondaryFrontInsuranceCard;
            const backCard = this.primaryInsurance ? getters.primaryBackInsuranceCard : getters.secondaryBackInsuranceCard;

            if(this.imagesIncluded) {
                this.$refs.frontImage.src = frontCard.File;
                this.$refs.backImage.src = backCard.File;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .insuranceInfo-header {
        display: inline-block;
    }

    .insuranceInfo-edit {
        float: right;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .insuranceInfo-images {
        margin-top: 18px;
    }

    .insuranceInfo-image {
        width: 100%;
        height: auto;
        margin-top: 18px;
    }

    .insuranceInfo-manual .insuranceInfo-header {
        margin-top: 5px;
    }
</style>
