<template>
    <div class="medication-row" :class="getMedicationContainerClass">
        <div class="medication-input input-container">
            <label class="label" :class="{error: hasError}">
                Mental health medication
            </label>
            <genesight-single-select ref="medicationAutoComplete" class="input medication-autocomplete" :class="getSingleSelectClass" placeholder="Examples: Prozac, sertraline, Xanax, Ritalin" />
            <input v-if="isDisabled" disabled class="input">
            <div v-if="hasError" class="errorMessage">
                This information is required to continue.
            </div>
        </div>  
        <div class="medication-row-inner">
            <div class="time-input input-container">
                <label class="label">
                    Time on medication
                </label>
                <select class="input" :disabled="isDisabled" @input="timeOnMedicationSelection">
                    <option disabled selected value />
                    <option value="Less than 14 days">
                        Less than 14 days
                    </option>
                    <option value="14-30 days">
                        14-30 days
                    </option>
                    <option value="31-60 days">
                        31-60 days
                    </option>
                    <option value="61-90 days">
                        61-90 days
                    </option>
                    <option value="Greater than 90 days">
                        Greater than 90 days
                    </option>
                    <option value="unknown">
                        unknown
                    </option>
                </select>
            </div> 
            <div class="dose-input input-container">
                <label class="label">
                    Total daily dose
                </label>
                <input class="input" :disabled="isDisabled" type="text" placeholder="Example: 30" @input="totalDailyDoseChange ">
            </div> 
            <div class="unit-input input-container">
                <label class="label">
                    Unit
                </label>
                <select class="input" :disabled="isDisabled" @input="unitSelection">
                    <option disabled selected value />
                    <option value="ampule">
                        ampule
                    </option>
                    <option value="bottle">
                        bottle
                    </option>
                    <option value="capsule">
                        capsule
                    </option>
                    <option value="cc">
                        cc
                    </option>
                    <option value="drop">
                        drop
                    </option>
                    <option value="g">
                        g
                    </option>
                    <option value="grain">
                        grain
                    </option>
                    <option value="gram">
                        gram
                    </option>
                    <option value="IU">
                        IU
                    </option>
                    <option value="mg">
                        mg
                    </option>
                    <option value="ml">
                        ml
                    </option>
                    <option value="other">
                        other
                    </option>
                    <option value="puff">
                        puff
                    </option>
                    <option value="tablet">
                        tablet
                    </option>
                    <option value="tbs">
                        tbs
                    </option>
                    <option value="tsp">
                        tsp
                    </option>
                    <option value="unit">
                        unit
                    </option>
                </select>
            </div> 
        </div>  
    </div>
</template>

<script>
import SurveyHttp from "../../../scripts/http/survey-http.js";

export default {
    name: "MedicationsRow",
    props: {
        isDisabled: {
            required: false,
            type: Boolean,
            default: false
        },
        hasError: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            jsonData:{}
        };
    },
    computed: {
        getSingleSelectClass () {
            return this.isDisabled ? "hide" : "hydrated";
        },
        getMedicationContainerClass() {
            return this.isDisabled ? "medication-row--disabled" : "";
        }
    },
    created() {
        if(this.$store.getters.medicationOptions.length == 0){
            SurveyHttp.getMedicationOptions().then((response) => {
                var formattedMeds = response.data.map(x => ({number: x}));
                this.$store.dispatch("setMedicationOptions", formattedMeds);
                this.$refs.medicationAutoComplete.items = this.$store.getters.medicationOptions;
            }).catch(()=> {
                this.$router.push("/error");
            });
        }
    },
    mounted() {
        this.$refs.medicationAutoComplete.items = this.$store.getters.medicationOptions;
        this.addSelectStyling();
        this.$refs.medicationAutoComplete.addEventListener("itemSelected", (event) => {
            this.jsonData["Mental Health Medication"] = event.detail;
            this.$emit("inputChange", this.jsonData);
        });

        this.$refs.medicationAutoComplete.addEventListener("inputChange", (event) => {
            if (event.detail === "") {
                this.jsonData["Mental Health Medication"] = event.detail;
                this.$emit("inputChange", this.jsonData);
            }
        });
    },
    methods: {
        timeOnMedicationSelection(event) {
            this.jsonData["Time On Medication"] = event.target.value;
            this.$emit("inputChange", this.jsonData);
        },
        totalDailyDoseChange(event) {
            // Allow only [numbers, periods, forward slash]
            event.target.value = event.target.value.replace(/[^0-9./]/, "");

            this.jsonData["Total Daily Dose"] = event.target.value;
            this.$emit("inputChange", this.jsonData);
        },
        unitSelection(event) {
            this.jsonData["Unit"] = event.target.value;
            this.$emit("inputChange", this.jsonData);
        },
        addSelectStyling() {
            const style = document.createElement("style");
            style.innerHTML = 
                `
                    :host .gsl-input { 
                        height: 35px; 
                        padding-left: 5px; 
                        z-index:0; 
                        font-size: 14px; 
                    } 
                    :host .gsl-container { 
                        font-size: 14px; 
                    } 
                    .gsl-dropdown { 
                        width: 100%; 
                        z-index: 9999; 
                        font-size: 14px;
                    } 
                    .gsl-dropdown .gsl-list-item { 
                        font-size: 14px; 
                    }
                `;

            // ensure shadowroot is not null
            if (this.$refs.medicationAutoComplete.shadowRoot !== null) {
                // The disgustig way we have to override styles in shadowdom
                this.$refs.medicationAutoComplete.shadowRoot.appendChild(style);
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.hide {
    display: none !important;
}

.error {
    color: #ef4034 !important;
}

.errorMessage {
    color: #ef4034;
    font-size: 0.8em;
}

.medication-row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.medication-row-inner {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.label {
    margin-bottom: 5px;
    font-size: .9em;
    font-weight: 100;
    color: #333333;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
}

.input-container:last-of-type {
    margin-right: 0;
}

.input {
    height: 35px;
    border: 1px solid #D0D3DA;
    border-radius: 3px;
    box-sizing: border-box;
}

.medication-input {
    width: 44%;
}

.time-input {
    width: 45%;
}

.dose-input {
    width: 33%;
}

.unit-input {
    width: 28%;
}

.medication-autocomplete {
    border: none;
}

.medication-row--disabled input, .medication-row--disabled select {
    border: 1px solid #d0d3da;
    background-color: rgba(239, 239, 239, 0.3);
}

@include small-desktop {
    .medication-row {
        flex-direction: column;
    }

    .medication-input {
        width: 100%;
        margin-bottom: 10px;
    }

    .medication-row-inner {
        width: 100%;
    }
}

@include tablet {
    .medication-input {
        margin-top: 30px;
        margin-bottom: 30px
    }
}
</style>
