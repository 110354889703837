<template>
    <transition name="fade">
        <div v-show="this.$store.getters.showFormHeaders" class="step-header">
            <h1 class="step-header-title">
                {{ this.$store.getters.stepHeaderTitle }}
            </h1>
        </div>
    </transition>
</template>

<script>
export default {
    name: "StepHeader",
    methods: {
        shouldShowHeader() {
            var path = this.$route.path;
            var options = ["/", "/complete"];

            return options.indexOf(path) > -1;
        }
    }
};
</script>

<style lang="scss" scoped>
.step-header-title {
    @include center-section();
    padding-top: 55px;
    margin: 0 auto 45px auto;
    font-size: $step-header-font-size;
    font-weight: 500;
}

@include small-desktop {
    .step-header-title {
        @include site-section-sm-desktop();
    }
}

@include tablet {
    .step-header {
        padding-top: 25px;
        margin-bottom: 18px;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
    }
    .step-header-title {
        padding-top: 0;
        margin: 0;
        font-size: 2.2em;
        font-weight: 300;
    }
}

@include sm {
    .step-header-title {
        font-size: 2em;
    }
}
</style>
