<template>
    <div class="survey-thank-you thank-you-page">
        <h1 class="thank-you-header">
            Thank you
        </h1>
        <h2 class="thank-you-sub-header">
            <div class="thank-you-step-image">
                <heart-icon />
            </div>
            <div class="thank-you-step-right-section">
                Your responses have been saved successfully
            </div>
        </h2>
        <div v-if="$store.getters.surveyComplete" class="thank-you-section">
            <p>Your information will be used for research to help improve the future of personalized healthcare. As a result, others are more likely to get the care they need to improve their mental health.</p>
        </div>
        <div v-else class="thank-you-section">
            <p>
                We appreciate you taking the time to answer this survey.   
            </p>
            <h1 class="thank-you-sub-header">
                What's next?
            </h1>
            <p>
                You will be contacted again in 12 weeks to check your progress for a follow-up survey.
            </p>
        </div>
    </div>
</template>

<script>
import HeartIcon from "../../../assets/icon_heart.svg";
export default {
    name: "SurveyThankYou",
    components: {
        HeartIcon
    }
};
</script>

<style lang="scss" scoped>
.thank-you-page {
    @include site-section();
    padding: 80px 90px;
    min-height: 610px;
}

.thank-you-header {
    font-size: $large-header-size;
}

.thank-you-sub-header {
    padding-top:30px;
    font-size: 1.625em;
    color: #00A8E9;
}

.thank-you-section {
    margin-top: 20px;
}

.thank-you-section p {
    margin-top: 0px;
    margin-bottom: 5px;
    color: #333333;
    font-size: 1em;
    line-height: 1.5em;
}

.thank-you-step-image {
    @include flex-center-vertical();
    width: $sub-header-img-size;
    height: $sub-header-img-size;
    min-width: $sub-header-img-size;
    min-height: $sub-header-img-size;
    z-index: 10;
    display: inline-block;
}

.thank-you-step-right-section{
    display: inline-block;
    padding-left: 15px;
    width:75%;
    vertical-align: 50%;
}

@include tablet{
    .thank-you-page {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .thank-you-step-image {
        vertical-align: top;
    }
}
</style>
