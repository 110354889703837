<template>
    <div class="thank-you-topper">
        <div v-if="$store.getters.lastPagesSaved === 1">
            <div class="thank-you">
                <font-awesome-icon class="thank-you-icon" icon="fa-solid fa-circle-check" />
                <p class="thank-you-text">
                    Thank you.<br>Your responses have been submitted successfully.
                </p>
            </div>
            <p class="thank-you-bold-text">
                Have another minute to help more?
            </p>
        </div>
        <div v-if="$store.getters.lastPagesSaved === 2">
            <div class="thank-you-large">
                <p class="thank-you-large-text">
                    Thank You
                </p>
            </div>
            <div class="thank-you">
                <font-awesome-icon class="thank-you-icon" icon="fa-solid fa-circle-check" />
                <p class="thank-you-text">
                    Your responses have been submitted successfully.
                </p>
            </div>
            <p class="thank-you-bold-text">
                Have 1 more minute?
            </p>
        </div>
        <down-arrows class="thank-you-down-arrow" />
    </div>
</template>

<script>
import DownArrows from "../../../assets/down_arrow.svg";

export default {
    name: "Name",
    components: {
        DownArrows
    },
    created() {
        this.$store.dispatch("setShowFormHeaders", false);
    }
};
</script>

<style lang="scss" scoped>
.thank-you-topper {
    margin-bottom: 80px;
}

.thank-you {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    margin-bottom: 30px;
}

.thank-you-large {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    margin-bottom: -20px;
}

.thank-you-icon {
    width: 49px;
    height: 49px;
    color: $genesight-green;
    margin-right: 7px;
}

.thank-you-text {
    font-size: 1em;
    line-height: 1.1em;
    font-weight: 100;
}

.thank-you-large-text {
    font-size: 2.75em;
    font-weight: 300;
    font-family: "Raleway";
}

.thank-you-bold-text {
    font-family: "Arial";
    font-size: 1.125em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.thank-you-down-arrow {
    display: block;
    margin: 0 auto;
}
</style>
